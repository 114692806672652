import { defineStore } from 'pinia';
import { useMoveStore } from '@store/move';

export const useInventoryHistoryStore = defineStore('inventoryHistory', {
    state: () => {
        return {
            loading: false,
            history: [],
            version: [],
            versionNumber: null,
            date: null,
            modalType: null,
        };
    },
    actions: {
        async retrieve(moveID = this.getMoveId) {
            this.loading = true;
            try {
                const response = await window.axios.get(`/moves/${moveID}/inventory/history`);
                this.history = response.data.inventory;
                this.history.sort((a, b) => a.version < b.version);
            } catch (e) {
                console.error('Error retrieving inventory history', e);
            } finally {
                this.loading = false;
            }
        },

        async retrieveVersion({ version, moveID = this.getMoveId, date }) {
            try {
                const response = await window.axios.get(`/moves/${moveID}/inventory/version/${version}`);
                this.version = response.data;
                this.versionNumber = version;
                this.date = date;
                return response.data;
            } catch (e) {
                console.error('Error retrieving inventory version', e);
            }
        },
        async approveVersion(versionID, moveID = this.getMoveId) {
            try {
                await window.axios.post(`/moves/${moveID}/inventory/approve`, {
                    version: this.getVersionNumber,
                });
            } catch (e) {
                console.error(`Error approving version ${this.getVersionNumber}`, e);
            }
        },
        async restoreVersion(versionID, moveID = this.getMoveId) {
            try {
                await window.axios.post(`/moves/${moveID}/inventory/rollback`, {
                    version: this.getVersionNumber,
                });
            } catch (e) {
                console.error(`Error restoring version ${this.getVersionNumber}`, e);
            }
        },
        setModalType(type) {
            this.modalType = type;
        },
    },
    getters: {
        getMoveId() {
            const moveStore = useMoveStore();
            return moveStore.getMoveId;
        },
        getHistory(state) {
            return state.history;
        },
        getVersion(state) {
            return state.version;
        },
        getApproved(state) {
            return state.approved;
        },
        getVersionNumber(state) {
            return state.versionNumber;
        },
        getDate(state) {
            return state.date;
        },
        getModalType(state) {
            return state.modalType;
        },
        isApprove(state) {
            return state.modalType === 'approve';
        },
    },
});
