<template>
    <div class="z-0 inline-block align-middle">
        <div class="flex">
            <input
                id="offers"
                :checked="!!selected"
                name="offers"
                type="checkbox"
                :disabled="true"
                class="my-auto h-4 w-4 border-gray-300 text-green-500 focus:ring-green-500"
            />
            <span class="my-auto ml-2 text-sm font-normal capitalize text-gray-700">{{ data.label }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MSAgent',
    props: {
        data: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {};
    },
};
</script>
