import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { refDebounced } from '@vueuse/core';
import { useOrderStore } from '@store';
import { useMoveStore } from './move';

export const useInventoryStore = defineStore('inventory', () => {
    const orderStore = useOrderStore();
    const moveStore = useMoveStore();

    const CUSTOM_CATEGORY_ID = 1;
    const CATEGORY_URL = 'inventory-categories';
    const OPTION_URL = 'inventory-options';

    const CREATE_CUSTOM_OPTION_URL = computed(() => {
        return `moves/${orderStore.getMoveId}/inventory/customer`;
    });

    const GET_CUSTOM_OPTIONS_URL = computed(() => {
        return `moves/${orderStore.getMoveId}/inventory/customer`;
    });

    const loading = ref(true);

    const isLoading = computed(() => {
        return loading.value;
    });

    const initialized = ref(false);

    const searchTerm = ref('');
    const searchTermDebounced = refDebounced(searchTerm, 200);

    const categories = ref([]);
    const serverCategories = ref([]);
    const serverItems = ref([]);
    const selectedItems = ref([]);
    const filterCategory = ref(2);
    const inventoryOptions = ref([]);
    const hasHistory = ref(false);

    function toggleCustomItemForm() {
        searchTerm.value = '';
        searched.value = [];
        filterCategory.value = CUSTOM_CATEGORY_ID;
    }

    async function retrieve(moveId = moveStore.getMoveId) {
        loading.value = true;
        const { data: data } = await window.axios.get(`/moves/${moveId}/inventory`);
        init({ inventory: data.data });
        hasHistory.value = data.has_history;
    }

    function resetToSaved() {
        selectedItems.value = cloneDeep(serverItems.value);
    }

    watch(searchTermDebounced, async () => {
        await search();
    });

    // Initialize inventory with selectedItems
    async function init({ inventory = [] }) {
        loading.value = true;
        await fetchCategories();

        if (orderStore.getOrderId) {
            await fetchCustomOptions();
        }

        if (inventory && inventory.length > 0) {
            // On init both selectedItems and serverItems are the same
            selectedItems.value = cloneDeep(inventory);
            serverItems.value = cloneDeep(inventory);
        }

        loading.value = false;
        initialized.value = true;
    }

    async function create() {
        loading.value = true;
        try {
            let items = selectedItems.value.map((item) => {
                return {
                    inventory_option_id: item.inventory_option.id,
                    qty: item.qty,
                };
            });
            const { data: data } = await window.axios.post(`/moves/${orderStore.getMoveId}/inventory`, {
                inventory: items,
            });

            serverItems.value = cloneDeep(data.inventory);
            selectedItems.value = cloneDeep(data.inventory);

            const move = useMoveStore();
            move.setQuoteType('ACCURATE_QUOTE');
        } catch (error) {
            console.log('Create inventory error', error);
            throw error;
        } finally {
            loading.value = false;
        }
    }

    async function update() {
        loading.value = true;
        try {
            let items = selectedItems.value.map((item) => {
                return {
                    inventory_option_id: item.inventory_option.id,
                    qty: item.qty,
                };
            });
            const { data: data } = await window.axios.patch(`/moves/${orderStore.getMoveId}/inventory`, {
                inventory: items,
            });

            serverItems.value = cloneDeep(data.inventory);
            selectedItems.value = cloneDeep(data.inventory);
        } catch (error) {
            console.log('Update inventory error', error);
            throw error;
        } finally {
            loading.value = false;
        }
    }

    async function fetchCategories() {
        try {
            loading.value = true;
            if (categories.value.length === 0) {
                const response = await window.axios.get(`${CATEGORY_URL}`, {
                    params: {
                        type: 'MOVE',
                    },
                });
                let formatted = response.data.data;
                categories.value = formatted;
                serverCategories.value = cloneDeep(response.data.data);
            }

            loading.value = false;
        } catch (error) {
            console.log('Store@Inventory Fetch categories error', error);
            throw error;
        }
    }

    const isFetchingSearch = ref(false);
    const searched = ref([]);
    async function search() {
        try {
            loading.value = true;
            const response = await window.axios.get(`${OPTION_URL}`, {
                params: {
                    type: 'MOVE',
                    search: searchTerm.value,
                },
            });
            let options = response.data.data;
            searched.value = options;
        } catch (error) {
            console.log('Store@Inventory Fetch options error', error);
            throw error;
        } finally {
            loading.value = false;
        }
    }

    async function fetchCustomOptions() {
        try {
            loading.value = true;

            const customOptions = await retrieveCustomOptionItems();

            categories.value = categories.value.map((category) => {
                if (category.id === CUSTOM_CATEGORY_ID) {
                    category.move_options = customOptions;
                }
                return category;
            });
        } catch (error) {
            console.log('Store@Inventory Fetch options error', error);
            throw error;
        } finally {
            loading.value = false;
        }
    }

    function addCustomOption(option) {
        categories.value = categories.value.map((category) => {
            if (category.id === CUSTOM_CATEGORY_ID) {
                category.move_options.push(option);
                // Make sure the custom option is added with a qty of 1
                addItem(option);
            }
            return category;
        });
    }

    /*
     * Creates a custom option for the current customer and adds it to the selectedItems array
     */
    async function createCustomOptionItem(payload) {
        const { data } = await window.axios.post(CREATE_CUSTOM_OPTION_URL.value, payload);

        if (!categories.value[0].move_options?.some((item) => item.id === data.custom_option.id)) {
            console.log('matched');
            addCustomOption(data.custom_option);
        }

        return data.custom_option;
    }

    /*
     * Retrieves all custom options for the current customer
     */
    async function retrieveCustomOptionItems() {
        const { data } = await window.axios.get(GET_CUSTOM_OPTIONS_URL.value);
        return data.data;
    }

    // Adds a new inventory item to the selectedItems array for a given item if it doesn't already exist otherwise it increments a found item's qty.
    function addItem(item) {
        // Check if the inventory item is already in selectedItems
        if (selectedItems.value.length < 1) {
            selectedItems.value.push({
                inventory_option: {
                    id: item.id,
                    inventory_category_id: item.inventory_category_id,
                    name: item.name,
                },
                inventory_option_id: item.id,
                name: item.name,
                qty: 1,
            });
        } else if (
            selectedItems.value.length > 0 &&
            !selectedItems.value.some((inventoryItem) => {
                return inventoryItem.inventory_option.id === item.id;
            })
        ) {
            selectedItems.value.push({
                inventory_option: {
                    id: item.id,
                    inventory_category_id: item.inventory_category_id,
                    name: item.name,
                },
                name: item.name,
                inventory_option_id: item.id,
                qty: 1,
            });
        } else if (selectedItems.value.length > 0) {
            selectedItems.value.find((inventoryItem) => {
                return inventoryItem.inventory_option.id === item.id;
            }).qty++;
        }
    }

    // Subtracts the found items selectedItems objects qty if greater than 1 or removes the entire selectedItems object from the inventory
    function removeItem(item) {
        let inventoryItem = selectedItems.value.find((inventoryItem) => {
            return inventoryItem.inventory_option.id === item.id;
        });

        // Check if inventory items qty is greater than 1 and if minus 1 from qty
        // else remove the entire item object from the selectedItems array
        if (inventoryItem && inventoryItem.qty >= 1) {
            inventoryItem.qty--;
        } else {
            selectedItems.value = selectedItems.value.filter((inventoryItem) => {
                return inventoryItem.inventory_option.id !== item.id;
            });
        }
    }

    function clearInventory() {
        selectedItems.value = [];
    }

    function setItemCountById(id, count) {
        let inventoryItem = selectedItems.value.find((inventoryItem) => {
            return inventoryItem.inventory_option.id === id;
        });
        inventoryItem.qty = count;
    }

    function setCategoryFilter(categoryId, clearSearchTerm = true) {
        if (categoryId === filterCategory.value) {
            filterCategory.value = null;
        } else {
            if (clearSearchTerm) searchTerm.value = '';
            filterCategory.value = categoryId;
        }
    }

    /*
     *  Computed Getters
     */

    const hasInventory = computed(() => {
        const move = useMoveStore();
        if (move.getQuoteType == 'ACCURATE_QUOTE') {
            return true;
        } else {
            return false;
        }
    });

    const getCategories = computed(() => {
        if (categories.value && categories.value.length) {
            return categories.value;
        } else {
            return [];
        }
    });

    const getOptions = computed(() => {
        let options = [];

        getCategories.value.forEach((category) => {
            if (category.id === filterCategory.value) {
                category.move_options?.forEach((item) => {
                    options.push(item);
                });
            }
        });

        return options;
    });

    const getCalculatedSpace = computed(() => {
        let all = [];
        getCategories.value.forEach((category) => {
            category.move_options?.forEach((item) => {
                all.push(item);
            });
        });

        inventoryOptions.value = all.map((item) => {
            return {
                id: item.id,
                volume: item.volume,
            };
        });

        let totalSpace = 0;
        if (selectedItems.value && selectedItems.value.length > 0) {
            selectedItems.value.forEach((item) => {
                inventoryOptions.value.forEach((option) => {
                    if (item.inventory_option.id == option?.id && item.qty) {
                        totalSpace += option.volume * item.qty;
                    }
                });
            });
            return totalSpace.toFixed(2);
        } else {
            return 0;
        }
    });

    const getCountByCategoryId = computed(() => {
        return (categoryId, saved = false) => {
            if (saved) {
                let count = 0;
                if (serverItems.value && serverItems.value.length) {
                    serverItems.value.forEach((item) => {
                        if (item.inventory_option.inventory_category_id == categoryId) {
                            count += item.qty;
                        }
                    });
                }
                return count;
            } else {
                let count = 0;
                if (selectedItems.value && selectedItems.value.length) {
                    selectedItems.value.forEach((item) => {
                        if (item.inventory_option.inventory_category_id == categoryId) {
                            count += item.qty;
                        }
                    });
                }
                return count;
            }
        };
    });

    const getItemCountById = computed(() => {
        return (itemId, saved = false) => {
            if (saved) {
                if (serverItems.value && serverItems.value.length > 0) {
                    return (
                        serverItems.value.find((inventoryItem) => {
                            return inventoryItem.inventory_option.id === itemId;
                        })?.qty || 0
                    );
                } else {
                    return 0;
                }
            } else {
                if (selectedItems.value && selectedItems.value.length > 0) {
                    return (
                        selectedItems.value.find((inventoryItem) => {
                            return inventoryItem.inventory_option.id === itemId;
                        })?.qty || 0
                    );
                } else {
                    return 0;
                }
            }
        };
    });

    const getTotalItems = computed(() => {
        let total = 0;
        if (selectedItems.value && selectedItems.value.length > 0) {
            selectedItems.value.forEach((el) => {
                total += el.qty;
            });
        }
        return total;
    });

    const getTotalItemsServer = computed(() => {
        let total = 0;
        if (serverItems.value && serverItems.value.length > 0) {
            serverItems.value.forEach((el) => {
                total += el.qty;
            });
        }
        return total;
    });

    const hasHeavyItem = computed(() => {
        if (serverItems.value && serverItems.value.length) {
            return serverItems.value.some((item) => item.inventory_option.is_heavy && item.qty);
        }
        return false;
    });

    const hasCustomItems = computed(() => {
        if (serverItems.value && serverItems.value.length) {
            return serverItems.value.some((item) => item.inventory_option.is_custom);
        }
        return false;
    });

    const getCurrentInventory = computed(() => {
        return (categoryId) => {
            return serverItems.value
                .filter((item) => {
                    return item.inventory_option.inventory_category_id == categoryId;
                })
                .map((item) => {
                    return {
                        ...item.inventory_option,
                        qty: item.qty,
                        alert_change: item.alert_change,
                    };
                });
        };
    });

    /*
     *  Return
     */
    return {
        // crud
        create,
        retrieve,
        update,

        resetToSaved,
        init,
        initialized,
        loading,
        isLoading,
        categories,
        serverCategories,
        selectedItems,
        serverItems,
        filterCategory,
        searchTerm,
        searched,
        isFetchingSearch,
        fetchCategories,

        // Custom Options
        toggleCustomItemForm,
        retrieveCustomOptionItems,
        createCustomOptionItem,
        addCustomOption,

        addItem,
        removeItem,
        clearInventory,
        setItemCountById,
        setCategoryFilter,
        getCategories,
        getCurrentInventory,
        getOptions,
        getCalculatedSpace,
        getCountByCategoryId,
        getItemCountById,
        getTotalItems,
        getTotalItemsServer,
        hasHeavyItem,
        hasCustomItems,
        hasInventory,
        hasHistory,
    };
});
