/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module Customer
 */

const ROUTE = '/customers';

function index() {
    return window.axios.get(ROUTE);
}

function create(customer) {
    return window.axios.post(ROUTE, customer);
}

function retrieve(id) {
    return window.axios.get(`${ROUTE}/${id}`);
}

function retrieveByEmail(email) {
    return window.axios.get(`${ROUTE}/by-email`, {
        params: { email },
    });
}

function update(id, customer) {
    return window.axios.put(`${ROUTE}/${id}`, customer);
}

function destroy(id) {
    return window.axios.delete(`${ROUTE}/${id}`);
}

// Helper API Services

export default {
    index,
    create,
    retrieve,
    retrieveByEmail,
    update,
    destroy,
};
