import { defineStore } from 'pinia';

export const useMergeTags = defineStore('mergeTags', {
    state: () => {
        return {
            loading: false,
            data: {},
        };
    },
    actions: {
        /*
         * Retrieve a mergeTags
         */
        async retrieve() {
            try {
                this.loading = true;
                const { data: data } = await window.axios.get('/merge-tags');
                this.data = data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },
    },
});
