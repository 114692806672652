import { defineStore } from 'pinia';
import depotApi from '@api/depot';
import { cloneDeep } from 'lodash';

export const useDepotStore = defineStore('depot', {
    state: () => {
        return {
            loading: false,
            data: {
                address: '',
            },
            collection: [],
            serverData: {},
            createValues: {},
        };
    },
    getters: {},
    actions: {
        async index(params = {}) {
            try {
                const { data } = await depotApi.index(params);
                this.collection = data.data;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async create(depot) {
            try {
                this.loading = true;
                const { data } = await depotApi.create(depot);
                this.mapResponseValues(data.depot);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async retrieve(depotId) {
            try {
                this.loading = true;
                const { data } = await depotApi.retrieve(depotId);
                this.mapResponseValues(data.depot);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async update(depotId, depot) {
            try {
                this.loading = true;
                const { data } = await depotApi.update(depotId, depot);
                this.mapResponseValues(data.depot);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        mapResponseValues(depot) {
            console.log(depot);
            this.serverData = cloneDeep(depot);
            this.data = depot;
            this.data.address = depot.address.formatted;
        },
    },
});
