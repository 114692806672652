export default [
    {
        path: '/providers',
        name: 'Providers',
        meta: {
            requiresAuth: true,
            title: 'Providers',
        },
        components: {
            default: () => import('@views/providers/ProvidersIndex.vue'),
            agent: () => import('@views/providers/ProvidersIndex.vue'),
        },
    },
    {
        path: '/providers/create',
        name: 'ProvidersCreate',
        props: {
            default: () => ({ isCreate: true }),
            agent: () => ({ isCreate: true }),
        },
        meta: {
            requiresAuth: true,
            title: 'Create Provider',
        },
        components: {
            default: () => import('@views/providers/ProvidersForm.vue'),
            agent: () => import('@views/providers/ProvidersForm.vue'),
        },
    },
    {
        path: '/providers/:id/edit',
        name: 'ProvidersEdit',
        props: {
            default: (route) => ({ id: route.params.id, isCreate: false }),
            agent: (route) => ({ id: route.params.id, isCreate: false }),
        },
        meta: {
            requiresAuth: true,
            title: 'Edit Provider',
        },
        components: {
            default: () => import('@views/providers/ProvidersForm.vue'),
            agent: () => import('@views/providers/ProvidersForm.vue'),
        },
    },
    {
        path: '/providers/:id/view',
        name: 'ProvidersView',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
            title: 'View Provider',
        },
        components: {
            default: () => import('@views/providers/ProvidersView.vue'),
            agent: () => import('@views/providers/ProvidersView.vue'),
        },
    },
];
