import { defineStore } from 'pinia';
import ProviderAPI from '@api/provider';

export const useProviderStore = defineStore('provider', () => {
    const provider = ref();

    async function retrieveProvider(id) {
        const response = await ProviderAPI.retrieve(id);
        provider.value = response;
    }

    async function createProvider(id) {
        const response = await ProviderAPI.create(id);
        provider.value = response;
    }

    async function updateProvider(id, values) {
        const response = await ProviderAPI.update(id, values);
        provider.value = response;
    }

    const getProviderId = computed(() => provider.value?.id);

    return {
        provider,
        retrieveProvider,
        createProvider,
        updateProvider,
        getProviderId,
    };
});
