<template>
    <div class="relative flex w-full flex-col">
        <div class="text-md">Are you sure you want to delete this {{ itemType }}?</div>
        <div class="container mt-3 divide-y divide-gray-200 rounded-muval-2 bg-muval-gray-5 dark:bg-gray-900">
            <div class="grid grid-cols-5 gap-y-2 p-4 text-sm">
                <span class="font-medium">{{ itemType }}</span>
                <span class="col-span-4">{{ data.id }}</span>
                <template
                    v-for="item in deleteItemSchema"
                    :key="item"
                >
                    <span class="font-medium">{{ item.name }}</span>
                    <!-- Thankyou Stack Overflow: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path -->
                    <span class="col-span-4">
                        {{ `${item.value}`.split('.').reduce((p, c) => (p && p[c]) || null, data) }}
                    </span>
                </template>
                <template v-if="data.created_at">
                    <span class="font-medium">Created</span>
                    <time class="col-span-4">
                        {{ $dayjs(data.created_at).format('dddd DD MMM YYYY') }} at
                        {{ $dayjs(data.created_at).format('h:mm A') }}
                    </time>
                </template>
            </div>
        </div>
        <div class="form-save">
            <MuvalButton
                quaternary
                @click="closeModal"
            >
                No, keep this item
            </MuvalButton>
            <div>
                <MuvalButton
                    :loading="loading"
                    @click="remove"
                >
                    Yes, delete this {{ itemType }}
                </MuvalButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { notify } from '@kyvg/vue3-notification';
import useEmitter from '@plugins/useEmitter';
import MuvalButton from '@buttons/MuvalButton.vue';

const props = defineProps({
    itemIndex: {
        type: Number,
        required: true,
    },
    itemType: {
        type: [String, null],
        required: false,
        default: null,
    },
    data: {
        type: [Object, null],
        required: false,
        default: null,
    },
    url: {
        type: [String, null],
        required: true,
        default: null,
    },
    deleteItemSchema: {
        type: [Array, null],
        required: false,
        default: null,
    },
    emitKey: {
        type: [String, null],
        required: false,
        default: null,
    },
});
const emit = defineEmits(['close:modal', 'remove:item']);
const loading = ref(false);
const emitter = useEmitter();
const lastDeleted = ref({
    index: null,
    id: null,
});
async function remove() {
    try {
        loading.value = true;
        const response = await window.axios.delete(`${props.url}/${props.data.id}`);
        loading.value = false;
        if (response) {
            lastDeleted.value.index = props.itemIndex;
            lastDeleted.value.id = props.data.id;
            console.log(lastDeleted.value);
            notify({
                title: 'Undo delete',
                group: 'app_restore',
                text: 'delete',
                data: {
                    url: props.url,
                    type: props.itemType,
                    lastDeleted: lastDeleted.value,
                    key: props.emitKey,
                },
            });
            if (props.emitKey) {
                emitter.emit(`remove:item-${props.emitKey}`, props.data.id);
            } else {
                emit('remove:item', props.data.id);
            }
        }
        closeModal();
    } catch (error) {
        loading.value = false;
        console.log('DeleteModal@error', error);
    }
}
function closeModal() {
    emit('close:modal');
}
</script>
