export default [
    {
        path: '/staff',
        name: 'Staff',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/staff/StaffIndex.vue'),
            agent: () => import('@views/staff/StaffMuvalIndex.vue'),
        },
    },
    {
        path: '/users/create',
        name: 'UserCreate',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/user/UserCreate.vue'),
            agent: () => import('@views/user/UserCreate.vue'),
        },
    },
    {
        path: '/users/:userId/edit',
        name: 'UserEdit',
        props: {
            default: (route) => ({ userId: route.params.userId }),
            agent: (route) => ({ userId: route.params.userId }),
        },
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/user/UserEdit.vue'),
            agent: () => import('@views/user/UserEdit.vue'),
        },
    },
];
