<template>
    <div class="w-full px-2">
        <input
            ref="input"
            v-model="context.context.search"
            class="h-8 w-full text-base focus:outline-none"
            placeholder="Customer contact information, company name..."
        />
    </div>
</template>
<script setup>
const input = ref();

const context = inject('context');

onMounted(() => {
    input.value.focus();
});
</script>
