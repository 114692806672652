export default [
    {
        path: '/staff',
        name: 'Staff',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/staff/StaffIndex.vue'),
            agent: () => import('@views/staff/StaffIndex.vue'),
        },
    },
    {
        path: '/staff/:companyId/create',
        name: 'StaffCreate',
        meta: {
            requiresAuth: true,
        },
        props: true,
        components: {
            default: () => import('@views/staff/StaffCreate.vue'),
            agent: () => import('@views/staff/StaffCreate.vue'),
        },
    },
    {
        path: '/companies/:companyId/staff/:staffId/edit',
        name: 'CompanyStaffEdit',
        props: {
            default: (route) => ({
                companyId: route.params.companyId,
                staffId: route.params.staffId,
            }),
            agent: (route) => ({
                companyId: route.params.companyId,
                staffId: route.params.staffId,
            }),
        },
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/staff/StaffEdit.vue'),
            agent: () => import('@views/staff/StaffEdit.vue'),
        },
    },
    {
        path: '/staff/:staffId/edit',
        name: 'StaffEdit',
        props: {
            default: (route) => ({
                staffId: route.params.staffId,
            }),
            agent: (route) => ({
                staffId: route.params.staffId,
            }),
        },
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/staff/StaffMuvalEdit.vue'),
            agent: () => import('@views/staff/StaffMuvalEdit.vue'),
        },
    },
];
