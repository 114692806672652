/**
 * Axios error postprocessing
 *
 * @package Muval Frontend SPA
 * @author Tim Maier
 */
import { notify } from '@kyvg/vue3-notification';
import * as Sentry from '@sentry/vue';

/**
 * Helper function to process Axios errors
 *
 * @param error Axios error object
 * @return object Error Muval SFC can use
 */
export function processError(error) {
    if (import.meta.env.VITE_ENV == 'production' && error?.code !== 'ERR_CANCELED' && error instanceof Error) {
        Sentry.captureException(error);
    }
    let result = {
        code: null,
        message: 'Unknown error',
        errors: null,
        links: null,
    };

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        switch (error.response.status) {
            case 422:
                notify({
                    title: 'Form validation error',
                    group: 'app_validation_error',
                    text: error.response.data.message,
                });
                result.message = error.response.data.message;
                result.errors = error.response.data.errors;
                result.links = error.response.data.links;
                break;
            case 400:
                console.log('400 ERROR', error);
                result.code = 400;
                switch (error.response.data.message) {
                    case 'There are no confirmable services. Check prices and statuses.':
                        notify({
                            title: 'Check order service prices and statuses.',
                            group: 'app_validation_error',
                            text: error.response.data.message,
                        });
                        result.message = error.response.data.message;
                        result.errors = error.response.data.errors;
                        break;
                    default:
                        notify({
                            title: 'Bad form request',
                            group: 'app_validation_error',
                            text: error.response.data.message,
                        });
                        result.message = error.response.data.message;
                        result.errors = error.response.data.errors;
                        break;
                }
                break;
            case 401:
                notify({
                    title: '401 Error occurred',
                    group: 'app_error',
                    text: error.response.data.message,
                });
                result.message = error.response.data.message;
                result.errors = error.response.data.errors;
                break;
            case 402:
                notify({
                    title: 'Payment error occurred',
                    group: 'app_error',
                    text: error.response.data.message,
                });
                result.code = 402;
                result.message = error.response.data.message;
                break;
            case 403:
                console.log('403 Error', error.response);
                notify({
                    title: error.response?.data?.message ? error.response.data.message : '403 Error occurred',
                    group: 'app_error',
                    data: {
                        url: error.response.request.responseURL,
                        method: error.response.config.method,
                    },
                });
                result.code = 403;
                result.message = error.response.data;
                break;
            case 404:
                result.code = 404;
                result.message = '404 not found:' + error.config.url;
                break;
            case 429:
                notify({
                    title: '429 Error occurred',
                    group: 'app_validation_error',
                    text: error.response.data.message,
                });
                result.message = error.response.data.message;
                result.errors = error.response.data.errors;
                break;
            case 500:
                notify({
                    title: '500 Error occurred',
                    group: 'app_error',
                    data: {
                        url: error.config.url,
                        method: error.config.method,
                    },
                });
                result.code = 500;
                result.message = error.response.data.message;
                break;
            case 503:
                notify({
                    title: '503 Service temporarily unavailable',
                    group: 'app_error',
                    data: {
                        url: error.config.url,
                        method: error.config.method,
                    },
                });
                result.code = 503;
                result.message = error.response.data.message;
                break;
            default:
                result.message = 'Unknown error';
                break;
        }
    } else if (error.request) {
        // The request was made but no response was received
        notify({
            title: 'Muval API failed to respond',
            group: 'app_error',
            text: 'The Muval API might be down?',
        });
    } else {
        // Something happened in setting up the request that triggered an Error
        result.message = 'Axios error: ' + error.message;
    }
    return result;
}
