export default [
    {
        path: '/news',
        name: 'News',
        meta: {
            requiresAuth: true,
        },
        components: {
            agent: () => import('@views/news/NewsIndex.vue'),
        },
    },
    {
        path: '/news/:id/edit',
        name: 'NewsEdit',
        meta: {
            requiresAuth: true,
        },
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        components: {
            default: () => import('@views/news/NewsForm.vue'),
            agent: () => import('@views/news/NewsForm.vue'),
        },
    },
    {
        path: '/news/create',
        name: 'NewsCreate',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/news/NewsForm.vue'),
            agent: () => import('@views/news/NewsForm.vue'),
        },
    },
];
