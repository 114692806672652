<template>
    <router-link
        class="flex h-16 w-full items-center gap-x-4 rounded-md p-2 hover:bg-muval-gray-6 focus:outline-none focus:ring-2 focus:ring-muval-brand"
        :to="$router.resolve({ name: 'Order', params: { id: data.id } }).href"
        @click="context.context.handleClose"
    >
        <SvgIcon
            name="system-user-filled"
            class="size-10 shrink-0 text-muval-gray-4"
        />
        <div class="flex w-full max-w-32 flex-col">
            <span class="truncate font-medium text-muval-gray-1"
                >{{ data.customer_firstname }} {{ data.customer_lastname }}</span
            >
            <span class="text-muval-gray-2">#{{ data.ref }}</span>
        </div>
    </router-link>
</template>
<script setup>
defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const context = inject('context');
</script>
