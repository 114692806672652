<template>
    <MuvalModal
        v-model="inactiveModal.isRevealed"
        modal-size="md:w-1/2"
        @modal:cancel="inactiveModal.cancel(!notificationsStore.inactive_dismissible)"
    >
        <template #header>
            <div class="flex items-center gap-x-4">
                <div class="flex size-5 items-center justify-center rounded-full bg-black">
                    <SvgIcon
                        name="system-user-filled"
                        class="size-4 text-white"
                    />
                </div>
                <h4>Hey, are you still there?</h4>
            </div>
        </template>
        <div class="grid gap-y-0.5 text-base">
            <span v-text="notificationsStore.inactive_status" />
            <div class="mt-4 flex w-full justify-end gap-x-4">
                <MuvalButton
                    v-if="notificationsStore.inactive_dismissible"
                    quaternary
                    @click="inactiveModal.confirm(false)"
                    >Dismiss</MuvalButton
                >
                <MuvalButton @click="inactiveModal.confirm">I'm here!</MuvalButton>
            </div>
        </div>
    </MuvalModal>
</template>
<script setup>
const notificationsStore = useNotificationsStore();
const userStore = useUserStore();

const inactiveModal = reactive(useConfirmDialog());

watch(
    () => notificationsStore.inactive_status,
    (val) => {
        console.log(`inactive_status updated: ${val}`);

        // prevent showing modal if inactive_status_code is AVAILABLE
        if (notificationsStore.inactive_status_code === 'AVAILABLE') {
            console.log(
                `skipped showing modal because inactive_status_code is ${notificationsStore.inactive_status_code}`,
            );

            inactiveModal.confirm(false);

            return;
        }

        if (val) {
            inactiveModal.reveal();
        } else {
            inactiveModal.confirm(false);
        }
    },
);

inactiveModal.onCancel((send_request = true) => {
    userReturns(send_request);
});

inactiveModal.onConfirm((send_request = true) => {
    userReturns(send_request);
});

onMounted(() => {
    notificationsStore.inactive_status_code = null;
    notificationsStore.inactive_status = null;
    notificationsStore.dismissible = false;
});

async function userReturns(send_request = true) {
    if (send_request) {
        const activity_status = 'AVAILABLE';

        await window.axios.patch(`/users/muval/${userStore.getUserId}/activity-status`, {
            activity_status,
        });

        userStore.activity_status = activity_status;
    }

    notificationsStore.inactive_status_code = null;
    notificationsStore.inactive_status = null;
}
</script>
