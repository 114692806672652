import { defineStore } from 'pinia';
import { useUserStore } from '@store';

export const useDashboardStore = defineStore('dashboard', {
    state: () => {
        return {
            user_id: null,
            start_date: null,
            end_date: null,
            agents: [],
            askRateModal: false,
        };
    },
    actions: {
        async showAskRateModal() {
            this.askRateModal = true;
        },

        async hideAskRateModal() {
            this.askRateModal = false;
        },

        async retrieveAgents() {
            if (this.agents.length === 0) {
                const {
                    data: { data: response },
                } = await window.axios.get('/users/muval?limit=9999');
                this.agents = response;
                return response;
            } else {
                return this.agents;
            }
        },

        async retrieveMonthlyTarget(user_id = this.getUserId, start_date = this.getStartDate) {
            const userStore = useUserStore();
            const passUser = userStore.hasAnyRole(['MUVAL_ADMIN', 'SUPER']);
            let params = { start_date };
            if (passUser) {
                params = {
                    ...params,
                    user_id,
                };
            }

            const response = await window.axios.get('/stats/monthly-target', {
                params: params,
            });
            return response.data.data;
        },

        async retrieveMonthlyTeamTarget(start_date = this.getStartDate, team = null) {
            const response = await window.axios.get(`/stats/monthly-team-target`, {
                params: {
                    start_date,
                    team,
                },
            });
            return response.data.data;
        },

        async retrieveLeadsTaken() {
            const response = await window.axios.get('/stats/leads-taken', {
                params: {
                    start_date: this.getStartDate,
                    end_date: this.getEndDate,
                    user_id: this.getUserId,
                },
            });
            return response.data.data;
        },

        async retrieveWarmTransfer() {
            const response = await window.axios.get('/stats/warm-transfer', {
                params: {
                    start_date: this.getStartDate,
                    end_date: this.getEndDate,
                    user_id: this.getUserId,
                },
            });
            console.log(response);
            return response.data.data;
        },

        async retrieveLeaderboard(type, service, sort, start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get(
                `/stats/leaderboard${this.createParams({
                    type,
                    service,
                    sort,
                    start_date,
                    end_date,
                })}`,
            );
            return response.data.data;
        },

        async retrieveGraph(
            days,
            revenue,
            leads,
            user_id = this.getUserId,
            start_date = this.getStartDate,
            end_date = this.getEndDate,
        ) {
            const response = await window.axios.get('/stats/sales-graph', {
                params: {
                    days,
                    leads,
                    revenue,
                    user_id,
                    start_date,
                    end_date: end_date ?? start_date,
                },
            });
            return response.data.data;
        },

        async retrieveServicesTable(
            user_id = this.getUserId,
            start_date = this.getStartDate,
            end_date = this.getEndDate,
        ) {
            const response = await window.axios.get(
                `/stats/services${this.createParams({
                    user_id,
                    start_date,
                    end_date,
                })}`,
            );
            return response.data.data;
        },

        async retrieveConversions(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/conversions', {
                params: {
                    start_date,
                    end_date,
                },
            });
            return response.data.data;
        },

        async retrieveLeadsUnanswered(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get(
                `/stats/leads-unanswered${this.createParams({
                    start_date,
                    end_date,
                })}`,
            );
            return response.data.data;
        },

        async retrieveLeadsReceived(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get(
                `/stats/leads-received${this.createParams({
                    start_date,
                    end_date,
                })}`,
            );
            return response.data.data;
        },

        async retrieveSalesCount(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/sales-count', {
                params: { start_date, end_date },
            });
            return response.data.data;
        },

        async retrieveAnsweredCalls(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/answered-calls', {
                params: { start_date, end_date },
            });
            return response.data.data;
        },

        async retrieveOutgoingCalls(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/outgoing-calls', {
                params: { start_date, end_date },
            });
            return response.data.data;
        },

        async retrieveAgentRevenue(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-revenue', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveAgentMoveCount(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-move-count', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveAgentInsuranceCount(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-insurance-count', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveAgentConversionRate(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-conversion-rate', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveAgentActiveLeads(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-active-leads', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveAgentOutgoingCalls(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-outgoing-calls', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveAgentAnsweredCalls(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-answered-calls', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveAgentWarmTransferCount(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/agent-warm-transfer-count', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveProductsPerCustomer(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/products-per-customer', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveServiceAskRate(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/service-ask-rate', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },

        async retrieveTemporaryServiceAskRate(start_date = this.getStartDate, end_date = this.getEndDate) {
            const response = await window.axios.get('/stats/temporary-service-ask-rate', {
                params: { start_date, end_date, user_id: this.getUserId },
            });
            return response.data.data;
        },
    },
    getters: {
        getUserId(state) {
            return state.user_id;
        },
        getStartDate(state) {
            return state.start_date;
        },
        getEndDate(state) {
            return state.end_date ?? state.start_date;
        },
        getKeys() {
            return [this.getUserId, this.getStartDate, this.getEndDate, this.getTeam];
        },
        createParams: () => {
            return (params) => {
                let query = '';
                let first = true;
                for (const [key, val] of Object.entries(params)) {
                    if (val) {
                        if (first) {
                            query += `?${key}=${val}`;
                            first = false;
                        } else {
                            query += `&${key}=${val}`;
                        }
                    }
                }
                return query;
            };
        },

        // if the selected user is an admin or if there isn't a user selected, if the logged in user is an admin
        isAdmin(state) {
            const userStore = useUserStore();

            return (
                userStore.isAdmin &&
                (state.user_id && state.agents.length ?
                    state.agents.find((agent) => agent.id == state.user_id)?.roles?.includes('MUVAL_ADMIN')
                :   true)
            );
        },

        getAgents(state) {
            return state.agents ?? [];
        },

        getAvatar(state) {
            if (state.agents.length) {
                return state.agents.find((agent) => agent.id == state.user_id)?.avatar;
            } else {
                return null;
            }
        },

        getTeam(state) {
            if (state.agents.length) {
                return state.agents.find((agent) => agent.id == state.user_id)?.team;
            } else {
                return null;
            }
        },

        getSelectAgent(state) {
            if (state.agents.length) {
                return state.agents.find((agent) => agent.id === state.user_id);
            }

            return null;
        },
    },
});
