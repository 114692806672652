import { defineStore } from 'pinia';

export const usePetTransportStore = defineStore('petTransport', {
    state: () => {
        return {
            loading: false,
            data: {},
            statuses: [],
            notes: {
                Template: `Service Type: XXXX (Door to door, Airport to Door etc)
Important Notes:

• Please advise of any health issues with the pet/s

• Pets 8 - 12 weeks old or 12 + years old require a Fit to Fly letter from a vet, for any air transport services. The vet must state "FIT TO FLY" on the certificate to avoid any issues during lodgement.

• For all pets’ health and welfare, their vaccinations must be up to date for any hotel boarding services: Minimum vaccination requirements are C5 Dogs / F3 Cats. Vaccinations must be confirmed and sighted before arrival. You can email a copy to: transport@hanrob.com.au

• All regional transport schedules are subject to capacities and we may need to change dates, subject to availability

• Virgin Air Freight Cargo have the right to refuse items that may be of risk to the pets or documents that cannot be attached to the crate

• Pet owners will be personally responsible when obtaining the items including additional postage cost.

• Brachycephalic Pets (Snub nosed breeds) are required to ensure their eligibility for air freight transport by having a Brachycephalic Obstructive Airway Syndrome (BOAS) assessment conducted by a qualified, registered vet who is a member of the Australian Veterinary Association (AVA) and agrees to complete the BOAS assessment and provide certification of fitness to fly by air cargo.

• Successful completion of the BOAS test is a requirement for transporting brachycephalic pets via air freight. For more specific and up-to-date information on these requirements and the procedures involved, you can visit https://freight.qantas.com/pets/brachycephalic-breeds.html or https://www.virginaustralia.com/au/en/travel-info/specific-travel/pets/ or contact Hanrob Pet Transport`,
            },
        };
    },
    actions: {
        /*
         * Retrieve a petTransport
         */
        async retrieve(orderId) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.get(`/orders/${orderId}/pet-transport`);
                this.mapData(data.pet_transport);
                await this.retrieveStatuses(orderId);
                this.loading = false;
            } catch (error) {
                console.log('Error retrieving petTransport', error);
                this.loading = false;
                throw error;
            }
        },

        /*
         * Create a petTransport
         */
        async create(orderId, petData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.post(`/orders/${orderId}/pet-transport`, {
                    ...petData,
                });
                this.mapData(data.pet_transport);
                await this.retrieveStatuses(orderId);
                this.loading = false;
            } catch (error) {
                console.log('Error creating petTransport', error);
                this.loading = false;
                throw error;
            }
        },

        /*
         * Update a petTransport
         */
        async update(orderId, petData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/pet-transport`, {
                    ...petData,
                });
                this.mapData(data.pet_transport);
                await this.retrieveStatuses(orderId);
                this.loading = false;
            } catch (error) {
                console.log('Error updating petTransport', error);
                this.loading = false;
                throw error;
            }
        },

        async updatePet(orderId, petId, petData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/pet-transport-items/${petId}`, {
                    ...petData,
                });
                const updatedPetIndex = this.data.pets.findIndex((pet) => pet.id === data.pet_transport_item.id);
                if (updatedPetIndex !== -1) {
                    this.data.pets[updatedPetIndex] = data.pet_transport_item;
                } else {
                    return new Error('Pet not found');
                }
            } catch (error) {
                console.log('Error updating a pet in petTransport', error);
                this.loading = false;
                throw error;
            }
        },

        async addPet(orderId, petData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.post(`/orders/${orderId}/pet-transport-items`, {
                    ...petData,
                });
                this.data.pets.push(data.pet_transport_item);
                return data;
            } catch (error) {
                console.log('Error creating another pet', error);
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async deletePet(orderId, petId) {
            try {
                await window.axios.delete(`/orders/${orderId}/pet-transport-items/${petId}`);
                this.data.pets = this.data.pets.filter((el) => el.id !== petId);
            } catch (e) {
                console.log('Error deleting pet', e);
            }
        },

        async inlineUpdate(orderId, petData) {
            try {
                // petData.vehicles.forEach((v) => {
                //   delete v.id;
                // })
                delete petData.pets;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/pet-transport`, {
                    ...petData,
                });
                this.mapData(data.pet_transport);
            } catch (error) {
                console.log('Error updating petTransport', error);
                this.loading = false;
                throw error;
            }
        },

        async calculateFee(orderId, wholesale_price) {
            try {
                const response = await window.axios.post(`/orders/${orderId}/pet-transport/calculate-fee`, {
                    wholesale_price,
                });

                return response.data;
            } catch (e) {
                console.log('Error calculating fee', e);
            }
        },

        async retrieveStatuses(orderId) {
            try {
                const response = await window.axios.get(`/orders/${orderId}/pet-transport/statuses`);
                this.statuses = response.data;
                return response.data;
            } catch (e) {
                console.log('Error retrieving statuses', e);
            }
        },

        mapData(pet) {
            console.log(pet);
            this.data = pet;
            this.data.pets = pet.pets.map((el) => {
                return {
                    id: el.id,
                    name: el.name,
                    animal: el.animal,
                    breed: el.breed,
                    age: el.age,
                    weight: el.weight,
                    reference_number: el.reference_number,
                    start_at_type: el.start_at_type,
                    start_at_by: el.start_at_by,
                    start_at_from: el.start_at_from,
                    end_at_type: el.end_at_type,
                    end_at_by: el.end_at_by,
                    end_at_from: el.end_at_from,
                    start_formatted: el.start_formatted,
                    end_formatted: el.end_formatted,
                };
            });
        },
    },
    getters: {
        getPetTransport(state) {
            return state?.data ?? null;
        },
        isConfirmed: (state) => {
            return ['ACCEPTED', 'PENDING'].includes(state.data?.status);
        },
        canDeleteAnyPet: (state) => {
            return state.data?.pets?.length > 1;
        },
        canEditInReady(state) {
            if (state.data?.status === 'READY') {
                const userStore = useUserStore();
                return userStore.hasAnyRole(['SUPER', 'MUVAL_ADMIN']);
            }

            return true;
        },
    },
});
