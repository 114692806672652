<template>
    <section class="mt-6 flex w-full flex-col lg:flex-row">
        <div class="flex w-full items-center justify-between">
            <button
                type="button"
                class="lg:hidden"
                @click="appStore.side = !appStore.side"
            >
                <SvgIcon
                    name="menu"
                    class="h-6 w-6"
                />
            </button>
            <span class="haptik hidden text-2xl font-medium lg:inline">{{ headerTitle }}</span>
            <div class="relative flex w-fit shrink-0 items-center gap-x-4">
                <RoleGuard
                    :guarded="['MUVAL_ADMIN', 'MUVAL_AGENT', 'MUVAL_TRIAGE', 'MUVAL_COMPANY', 'MUVAL_FINANCE', 'SUPER']"
                >
                    <BookingStatus />
                    <CommandPalette />
                    <router-link
                        class="relative items-center gap-x-1 lg:flex"
                        to="/inbox/calls"
                    >
                        <SvgIcon
                            name="calls"
                            class="size-5 text-muval-gray-1"
                        />
                        <div
                            v-show="badgeCount.phone > 0"
                            class="absolute -right-2.5 -top-2.5"
                        >
                            <span
                                class="flex size-[18px] items-center justify-center rounded-full bg-brand text-xs text-white"
                                v-text="badgeCount.phone"
                            />
                            <span
                                class="absolute top-0 -z-1 flex size-[18px] animate-ping items-center justify-center rounded-full bg-brand text-xs text-white"
                                v-text="' '"
                            />
                        </div>
                    </router-link>
                    <router-link
                        class="relative items-center gap-x-1 lg:flex"
                        to="/inbox/sms"
                    >
                        <SvgIcon
                            name="inbox"
                            class="size-5 text-muval-gray-1"
                        />
                        <div
                            v-show="badgeCount.text > 0"
                            class="absolute -right-2.5 -top-2.5"
                        >
                            <span
                                class="flex size-[18px] items-center justify-center rounded-full bg-brand text-xs text-white"
                                v-text="badgeCount.text"
                            />
                            <span
                                class="absolute top-0 -z-1 flex size-[18px] animate-ping items-center justify-center rounded-full bg-brand text-xs text-white"
                                v-text="' '"
                            />
                        </div>
                    </router-link>
                </RoleGuard>
                <NotificationIcon />
                <!-- Profile dropdown -->
                <ProfileDropDown />
                <UserInactiveModal v-if="userStore.isMuval" />
                <UserActivityMonitor v-if="userStore.isMuval" />
            </div>
        </div>
        <span class="haptik mt-6 inline text-2xl font-medium lg:hidden">{{ headerTitle }}</span>
    </section>
</template>
<script setup>
import useEmitter from '@plugins/useEmitter';
import { onMounted, onUnmounted } from 'vue';
import { startCase } from 'lodash';
import { useCompanyStore } from '@store';
import { capitalize } from '@utilities/filters';

const appStore = useAppStore();
const userStore = useUserStore();
const jobStore = useJobStore();
const tenderStore = useTenderStore();

const badgeCount = reactive({
    text: 0,
    phone: 0,
});

const getInboxCount = async () => {
    const { data: text } = await window.axios.get('inbox', {
        params: {
            count: 1,
            direction: 'INCOMING',
            seen: 0,
            type: 'TEXT',
            ...(userStore.getTeam != 'NONE' &&
                userStore.getTeam && {
                    team: userStore.getTeam,
                }),
        },
    });
    const { data: phone } = await window.axios.get('inbox', {
        params: {
            count: 1,
            direction: 'INCOMING',
            seen: 0,
            type: 'PHONE',
            ...(userStore.getTeam != 'NONE' &&
                userStore.getTeam && {
                    team: userStore.getTeam,
                }),
        },
    });

    badgeCount.text = text.count;
    badgeCount.phone = phone.count;
};

const emitter = useEmitter();

onMounted(() => {
    if (userStore.isAdmin || userStore.isAgent || userStore.isTriage) {
        getInboxCount();
        let stop = setInterval(getInboxCount, 60000);
        onUnmounted(() => {
            clearInterval(stop);
        });

        // When call/text items are marked as seen, refetch the notification count
        emitter.on('update:seen', getInboxCount);
    }
});

const route = useRoute();

const company = useCompanyStore();
const companyName = computed(() => {
    return company?.data?.name ?? null;
});

const headerTitle = computed(() => {
    switch (route.name) {
        case 'Dashboard':
            return userStore.getFirstName ? `Hi ${userStore.getFirstName}!` : null;
        case 'Search':
            return `Search ${userStore.search ?? ''}`;
        // Leads
        case 'LeadCreate':
            return 'Create Lead';
        // Interstate
        case 'InterstateTripCreate':
            return 'Create Interstate Trip';
        case 'InterstateTripEdit':
            return 'Edit Interstate Trip';
        case 'InterstateLaneCreate':
            return 'Create Interstate Lane';
        case 'InterstateLaneEdit':
            return 'Edit Interstate Lane';
        // Depots
        case 'DepotsCreate':
            return 'Create Depot';
        case 'DepotsEdit':
            return `Edit Depot`;
        // Companies
        case 'CompaniesView':
            return companyName.value;
        case 'CompaniesEdit':
            return 'Edit Company Profile';
        case 'CompaniesPreferences':
            return 'Company Preferences';
        // Local
        case 'LocalCreate':
            return 'Create Local';
        case 'LocalEdit':
            return 'Edit Local';
        // Fleet
        case 'FleetCreate':
            return 'Create Vehicle';
        case 'FleetEdit':
            return 'Edit Vehicle';
        // Staff
        case 'StaffCreate':
            return 'Create Staff Member';
        case 'StaffEdit':
            return 'Edit Staff Member';
        case 'CompanyStaffEdit':
            return 'Edit Staff Member';
        // SMS & CALL
        case 'InboxCalls':
            return 'Calls';
        case 'InboxSms':
            return 'Inbox';
        // Comms
        case 'CommunicationCreate':
            return 'Create Communication Template';
        case 'CommunicationEdit':
            return 'Edit Communication Template';
        // Jobs
        case 'JobView':
            return jobStore?.data?.order_ref ? `Job #${jobStore?.data?.order_ref ?? ''}` : '';
        // Quotes
        case 'QuoteView':
            return tenderStore?.tender?.order_ref ? `Quote #${tenderStore?.tender?.order_ref ?? ''}` : '';
        // Errors
        case '404Page':
            return '';
        default:
            return route?.meta?.title ?? capitalize(startCase(route.name));
    }
});
</script>
