import { defineStore } from 'pinia';

export const useOrderNotesStore = defineStore('orderNotes', () => {
    const notes = ref([]);
    const notesLength = computed(() => notes.value.length);

    const orderStore = useOrderStore();

    async function retrieveNotes(order_id = orderStore.getOrderId) {
        const response = await window.axios.get(
            `orders/${order_id}/notes?fields=type&values=AGENT_NOTE&sort=-created_at`,
        );
        notes.value = response.data.data;
    }

    async function createNote(data, order_id = orderStore.getOrderId) {
        const createdNote = await window.axios.post(`orders/${order_id}}/notes?type=AGENT_NOTE`, data);
        notes.value.unshift(createdNote.data.note);
    }

    async function updateNote(data, note_id, order_id = orderStore.getOrderId) {
        const response = await window.axios.patch(`orders/${order_id}/notes/${note_id}`, data);
        const note = response.data.note;
        let index = notes.value.findIndex((n) => n.id === note.id);
        notes.value[index] = note;
    }

    return {
        notes,
        notesLength,
        retrieveNotes,
        createNote,
        updateNote,
    };
});
