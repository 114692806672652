import { defineStore } from 'pinia';

export const useInsuranceStore = defineStore('insurance', {
    state: () => {
        return {
            loading: false,
            data: {},
            categories: [],
        };
    },
    actions: {
        /*
         * Retrieve a insurance
         */
        async retrieve(orderId) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.get(`/orders/${orderId}/insurance`);
                console.log(data);
                this.data = data.insurance;
                this.loading = false;
            } catch (error) {
                console.log('Error retrieving insurance', error);
                throw error;
            }
        },

        /*
         * Create a insurance
         */
        async create(orderId, insuranceData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.post(`/orders/${orderId}/insurance`, {
                    ...insuranceData,
                });
                this.data = data.insurance;
                this.loading = false;
            } catch (error) {
                console.log('Error creating insurance', error);
                throw error;
            }
        },

        /*
         * Update a insurance
         */
        async update(orderId, insuranceData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/insurance`, {
                    ...insuranceData,
                });
                this.data = data.insurance;
                this.loading = false;
            } catch (error) {
                console.log('Error updating insurance', error);
                throw error;
            }
        },

        /*
         * Delete an insurance
         */
        async delete(orderId, insuranceId) {
            try {
                this.loading = true;
                const response = await window.axios.delete(`/orders/${orderId}/insurance/${insuranceId}`);
                console.log('Deleted insurance', response);
                this.loading = false;
            } catch (error) {
                console.log('Error deleting insurance', error);
                throw error;
            }
        },

        async getCategories() {
            try {
                this.loading = true;
                const {
                    data: { data: res },
                } = await window.axios.get('inventory-categories?type=INSURANCE');
                this.categories = res;
                this.loading = false;
            } catch (error) {
                console.log('Error getting insurance categories', error);
                throw error;
            }
        },
    },
    getters: {
        getInsurance(state) {
            return state?.data ?? null;
        },

        getStatus(state) {
            return state?.data?.status;
        },

        hasPaid(state) {
            return state?.data?.payment_status === 'PAID';
        },

        isConfirmed(state) {
            return state?.data?.order_item_status === 'CONFIRMED';
        },
    },
});
