<template>
    {{ link?.text }}
    <a
        v-if="link"
        target="_blank"
        class="cursor-pointer text-focus"
        :href="link.href"
    >
        <span class="underline">here</span>
    </a>
</template>

<script setup>
const props = defineProps({
    links: {
        required: false,
        type: Object,
        default: () => ({}),
    },
    errorKey: {
        required: false,
        type: [String, null],
        default: null,
    },
});

const link = computed(() => {
    if (Object.keys(props.links).length > 0) {
        return props.links[props.errorKey];
    } else {
        return null;
    }
});
</script>
