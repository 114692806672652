import { defineStore } from 'pinia';

export const usePayoutsStore = defineStore('payouts', () => {
    const payouts = ref([]);

    const orderStore = useOrderStore();
    const orderId = toRef(orderStore, 'getOrderId');

    whenever(
        orderId,
        async (order) => {
            const resp = await window.axios.get('/payouts', {
                params: {
                    order,
                },
            });
            payouts.value = resp.data.data;
        },
        {
            immediate: true,
        },
    );

    return {
        payouts,
    };
});
