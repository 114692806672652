import { defineStore } from 'pinia';
import { notify } from '@kyvg/vue3-notification';

export const useCustomerAutomationsStore = defineStore('customerAutomations', () => {
    const automation = ref();

    const eventTypes = {
        IDLE_IN_PIPE: 'Idle in pipe',
        UNRESPONSIVE: 'Unresponsive',
        FORM_COMPLETED: 'Form completed',
    };

    const automationTypes = {
        EMAIL: 'Email',
        SMS: 'SMS',
    };

    async function createAutomation(values) {
        await window.axios.post('customer-automations', values);
    }

    async function retrieveAutomation(id) {
        const response = await window.axios.get('customer-automations/' + id);
        automation.value = formatData(response.data.customer_automation);
    }

    async function updateAutomation(id, values) {
        await window.axios.patch('customer-automations/' + id, values);
    }

    async function createStep(automation_id, values) {
        await window.axios.post('customer-automations/' + automation_id + '/steps', values);
    }

    async function updateStep(automation_id, step_id, values) {
        await window.axios.patch('customer-automations/' + automation_id + '/steps/' + step_id, values);
    }

    async function deleteStep(step_id) {
        const response = await window.axios.delete('customer-automations/' + automation.value.id + '/steps/' + step_id);
        await retrieveAutomation(automation.value.id);
        notify({
            title: 'Undo delete',
            group: 'app_restore',
            text: 'delete',
            data: {
                url: `customer-automations/${automation.value.id}/steps`,
                type: 'Step',
                lastDeleted: { id: step_id },
                key: 'customer-automations',
            },
        });
        return response;
    }

    async function restoreStep(step_id) {
        step_id;
    }

    function formatData(data) {
        let { id, label, description, active, end_on_engagement, event, trigger_start_date, flow_end_date, steps } =
            data;

        steps = steps.map(({ id, trigger_delay, automation_type, automation_template_id, active }) => {
            return {
                id,
                trigger_delay,
                automation_type,
                automation_template_id,
                active,
            };
        });

        return {
            id,
            label,
            description,
            active,
            end_on_engagement,
            event,
            trigger_start_date,
            flow_end_date,
            steps,
        };
    }

    return {
        automation,
        createAutomation,
        retrieveAutomation,
        updateAutomation,
        createStep,
        updateStep,
        deleteStep,
        restoreStep,
        eventTypes,
        automationTypes,
    };
});
