// Filters
import pluralize from 'pluralize';
import Dinero from 'dinero.js';

export function centsToDollar(value) {
    if (!value) {
        return 'NA';
    }
    value = parseInt(value);
    return Dinero({ amount: value }).toFormat('$0.00');
}

export function pluralise(word, count, inclusive = true) {
    return pluralize(word, count, inclusive);
}

export function camelize(text) {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    return text.substr(0, 1).toLowerCase() + text.substr(1);
}

// Convert _ to space and capitalize first letter and lower case the rest
export function humanize(text) {
    text = text.replace(/_/g, ' ');
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function format_date(fromMinDate, fromMaxDate, toMinDate, toMaxDate) {
    let fromString = '';
    if (fromMinDate) fromString += fromMinDate;
    if (fromMaxDate) fromString += ' - ' + fromMaxDate;
    if (toMinDate || toMaxDate) fromString += ' → ';
    if (toMinDate) fromString += toMinDate;
    if (toMaxDate) fromString += ' - ' + toMaxDate;

    return fromString;
}

export function capitalize(str) {
    if (str === null || str === undefined) {
        return null;
    }
    const lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
}

export function firstLetter(word) {
    if (word) {
        return word.charAt(0);
    }
}

export function formatPhoneNumber(phoneNum) {
    if (phoneNum.substring(3, 7) == '1300') {
        // 1300 number
        return `${phoneNum.substring(3, 7)} ${phoneNum.substring(7, 10)} ${phoneNum.substring(10, 13)}`;
    } else if (phoneNum.substring(3, 4) == '4') {
        // mobile
        return `0${phoneNum.substring(3, 6)} ${phoneNum.substring(6, 9)} ${phoneNum.substring(9, 12)}`;
    } else {
        // landline number
        return `0${phoneNum.substring(3, 4)} ${phoneNum.substring(4, 8)} ${phoneNum.substring(8, 13)}`;
    }
}

export function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
}

export default {
    firstLetter,
    humanize,
    centsToDollar,
    pluralise,
    capitalize,
    formatPhoneNumber,
    format_date,
    fmtMSS,
};
