import { defineStore } from 'pinia';

// Api Helper
import companyAPI from '@api/company';
import companyContactAPI from '@api/companyContact';

export const useCompanyStore = defineStore('company', {
    state: () => {
        return {
            loading: false,
            companyId: null,
            data: null,
            settings: null,
            contacts: [],
            predefined: {},
        };
    },
    actions: {
        async create(companyData) {
            try {
                this.loading = true;
                await companyAPI.create(companyData);
            } catch (e) {
                console.error('CompanyStore :: create', e);
                throw e;
            } finally {
                this.loading = false;
            }
        },

        async update(companyId, companyData) {
            try {
                this.loading = true;
                const companyUpdateResponse = await companyAPI.update(companyId, {
                    company_id: companyId,
                    ...companyData,
                    ...(companyData?.about && {
                        about: companyData.about.replace(/<\/?[^>]+>/gi, ''),
                    }),
                    ...(companyData?.description && {
                        description: companyData.description.replace(/<\/?[^>]+>/gi, ''),
                    }),
                });
                this.data = companyUpdateResponse.data.company;
                this.loading = false;
                return companyUpdateResponse.data.company;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async updateSettings(companyId, companyData) {
            try {
                this.loading = true;
                const companyUpdateResponse = await companyAPI.updateSettings(companyId, {
                    company_id: companyId,
                    ...companyData,
                });
                this.settings = companyUpdateResponse.data.companySettings;
                return companyUpdateResponse.data.companySettings;
            } catch (e) {
                console.error('CompanyStore :: updateSettings', e);
            } finally {
                this.loading = false;
            }
        },

        setCompanyId(companyId) {
            this.companyId = companyId;
        },

        async retrieve(companyId, query) {
            try {
                this.loading = true;
                this.companyId ? this.companyId : this.setCompanyId(companyId);
                const response = await companyAPI.retrieve(this.companyId, query);
                console.log(response);
                this.data = response.data.data;
                this.companyId = response.data.data.id;
                if (this.data.about) {
                    this.data.about = this.data.about.replace(/<\/?[^>]+>/gi, '');
                }
                if (this.data.description) {
                    this.data.description = this.data.description.replace(/<\/?[^>]+>/gi, '');
                }
                // this.data.description
                this.loading = false;
            } catch (error) {
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async retrieveSettings(companyId, query = {}) {
            try {
                this.loading = true;
                const response = await companyAPI.retrieveSettings(companyId, query);
                this.settings = response.data.companySettings;
                return response.data.companySettings;
            } catch (e) {
                console.error('CompanyStore :: retrieveSettings', e);
            } finally {
                this.loading = false;
            }
        },

        async retrieveLogo(companyId = this.getCompanyId) {
            try {
                const {
                    data: { data },
                } = await window.axios.get(`/companies/${companyId}/logo`);
                this.data.logo = data.url;
            } catch (e) {
                console.error('CompanyStore :: retrieveLogo', e);
            }
        },

        async retrieveContacts() {
            try {
                this.loading = true;
                this.contacts = await companyContactAPI.index(this.companyId);
            } catch (error) {
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async retrievePredefined() {
            const response = await window.axios.get('/companies/predefined');
            this.predefined = response.data;
        },
    },
    getters: {
        getName() {
            return this.data?.name;
        },

        getCompanyId: (state) => {
            return state.data?.id;
        },

        getDocuments() {
            return this.data?.documents;
        },

        hasDocuments() {
            return this.data?.documents?.length > 0;
        },

        getInterstatePriceValues() {
            return this.data?.interstatePriceValues;
        },

        hasInterstatePriceValues() {
            return this.data?.interstatePriceValues?.length > 0;
        },
        getCompany: (state) => {
            return state.data;
        },
        getStatus: (state) => {
            return state.data?.status;
        },
        getProfileNote: (state) => {
            return state.data?.notes;
        },
        getAdditionalCharges: (state) => {
            return state.data?.additional_charges;
        },
        getStatusList(state) {
            return state.predefined.statuses;
        },
        getBusinessTypeList(state) {
            return state.predefined.business_type;
        },
        getCompanyTypeList(state) {
            return state.predefined.types;
        },

        isRelocation(state) {
            return state.data?.type === 'RELOCATIONS';
        },
    },
});
