import { defineStore } from 'pinia';

export const useRefundsStore = defineStore('refunds', {
    state: () => {
        return {
            refunds: [],
        };
    },
    actions: {
        // Refunds
        async fetchRefunds(orderId) {
            const {
                data: { data: response },
            } = await window.axios.get(`/orders/${orderId}/refunds`);
            this.refunds = response;
            console.log('Refunds data', response);
        },
        async patchRefund(orderId, refundId, values) {
            const response = await window.axios.patch(`/orders/${orderId}/payments/${refundId}`, {
                ...values,
            });
            let index = this.refunds.findIndex((item) => item.id == refundId);
            this.refunds[index] = response.data.order_refund;
        },
        async patchExternalRefund(orderId, refundId, values) {
            const response = await window.axios.patch(`/orders/${orderId}/refunds/external/${refundId}`, {
                stripe_id: values.pi_id,
                amount: values.amount,
                notes: values.notes,
            });
            let index = this.refunds.findIndex((item) => item.id == refundId);
            this.refunds[index] = response.data.order_refund;
        },
        async deleteRefund(orderId, refundId) {
            await window.axios.delete(`/orders/${orderId}/refunds/${refundId}`);
            // Removal of refund is handled by the emitters in Orderrefunds
        },
        async addRefund(orderId, values) {
            const response = await window.axios.post(`/orders/${orderId}/refunds/external`, {
                ...values,
            });
            this.refunds.push(response.data.order_refund);
        },
    },
    getters: {
        getRefunds: (state) => {
            return state.refunds ?? [];
        },
    },
});
