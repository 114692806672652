export default [
    {
        path: '/jobs',
        name: 'Jobs',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/jobs/JobsIndexRemovalist.vue'),
            agent: () => import('@views/jobs/JobsIndexAgent.vue'),
        },
    },
    {
        path: '/jobs/:id/view',
        name: 'JobView',
        meta: {
            requiresAuth: true,
        },
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        components: {
            default: () => import('@views/jobs/JobsViewRemovalist.vue'),
            agent: () => import('@views/jobs/JobsViewRemovalist.vue'),
        },
    },
];
