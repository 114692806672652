/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module Depot
 */

const ROUTE = '/depots';

function index(params) {
    return window.axios.get(ROUTE, { params: params });
}

async function create(depot) {
    const response = await window.axios.post(ROUTE, depot);
    return response;
}

async function retrieve(depotId) {
    const response = window.axios.get(`${ROUTE}/${depotId}`);
    return response;
}

async function update(depotId, depot) {
    return window.axios.patch(`${ROUTE}/${depotId}`, depot);
}

function destroy(depotId) {
    return window.axios.delete(`${ROUTE}/${depotId}`);
}

export default {
    index,
    create,
    retrieve,
    update,
    destroy,
};
