import { defineStore } from 'pinia';

export const usePaymentsStore = defineStore('payments', {
    state: () => {
        return {
            payments: [],
        };
    },
    actions: {
        // Payments
        async fetchPayments(orderId) {
            const {
                data: { data: response },
            } = await window.axios.get(`/orders/${orderId}/payments`);
            this.payments = response;
            console.log('Payments data', response);
        },
        async patchPayment(orderId, paymentId, values) {
            const response = await window.axios.patch(`/orders/${orderId}/payments/${paymentId}`, {
                ...values,
            });
            let index = this.payments.findIndex((item) => item.id == paymentId);
            this.payments[index] = response.data.order_payment;
        },
        async deletePayment(orderId, paymentId) {
            await window.axios.delete(`/orders/${orderId}/payments/${paymentId}`);
            // Removal of payment is handled by the emitters in OrderPayments
        },

        // Refunds
        async fetchRefunds(orderId) {
            const {
                data: { data: response },
            } = await window.axios.get(`/orders/${orderId}/refunds`);
            this.refunds = response;
            console.log('Refunds data', response);
        },
        async patchRefund(orderId, refundId, values) {
            const response = await window.axios.patch(`/orders/${orderId}/payments/${refundId}`, {
                ...values,
            });
            let index = this.refunds.findIndex((item) => item.id == refundId);
            this.refunds[index] = response.data.order_refund;
        },
        async patchExternalRefund(orderId, refundId, values) {
            const response = await window.axios.patch(`/orders/${orderId}/refunds/external/${refundId}`, {
                stripe_id: values.pi_id,
                amount: values.amount,
                notes: values.notes,
            });
            let index = this.refunds.findIndex((item) => item.id == refundId);
            this.refunds[index] = response.data.order_refund;
        },
        async deleteRefund(orderId, refundId) {
            await window.axios.delete(`/orders/${orderId}/refunds/${refundId}`);
            // Removal of refund is handled by the emitters in Orderrefunds
        },
        async addRefund(orderId, values) {
            const response = await window.axios.post(`/orders/${orderId}/refunds/external`, {
                ...values,
            });
            this.refunds.push(response.data.order_refund);
        },
    },
    getters: {
        getPayments: (state) => {
            return state.payments;
        },
        getRefunds: (state) => {
            return state.refunds;
        },
    },
});
