import { defineStore } from 'pinia';

export const useCarTransportStore = defineStore('carTransport', {
    state: () => {
        return {
            loading: false,
            data: {},
            statuses: [],
            notes: {
                Template: `Service Type: XXXX (Door to door, Depot to Door etc)

Approximate Delivery Timeframe: XXXX business days (ESTIMATE ONLY)

NOTE: Vehicle must be empty of personal items for the move

Please be advised this quotation is based on standard dimensions only and a vehicle market value of $60,000.00 or below. Vehicles must be fully drivable. Any modifications will incur additional charges. Please note minimum ground clearance is 120mm (12cm) for Standard & Enclosed services. Enclosed services are subject to availability.
`,
            },
        };
    },
    actions: {
        /*
         * Retrieve a carTransport
         */
        async retrieve(orderId) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.get(`/orders/${orderId}/car-transport`);
                this.mapData(data.car_transport);
                await this.retrieveStatuses(orderId);
                this.loading = false;
            } catch (error) {
                console.log('Error retrieving carTransport', error);
                this.loading = false;
                throw error;
            }
        },

        /*
         * Create a carTransport
         */
        async create(orderId, carData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.post(`/orders/${orderId}/car-transport`, {
                    ...carData,
                });
                this.mapData(data.car_transport);
                await this.retrieveStatuses(orderId);
                this.loading = false;
            } catch (error) {
                console.log('Error creating carTransport', error);
                this.loading = false;
                throw error;
            }
        },

        /*
         * Update a carTransport
         */
        async update(orderId, carData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/car-transport`, {
                    ...carData,
                });
                this.mapData(data.car_transport);
                await this.retrieveStatuses(orderId);
                this.loading = false;
            } catch (error) {
                console.log('Error updating carTransport', error);
                this.loading = false;
                throw error;
            }
        },

        async updateCar(orderId, carId, carData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/car-transport-items/${carId}`, {
                    ...carData,
                });
                const updatedVehicleIndex = this.data.vehicles.findIndex(
                    (vehicle) => vehicle.id === data.car_transport_item.id,
                );
                if (updatedVehicleIndex !== -1) {
                    this.data.vehicles[updatedVehicleIndex] = data.car_transport_item;
                } else {
                    return new Error('Vehicle not found');
                }
            } catch (error) {
                console.log('Error updating a car in carTransport', error);
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async addCar(orderId, carData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.post(`/orders/${orderId}/car-transport-items`, {
                    ...carData,
                });
                this.data.vehicles.push(data.car_transport_item);
                return data;
            } catch (error) {
                console.log('Error creating another car', error);
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async deleteCar(orderId, carId) {
            try {
                await window.axios.delete(`/orders/${orderId}/car-transport-items/${carId}`);
                this.data.vehicles = this.data.vehicles.filter((el) => el.id !== carId);
            } catch (e) {
                console.log('Error deleting car', e);
            }
        },

        async inlineUpdate(orderId, carData) {
            try {
                // carData.vehicles.forEach((v) => {
                //   delete v.id;
                // })
                delete carData.vehicles;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/car-transport`, {
                    ...carData,
                });
                this.mapData(data.car_transport);
                await this.retrieveStatuses(orderId);
            } catch (error) {
                console.log('Error updating carTransport', error);
                throw error;
            }
        },

        async calculateFee(orderId, wholesale_price) {
            try {
                const response = await window.axios.post(`/orders/${orderId}/car-transport/calculate-fee`, {
                    wholesale_price,
                });

                return response.data;
            } catch (e) {
                console.log('Error calculating fee', e);
            }
        },

        async retrieveStatuses(orderId) {
            try {
                const response = await window.axios.get(`/orders/${orderId}/car-transport/statuses`);
                this.statuses = response.data;
                return response.data;
            } catch (e) {
                console.log('Error retrieving statuses', e);
            }
        },

        mapData(car) {
            console.log(car);
            this.data = { ...car };
            this.data.vehicles = this.data.vehicles.map((el) => {
                return {
                    id: el.id,
                    make: el.make,
                    model: el.model,
                    registration_number: el.registration_number,
                    registration_state: el.registration_state,
                    reference_number: el.reference_number,
                    start_at_type: el.start_at_type,
                    start_at_by: el.start_at_by,
                    start_at_from: el.start_at_from,
                    end_at_type: el.end_at_type,
                    end_at_by: el.end_at_by,
                    end_at_from: el.end_at_from,
                    start_formatted: el.start_formatted,
                    end_formatted: el.end_formatted,
                };
            });
        },
    },
    getters: {
        getCarTransport: (state) => {
            return state.data;
        },

        isConfirmed: (state) => {
            return ['ACCEPTED', 'PENDING'].includes(state.data?.status);
        },

        getCount: (state) => {
            return state.data?.vehicles?.length;
        },

        canDeleteAnyVehicle: (state) => {
            return state.data?.vehicles?.length > 1;
        },

        canEditInReady(state) {
            if (state.data?.status === 'READY') {
                const userStore = useUserStore();
                return userStore.hasAnyRole(['SUPER', 'MUVAL_ADMIN']);
            }

            return true;
        },
    },
});
