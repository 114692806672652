<template>
    <div class="relative mb-6 min-h-[100px] w-full">
        <div class="relative w-full">
            <textarea
                id="textarea"
                ref="textarea"
                class="peer block min-h-[100px] w-full resize-y overflow-y-auto rounded-input border px-3 pt-3 text-sm placeholder-transparent focus:border-focus focus:outline-none focus:ring-0"
                :class="{
                    'border-error': errorMessage,
                    'border-muval-gray-4 bg-white hover:border-muval-gray-3': !errorMessage,
                    'placeholder-gray-400': !label && placeholder,
                    'bg-muval-gray-5': disabled,
                }"
                rows="1"
                :placeholder="placeholder ? placeholder : label"
                :value="inputValue"
                :maxlength="limit"
                :disabled="disabled"
                :readonly="readonly"
                @input="onUpdate"
                @change="handleChange"
                @blur="handleBlur"
            ></textarea>
            <div
                v-if="label"
                class="peer pointer-events-none absolute -top-2 left-1 z-1 line-clamp-1 text-xs text-gray-400 transition-all duration-75 peer-placeholder-shown:top-2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-focus skeleton:hidden"
            >
                <span class="px-2">{{ label }}</span>
                <div class="absolute top-2 -z-10 h-0.5 w-full bg-white dark:bg-gray-900"></div>
            </div>

            <div class="absolute -bottom-5 right-0 flex whitespace-nowrap">
                <p
                    v-if="!errorMessage && limit !== null"
                    class="my-auto ml-2 text-xs text-gray-400"
                >
                    {{ limit - limitChar }} / {{ limit }}
                </p>
            </div>

            <div
                v-if="errorMessage"
                class="absolute -bottom-5 left-0 flex w-full truncate whitespace-nowrap text-xxs"
                :class="{ 'text-error': errorMessage }"
            >
                <div class="text-xxs">{{ errorMessage }}</div>
            </div>
        </div>
        <!-- Skeleton -->
        <div class="skeleton:loader"></div>
    </div>
</template>

<script>
import { useField } from 'vee-validate';

import { idGen } from '@utilities/idGen';

export default {
    name: 'MuvalTextArea',
    props: {
        modelValue: {
            type: [String, null],
            required: false,
            default: null,
        },
        label: {
            type: [String, null],
            required: false,
            default: null,
        },
        placeholder: {
            type: [String, null],
            required: false,
            default: null,
        },
        vid: {
            type: String,
            required: false,
            default: () => idGen(6),
        },
        limit: {
            type: Number,
            required: false,
            default: 3000,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { value: inputValue, errorMessage, handleBlur, handleChange, meta } = useField(props.vid, undefined);

        const validationHover = ref(false);

        const limit = ref(props.limit);

        const limitChar = computed(() => {
            if (inputValue && inputValue.value && inputValue.value.length) {
                let currentChar = limit.value - inputValue.value.length;

                if (currentChar >= 0) {
                    return currentChar;
                } else {
                    return 0;
                }
            }

            return limit.value;
        });

        const undoValue = ref(null);
        function clearValue() {
            undoValue.value = inputValue.value;
            inputValue.value = '';
        }
        const keys = useMagicKeys();
        const shiftCtrlZ = keys['Ctrl+Z'];

        watch(shiftCtrlZ, (v) => {
            if (v) {
                inputValue.value = undoValue.value;
            }
        });

        watch(inputValue, (newVal) => {
            if (newVal === props.modelValue) {
                return;
            }
            // sync the model value with vee-validate model
            emit('update:modelValue', newVal);
        });

        // and you need to listen for `modelValue` prop changes
        watch(
            () => props.modelValue,
            (newModel) => {
                if (newModel === inputValue.value) {
                    return;
                }

                // Sync the vee-validate model with model value
                inputValue.value = newModel;
            },
        );

        onMounted(() => {
            let textarea = document.getElementById('textarea');
            if (textarea && textarea.style) {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        });

        function onUpdate(event) {
            handleChange(event);
            event.target.style.height = 'auto';
            event.target.style.height = `${event.target.scrollHeight}px`;
        }

        return {
            validationHover,
            clearValue,
            onUpdate,
            inputValue,
            handleChange,
            handleBlur,
            errorMessage,
            meta,
            limitChar,
        };
    },
};
</script>
