import { defineStore } from 'pinia';
import { useMoveStore } from '@store/move';
import { useCarTransportStore } from '@store/carTransport';
import { usePetTransportStore } from '@store/petTransport';
import { useOrderAddonStore } from '@store/orderAddon';
import { useInsuranceStore } from '@store/insurance';
import { useCustomerStore } from './customer';
import customerApi from '@api/customer';

export const useOrderStore = defineStore('order', {
    state: () => {
        return {
            loading: false,
            relatedOrdersLoading: false,
            data: {
                customer: null,
            },
            predefined: {
                agents: [],
            },
            mapData: {},
            bookingLink: '',
        };
    },
    getters: {
        getCustomer: (state) => {
            return state.data?.customer;
        },

        getCustomerId: (state) => {
            return state.data?.customer?.id ?? null;
        },

        getItems: (state) => {
            return state.data?.items;
        },

        getOrderId: (state) => {
            return state.data.id;
        },

        getOrderRef(state) {
            return state.data?.ref;
        },

        getAssignee(state) {
            return state.data?.agent;
        },

        getStatus(state) {
            return state.data?.status;
        },

        getMoveIndex: (state) => {
            return state.data?.items?.findIndex((item) => item.service_type == 'Move');
        },

        getMoveItem: (state) => {
            return state.data?.items?.find((item) => item.service_type == 'Move');
        },

        getMove: () => {
            const moveStore = useMoveStore();
            return moveStore.getMove;
        },

        getMoveId: (state) => {
            return state.getMove?.id;
        },

        getCarTransport: () => {
            const carTransportStore = useCarTransportStore();
            return carTransportStore.getCarTransport;
        },

        getPetTransport: () => {
            const petTransportStore = usePetTransportStore();
            return petTransportStore.getPetTransport;
        },

        getAddons: () => {
            const orderAddonStore = useOrderAddonStore();
            return orderAddonStore.getAddons;
        },

        getInsurance: () => {
            const insuranceStore = useInsuranceStore();
            return insuranceStore.getInsurance;
        },

        hasInsurance(state) {
            return state.data?.items?.some((item) => item.productable_type == 'insurance');
        },

        isPendingBankDepositUnconfirmed(state) {
            return state.data?.items?.some((item) => item.is_pending_bank_deposit && !item.is_confirmed);
        },

        isPendingBankDepositConfirmed(state) {
            return state.data?.items?.some((item) => item.is_pending_bank_deposit && item.is_confirmed);
        },

        isPendingApproval(state) {
            return state.data?.items?.some((item) => item.is_pending_approval);
        },

        getCreatedAt: (state) => {
            return state.data?.created_at_formatted;
        },

        isReturningCustomer: (state) => {
            return state.data.related_orders?.length > 0;
        },

        hasBooking: (state) => {
            return state.data.related_orders?.some((booking) => booking.booked);
        },

        getFirstName: (state) => {
            return state.data?.customer?.first_name;
        },

        getLastName: (state) => {
            return state.data?.customer?.last_name;
        },

        getEmail: (state) => {
            return state.data?.customer?.email;
        },

        getOpportunityAt: (state) => {
            return state.data?.opportunity_at_formatted;
        },

        canBeTrashed(state) {
            const userStore = useUserStore();

            if (state.data?.state != 'BIN') {
                if (userStore.hasAnyRole(['MUVAL_ADMIN', 'SUPER'])) {
                    // can do if admin or super regardless of status
                    return true;
                } else if (!['PARTIALLY_CONFIRMED', 'CONFIRMED'].includes(state.data?.status)) {
                    // agents can only bin assinged orders
                    if (userStore.isAgent && this.getAssignee?.id != userStore.getUserId) {
                        return false;
                    }
                    // can bin regardless of role if order is not in these statuses
                    return true;
                }
            }
            return false;
        },

        canShowConnectionPrompt: (state) => {
            // utility connections are not supported for Corporate Relocations at this stage
            if (state.isRelocation) {
                return false;
            }

            if (state.getAllowedConnectionPartner == 'MOVE_ME_IN') {
                // never show connection prompt for Move Me In referrals
                return false;
            } else if (state.hasAllowedConnectionPartner) {
                // show if not already set
                return state.data?.connection_request_status == null;
            } else {
                // otherwise hide
                return false;
            }
        },

        canAllowConnectionEdit: (state) => {
            if (
                // this is to guard against the referral partner changing after sending data to a provider
                // e.g. if a customer opts in via the booking form to MyConnect, and the referral
                // partner changes to Move Me In or Ten Ants when the referrer is updated, we should
                // not show the connection prompt
                state.hasAllowedConnectionPartner &&
                state.data?.connection_partner &&
                state.data?.connection_partner != state.getAllowedConnectionPartner &&
                state.data?.connection_partner_response_status == 'SUCCESS'
            ) {
                return false;
            }

            return true;
        },

        canShowConnectionWarning: (state) => {
            if (
                // if has the legacy order meta ref of value "sorted", display the warning
                state.data?.meta?.some(({ key_original, value }) => key_original == 'ref' && value == 'sorted')
            ) {
                return true;
            } else if (state.getAllowedConnectionPartner === 'MOVE_ME_IN') {
                return true;
            } else {
                // otherwise, show if no connection partner defined
                return state.getOrderRef && !state.hasAllowedConnectionPartner;
            }
        },

        canShowCashBackWarning: (state) => {
            return (
                state.canShowConnectionWarning ||
                (state.hasAllowedConnectionPartner && state.getAllowedConnectionPartner != 'MY_CONNECT')
            );
        },

        getAllowedConnectionPartner(state) {
            return state.data?.allowed_connection_partner;
        },

        hasAllowedConnectionPartner(state) {
            return state.getAllowedConnectionPartner != null;
        },

        // only assigned orders can be actioned (notes/actions/comms) by non-admins
        canActionOrder() {
            const userStore = useUserStore();

            return (
                userStore.hasAnyRole(['MUVAL_ADMIN', 'SUPER', 'MUVAL_TRIAGE']) || this.getAssignee || this.isConfirmed
            );
        },

        isDraft(state) {
            return state.data?.status == 'DRAFT';
        },

        isLegacy(state) {
            return state.data?.is_legacy;
        },

        isConfirmed(state) {
            return ['JOB', 'JOB_PARTIAL', 'JOB_CLOSED'].includes(state.data?.state);
        },

        isSafeToEmail(state) {
            return state.data?.safe_to_email;
        },

        isSafeToSMS(state) {
            return state.data?.safe_to_sms;
        },

        isSafeToCall(state) {
            return state.data?.safe_to_call;
        },

        paymentSettings(state) {
            return state.predefined?.payment_settings;
        },

        systemCardFeesEnabled(state) {
            return state.paymentSettings?.card_fees_enabled;
        },

        cardFeesEnabled(state) {
            return state.systemCardFeesEnabled && state.data?.card_fee;
        },

        getInsurancePolicyType(state) {
            return state?.data?.insurance_policy_type;
        },

        getInsurancePolicyBrand(state) {
            return state?.data?.insurance_policy_brand;
        },

        getInsurancePolicyVerbPresent(state) {
            return state?.data?.insurance_policy_verb_present;
        },

        getInsurancePolicyVerbPast(state) {
            return state?.data?.insurance_policy_verb_past;
        },

        getInsurancePolicyPremiumLabel(state) {
            return state?.data?.insurance_policy_premium_label;
        },

        getInsurancePolicyDocumentLabel(state) {
            return state?.data?.insurance_policy_document_label;
        },

        isRelocation(state) {
            return state.data?.is_relocation ?? false;
        },

        getRelocation(state) {
            return state.data?.relocation ?? {};
        },

        isLoaded(state) {
            return state.data?.id != null;
        },
    },
    actions: {
        getServiceLabel(serviceLabel) {
            if (serviceLabel == 'Insurance') {
                return this.getInsurancePolicyBrand;
            }

            return serviceLabel;
        },

        setOrderId(orderId) {
            this.data.id = orderId;
        },

        async update(order, orderId = this.getOrderId) {
            try {
                this.loading = true;
                const { data } = await window.axios.patch(`/orders/${orderId}`, {
                    ...order,
                });

                this.mapResponseValues(data.order);

                return data.order;
            } catch (error) {
                this.loading = false;

                throw error;
            } finally {
                this.loading = false;
            }
        },

        async retrieve(orderId = this.getOrderId, returnResponse = false) {
            try {
                this.loading = true;

                const { data } = await window.axios.get(`/orders/${orderId}`);

                console.log(data);

                if (returnResponse) {
                    this.loading = false;

                    return data.order;
                } else {
                    this.data = {
                        ...this.data,
                        ...data.order,
                    };
                    this.loading = false;
                }
            } catch (error) {
                this.loading = false;

                throw error;
                // let the form component display the error
            }
        },

        async retrieveOrderPredefined(orderId = this.getOrderId) {
            const resp = await window.axios.get(`/orders/${orderId}/predefined`);
            this.data.predefined = resp.data;
        },

        async declineOffer(offer, status, orderId = this.getOrderId) {
            const resp = await window.axios.post(`/orders/${orderId}/decline-offer`, {
                offer,
                status,
            });
            this.data.predefined.offers = resp.data.offers;

            return resp.data.offers;
        },

        async addServiceShell(offer, orderId = this.getOrderId) {
            await window.axios.post(`/orders/${orderId}/accept-offer`, {
                offer,
            });
        },

        async retrievePredefined(noAff = false) {
            try {
                this.loading = true;

                const { data } = await window.axios.get('orders/predefined');

                this.predefined = data.predefined;

                const response = await window.axios.get('/access-tags');

                this.predefined.interstate_price_items = response.data.data;

                if (!noAff) {
                    const affiliatesResponse = await window.axios.get('affiliates');

                    this.predefined.affiliates = affiliatesResponse.data.data;
                }

                this.predefined.services.INSURANCE = this.predefined.insurance_policy_brand;
            } catch (error) {
                this.loading = false;

                throw error;
            } finally {
                this.loading = false;
            }
        },

        async fetchItems(orderId = this.getOrderId) {
            try {
                this.loading = true;

                const { data } = await window.axios.get(`/orders/${orderId}/items`);

                this.data.items = data.data;

                return data;
            } catch (error) {
                this.loading = false;

                throw error;
            } finally {
                this.loading = false;
            }
        },

        async getTotals() {
            const { data: resp } = await window.axios.get(`/orders/${this.getOrderId}/totals`);

            this.data.price_total = resp.price_total;
            this.data.card_fee_total = resp.card_fee_total;
            this.data.price_sub_total = resp.price_sub_total;
            this.data.price_owing = resp.price_owing;
            this.data.payment_total = resp.payment_total;

            resp.items.forEach((item, itemIndex) => {
                this.data.items[itemIndex] = {
                    ...this.data.items[itemIndex],
                    ...item,
                };
            });
        },

        async getRouteData(orderId = this.getOrderId) {
            const resp = await window.axios.get(`/orders/${orderId}/route`);

            this.mapData = resp.data.route;

            console.log(resp);
        },

        async fetchBookingLink() {
            try {
                this.loading = true;

                const { data } = await window.axios.get(`/orders/${this.getOrderId}/link`);

                this.bookingLink = data.link;

                return data.link;
            } catch (error) {
                this.loading = false;

                throw error;
            } finally {
                this.loading = false;
            }
        },

        async retrieveRelatedOrders() {
            try {
                this.relatedOrdersLoading = true;

                const { data } = await window.axios.get(`/orders/${this.getOrderId}/related-orders`);

                this.data = { ...this.data, related_orders: data };

                return data.link;
            } catch (error) {
                this.relatedOrdersLoading = false;

                throw error;
            } finally {
                this.relatedOrdersLoading = false;
            }
        },

        async updateCustomer(customerId, customerData) {
            try {
                this.loading = true;

                const customerUpdateResponse = await customerApi.update(customerId, {
                    ...customerData,
                });

                this.data.customer = customerUpdateResponse.data.customer;

                this.loading = false;

                return customerUpdateResponse.data.customer;
            } catch (error) {
                this.loading = false;

                throw error;
            }
        },

        mapResponseValues(values) {
            this.data.ref = values.ref;
            this.data.type = values.type;
            this.data.state = values.state;
            this.data.status = values.status;
            this.data.heat = values.heat;
            this.data.price_total = values.price_total;

            this.data.safe_to_email = values.safe_to_email;
            this.data.safe_to_sms = values.safe_to_sms;
            this.data.safe_to_call = values.safe_to_call;

            // Sync customer store
            const customer = useCustomerStore();

            customer.data = values.customer;

            this.data.agent = values.agent;
            this.data.created_at = values.created_at;
            this.data.updated_at = values.updated_at;
            this.data.meta = values.meta;
        },

        syncStores() {
            const moveStore = useMoveStore();
            const carTransportStore = useCarTransportStore();
            const petTransportStore = usePetTransportStore();
            const orderAddonStore = useOrderAddonStore();
            const insuranceStore = useInsuranceStore();

            this.data?.items?.forEach((item) => {
                if (item.productable_type == 'move') {
                    moveStore.data = item.product;
                } else if (item.productable_type == 'car_transport') {
                    carTransportStore.data = item.product;
                } else if (item.productable_type == 'pet_transport') {
                    petTransportStore.data = item.product;
                } else if (item.productable_type == 'order_addon') {
                    orderAddonStore.setAddon(item.product);
                } else if (item.productable_type == 'insurance') {
                    insuranceStore.data = item.product;
                }
            });
        },
    },
});
