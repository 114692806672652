export default [
    {
        path: '/affiliates',
        name: 'Affiliates',
        meta: {
            requiresAuth: true,
            title: 'Affiliate partners',
        },
        components: {
            default: () => import('@views/affiliates/AffiliatesIndex.vue'),
            agent: () => import('@views/affiliates/AffiliatesIndex.vue'),
        },
    },
    {
        path: '/affiliates/create',
        name: 'AffiliatesCreate',
        props: {
            default: () => ({ isCreate: true }),
            agent: () => ({ isCreate: true }),
        },
        meta: {
            requiresAuth: true,
            title: 'Create Affiliate',
        },
        components: {
            default: () => import('@views/affiliates/AffiliatesForm.vue'),
            agent: () => import('@views/affiliates/AffiliatesForm.vue'),
        },
    },
    {
        path: '/affiliates/:id/edit',
        name: 'AffiliatesEdit',
        props: {
            default: (route) => ({ id: route.params.id, isCreate: false }),
            agent: (route) => ({ id: route.params.id, isCreate: false }),
        },
        meta: {
            requiresAuth: true,
            title: 'Edit Affiliate',
        },
        components: {
            default: () => import('@views/affiliates/AffiliatesForm.vue'),
            agent: () => import('@views/affiliates/AffiliatesForm.vue'),
        },
    },
    {
        path: '/affiliates/leads',
        name: 'AffiliatesLeads',
        meta: {
            requiresAuth: true,
            title: 'Affiliate leads',
        },
        components: {
            default: () => import('@views/affiliates/AffiliatesLeads.vue'),
            agent: () => import('@views/affiliates/AffiliatesLeads.vue'),
        },
    },
    {
        path: '/affiliates/invite',
        name: 'AffiliatesInvite',
        meta: {
            requiresAuth: true,
            title: 'Affiliate invite',
        },
        components: {
            affiliates: () => import('@views/affiliates/AffiliatesInvite.vue'),
        },
    },
    {
        path: '/affiliates/add-lead',
        name: 'AffiliatesAddLead',
        meta: {
            requiresAuth: true,
            title: 'Add customer',
        },
        components: {
            affiliates: () => import('@views/affiliates/AffiliatesAddLead.vue'),
        },
    },
];
