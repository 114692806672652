/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module User
 */

const ROUTE = '/users';

function index() {
    return window.axios.get(`${ROUTE}/search`);
}

function create(userData) {
    return window.axios.post(ROUTE, userData);
}

function update(userId, userData) {
    return window.axios.patch(`${ROUTE}/${userId}`, {
        user_id: userId,
        ...userData,
    });
}

function retrieve(userId) {
    return window.axios.get(`${ROUTE}/${userId}`);
}

function destroy(userId) {
    return window.axios.delete(`${ROUTE}/${userId}`);
}

// Helper API Services

function notifications(userId) {
    return window.axios.get(`${ROUTE}/${userId}/notifications`);
}

function requestResetPassword(email) {
    return window.axios.post('auth/forgot-password', { email });
}

function confirmResetPassword(token, email, password, password_confirmation) {
    return window.axios.post('auth/reset-password', {
        token: token,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
    });
}

export default {
    index,
    create,
    retrieve,
    update,
    destroy,
    notifications,
    requestResetPassword,
    confirmResetPassword,
};
