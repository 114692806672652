import { defineStore } from 'pinia';
import mxDepotApi from '@api/mxdepot';

export const useMxDepotStore = defineStore('mxdepot', {
    state: () => {
        return {
            loading: false,
            data: {},
            createValues: {
                radius: 30, // has to be a multiple of 10
                type: 'LOCAL',
                active: true,
            },
        };
    },
    getters: {},
    actions: {
        async create(data) {
            try {
                this.loading = true;
                await mxDepotApi.create(data);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async retrieve(mxDepotId) {
            try {
                this.loading = true;
                const mxDepot = await mxDepotApi.retrieve(mxDepotId);
                this.mapResponseValues(mxDepot.data);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async update(mxDepotId, payload) {
            try {
                this.loading = true;
                const data = await mxDepotApi.update(mxDepotId, payload);
                this.mapResponseValues(data.data.muvexpress_depot);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        mapResponseValues(mxDepot) {
            this.data.id = mxDepot.id;
            this.data.label = mxDepot.label;
            this.data.location = mxDepot.label;
            this.data.type = mxDepot.type;
            this.data.radius = mxDepot.radius;
            this.data.start_point = mxDepot.start_point;
            this.data.rates = { small: {}, large: {} };

            let smallIndex = mxDepot.rates.findIndex((item) => item.truck_size == 'SMALL');
            let largeIndex = mxDepot.rates.findIndex((item) => item.truck_size == 'LARGE');

            // Small
            if (smallIndex != -1) {
                this.data.rates.small.two_mon_fri = mxDepot.rates[smallIndex].two_mon_fri?.amount;
                this.data.rates.small.three_mon_fri = mxDepot.rates[smallIndex].three_mon_fri?.amount;
                this.data.rates.small.four_mon_fri = mxDepot.rates[smallIndex].four_mon_fri?.amount;

                this.data.rates.small.two_sat = mxDepot.rates[smallIndex].two_sat?.amount;
                this.data.rates.small.two_sun = mxDepot.rates[smallIndex].two_sun?.amount;
                this.data.rates.small.three_sat = mxDepot.rates[smallIndex].three_sat?.amount;
                this.data.rates.small.three_sun = mxDepot.rates[smallIndex].three_sun?.amount;
                this.data.rates.small.four_sat = mxDepot.rates[smallIndex].four_sat?.amount;
                this.data.rates.small.four_sun = mxDepot.rates[smallIndex].four_sun?.amount;
            } else {
                this.data.rates.small = null;
            }

            // Large
            if (largeIndex != -1) {
                this.data.rates.large.two_mon_fri = mxDepot.rates[largeIndex].two_mon_fri?.amount;
                this.data.rates.large.three_mon_fri = mxDepot.rates[largeIndex].three_mon_fri?.amount;
                this.data.rates.large.four_mon_fri = mxDepot.rates[largeIndex].four_mon_fri?.amount;

                this.data.rates.large.two_sat = mxDepot.rates[largeIndex].two_sat?.amount;
                this.data.rates.large.two_sun = mxDepot.rates[largeIndex].two_sun?.amount;
                this.data.rates.large.three_sat = mxDepot.rates[largeIndex].three_sat?.amount;
                this.data.rates.large.three_sun = mxDepot.rates[largeIndex].three_sun?.amount;
                this.data.rates.large.four_sat = mxDepot.rates[largeIndex].four_sat?.amount;
                this.data.rates.large.four_sun = mxDepot.rates[largeIndex].four_sun?.amount;
            } else {
                this.data.rates.large = null;
            }
        },
    },
});
