export default [
    {
        path: '/quotes',
        name: 'Quotes',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/quotes/QuoteIndex.vue'),
            agent: () => import('@views/quotes/QuoteIndex.vue'),
        },
    },
    {
        path: '/quotes/:id/view',
        name: 'QuoteView',
        meta: {
            requiresAuth: true,
        },
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        components: {
            default: () => import('@views/quotes/QuoteView.vue'),
            agent: () => import('@views/quotes/QuoteView.vue'),
        },
    },
];
