// store/listings.js
import { defineStore } from 'pinia';
import sanctum from '@api/auth/sanctum';
import userAPI from '@api/user';
import navigation from '@router/navigation';
import { notify } from '@kyvg/vue3-notification';
import * as Sentry from '@sentry/vue';

import { useNotificationsStore } from './notifications';

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            loading: false,
            data: null,
            authenticated: false,
            search: null,
            navigation: [],
            bearer: useStorage('bearer', null),
            activity_status: useStorage('activity_status', null),
        };
    },
    actions: {
        async login(username, password) {
            try {
                this.loading = true;
                const { token } = await sanctum.token({
                    email: username,
                    password,
                });
                this.bearer = token;
                const user = await this.fetchUser();
                this.loading = false;
                return user;
            } catch (error) {
                this.authenticated = false;
                this.data = null;
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async fetchUser(fetch_notifications = true) {
            try {
                this.loading = true;
                const { data } = await sanctum.me();
                this.data = data;
                this.activity_status = data.activity_status;
                this.authenticated = true;
                this.navigation = navigation.populateNavigation([...this.data.roles]);
                if (fetch_notifications) {
                    const notifications = useNotificationsStore();
                    await notifications.retrieve();
                }
                if (import.meta.env.VITE_ENV == 'production') {
                    Sentry.setUser({
                        name: this.data.fullname,
                        email: this.data.email,
                        id: this.data.id,
                    });
                }
                this.loading = false;
                return data;
            } catch (error) {
                this.authenticated = false;
                this.data = null;
                this.loading = false;
                if (this.router && this.router.currentRoute.value.name !== 'Register') {
                    this.router.push('login');
                }
            }
        },

        async logout() {
            try {
                this.loading = true;
                await sanctum.logout();
                if (import.meta.env.VITE_ENV == 'production') {
                    Sentry.setUser(null);
                }
                this.data = null;
                this.authenticated = false;
                this.jwt = null;
                this.loading = false;
            } catch (error) {
                this.data = null;
                this.authenticated = false;
                this.loading = false;
                throw error;
            } finally {
                this.loading = false;
            }
        },

        async update(userData) {
            try {
                const { data } = await userAPI.update(this.data.id, userData);
                this.data = data.user;
                notify({
                    title: 'User successfully',
                    group: 'app_success',
                    text: 'updated',
                });
                return data.user;
            } catch (error) {
                notify({
                    title: 'User Update failed',
                    group: 'app_error',
                    text: error.message,
                });
                throw error;
            }
        },

        async requestResetPassword(email) {
            const { data } = await userAPI.requestResetPassword(email);
            if (data) {
                return true;
            }
        },

        async confirmResetPassword(token, email, password, password_confirmation) {
            const { data } = await userAPI.confirmResetPassword(token, email, password, password_confirmation);
            if (data) {
                return true;
            }
        },
    },
    getters: {
        isAuthenticated: (state) => {
            return state.authenticated;
        },

        isRemovalist(state) {
            if (state.data && state.data.roles && state.data.roles.includes('COMPANY_MANAGER')) {
                return true;
            } else {
                return false;
            }
        },

        isRelocations(state) {
            return state.data?.roles?.includes('RELOCATIONS_MANAGER');
        },

        isAffiliate(state) {
            return state.data?.roles?.includes('AFFILIATE_MANAGER');
        },

        isAdmin(state) {
            if (state.data && state.data.roles && state.data.roles.includes('MUVAL_ADMIN')) {
                return true;
            } else {
                return false;
            }
        },

        isAgent(state) {
            if (state.data && state.data.roles && state.data.roles.includes('MUVAL_AGENT')) {
                return true;
            } else {
                return false;
            }
        },

        isTriage(state) {
            if (state.data && state.data.roles && state.data.roles.includes('MUVAL_TRIAGE')) {
                return true;
            } else {
                return false;
            }
        },

        isMuvalCompany(state) {
            if (state.data && state.data.roles && state.data.roles.includes('MUVAL_COMPANY')) {
                return true;
            } else {
                return false;
            }
        },

        isAdminOrSuper(state) {
            if (
                state.data &&
                state.data.roles &&
                (state.data.roles.includes('MUVAL_ADMIN') || state.data.roles.includes('SUPER'))
            ) {
                return true;
            }
            return false;
        },

        isSuper(state) {
            if (state.data && state.data.roles && state.data.roles.includes('SUPER')) {
                return true;
            } else {
                return false;
            }
        },

        isDev(state) {
            if (state.data && state.data.roles && state.data.roles.includes('DEVELOPER')) {
                return true;
            } else {
                return false;
            }
        },

        isMuval() {
            return this.hasAnyRole([
                'SUPER',
                'MUVAL_ADMIN',
                'MUVAL_AGENT',
                'MUVAL_TRIAGE',
                'MUVAL_COMPANY',
                'DEVELOPER',
            ]);
        },

        companyId(state) {
            return state.data?.company_id;
        },

        isOnboarded(state) {
            if (state.data && state.data.roles) {
                if (state.data.roles.includes('MUVAL_MANAGER' || 'MUVAL_ADMIN')) {
                    return true;
                }
            }
            return false;
        },

        getUser(state) {
            return state.data;
        },

        getUserId(state) {
            return state.data?.id;
        },

        getTimezone(state) {
            return state.data?.timezone;
        },

        getAvatar(state) {
            return state.data?.avatar;
        },

        getFullName(state) {
            if (state.data && state.data.fullname) {
                return state.data.fullname;
            }
        },

        getFirstName(state) {
            if (state.data && state.data.first_name) {
                return state.data.first_name;
            }
        },

        getLastName(state) {
            if (state.data && state.data.last_name) {
                return state.data.last_name;
            }
        },

        getUsername(state) {
            if (state.data) {
                return state.data.username;
            }
        },

        getRoles(state) {
            if (state.data) {
                return state.data.roles;
            }
        },

        getCompanyId(state) {
            if (state.data) {
                return state.data.company_id;
            }
        },

        getTeam(state) {
            return state.data?.team;
        },

        // gates
        hasRole: (state) => {
            return (role) => {
                if (state.data && state.data.roles) {
                    return state.data.roles.includes(role);
                }
            };
        },

        unlessRole: (state) => {
            return (role) => {
                if (state.data && state.data.roles) {
                    return !state.data.roles.includes(role);
                }
            };
        },

        hasAnyRole: (state) => {
            return (roles) => {
                if (state.data && state.data.roles) {
                    return roles.some((role) => state.data.roles.includes(role));
                }
            };
        },

        hasEveryRole: (state) => {
            return (roles) => {
                if (state.data && state.data.roles) {
                    return roles.every((role) => state.data.roles.includes(role));
                }
            };
        },
    },
});
