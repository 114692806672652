import Echo from 'laravel-echo';
/* eslint-disable */
import Pusher from 'pusher-js';
/* eslint-enable */

export default (app) => {
    const echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,

        // Don't add a host because we're using a cluster and this needs to be apart of the host
        ...((
            import.meta.env.VITE_ENV === 'local' ||
            import.meta.env.VITE_ENV === 'development' ||
            import.meta.env.VITE_ENV === 'testing'
        ) ?
            { wsHost: import.meta.env.VITE_PUSHER_HOST }
        :   {}),
        wsPort: import.meta.env.VITE_PUSHER_PORT,
        wssPort: import.meta.env.VITE_PUSHER_PORT,
        cluster: import.meta.env.VITE_PUSHER_CLUSTER,
        forceTLS: import.meta.env.VITE_PUSHER_SCHEME === 'https',
        encrypted: import.meta.env.VITE_PUSHER_SCHEME === 'https',
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authorizer: (channel) => {
            return {
                authorize: (socketId, callback) => {
                    let token = localStorage.getItem('bearer');
                    let config = {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    };

                    window.axios
                        .post(
                            '/broadcasting/auth',
                            {
                                socket_id: socketId,
                                channel_name: channel.name,
                            },
                            {
                                config,
                            },
                        )
                        .then((response) => {
                            callback(false, response.data);
                        })
                        .catch((error) => {
                            callback(true, error);
                        });
                },
            };
        },
    });

    app.config.globalProperties.$echo = echo;
    window.echo = echo;
};
