// Allows usage of global event emitter in a vue 3 composition like way via useEmitter()
/*
    Example in a vue setup method
    import useEmitter from '@plugins/useEmitter'

    export default {
    setup() {
        const emitter = useEmitter()
        ...
    }
    ...
    }
*/

import { getCurrentInstance } from 'vue';
export default function useEmitter() {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;

    return emitter;
}
