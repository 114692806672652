import { defineStore } from 'pinia';

// Api Helper
import customerApi from '@api/customer';

export const useCustomerStore = defineStore('customer', {
    state: () => {
        return {
            loading: false,
            orderId: null,
            data: null,
        };
    },
    actions: {
        async create(customerData) {
            try {
                this.loading = true;
                const customerCreateResponse = await customerApi.create({
                    ...customerData,
                });
                this.data = customerCreateResponse.data.customer;
                this.loading = false;
                return customerCreateResponse.data.customer;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async update(customerId, customerData) {
            try {
                this.loading = true;
                const customerUpdateResponse = await customerApi.update(customerId, {
                    ...customerData,
                });
                this.data = customerUpdateResponse.data.customer;
                this.loading = false;
                return customerUpdateResponse.data.customer;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async retrieve(customerId) {
            try {
                this.loading = true;
                const { data: data } = await customerApi.retrieve(customerId);
                this.data = data.customer;
                this.loading = false;
                return data.customer;
            } catch (error) {
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async retrieveByEmail(email) {
            try {
                this.loading = true;
                const { data: data } = await customerApi.retrieveByEmail(email);
                this.loading = false;
                return data.customer;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },
    },
    getters: {
        hasData: (state) => {
            return state.data !== null;
        },

        getCustomer: (state) => {
            return state.data;
        },

        getCustomerId: (state) => {
            return state.data?.id;
        },

        getFirstName: (state) => {
            return state.data?.first_name ?? null;
        },

        getLastName: (state) => {
            return state.data?.last_name ?? null;
        },

        getEmail: (state) => {
            return state.data?.email ?? null;
        },
    },
});
