export default [
    {
        path: '/local',
        name: 'Local',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/local/LocalIndexRemovalist.vue'),
            agent: () => import('@views/local/LocalIndex.vue'),
        },
    },
    {
        path: '/local/create',
        name: 'LocalCreate',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/local/LocalCreate.vue'),
            agent: () => import('@views/local/LocalCreate.vue'),
        },
    },
    {
        path: '/local/:id/edit',
        name: 'LocalEdit',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/local/LocalEdit.vue'),
            agent: () => import('@views/local/LocalEdit.vue'),
        },
    },
];
