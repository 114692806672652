<template>
    <div class="flex w-full py-4">
        <component
            :is="htmlTag"
            class="group flex w-full cursor-pointer gap-4 rounded-muval-1 transition-all hover:-my-2 hover:bg-muval-gray-5 hover:py-2"
            :href="notification?.data?.url ?? ''"
            @click.stop.prevent="$emit('notification-clicked', notification)"
            @click.middle.exact.stop.prevent="$emit('mark-read', notification)"
        >
            <div
                class="ml-2 mt-2 aspect-square h-3 w-3 shrink-0 rounded-full"
                :class="$props.notification.read_at ? 'invisible' : 'bg-brand'"
                @click.stop.prevent="$emit('mark-read', notification)"
            ></div>
            <div class="flex grow flex-col gap-1">
                <div v-text="$props.notification.data.message" />
                <div class="text-xxs text-muval-gray-3">
                    {{ $dayjs($props.notification.created_at).format('ddd D MMM YYYY h:mm A') }}
                </div>
            </div>
            <div
                class="flex h-full items-center"
                :class="!$props.notification.data.url && 'invisible'"
            >
                <div
                    class="flex h-8 w-8 -translate-x-1 cursor-pointer items-center justify-center rounded-full transition-all hover:bg-muval-gray-5 group-hover:translate-x-0 group-hover:border-0"
                >
                    <SvgIcon
                        name="system-forward"
                        class="h-6 w-6 text-brand"
                    />
                </div>
            </div>
        </component>
    </div>
</template>

<script setup>
const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
});

const htmlTag = computed(() => {
    return props.notification?.data?.url ? 'a' : 'div';
});

defineEmits(['notification-clicked', 'mark-read']);
</script>
