<template>
    <TransitionRoot
        as="template"
        :show="appStore.side"
    >
        <Dialog
            :unmount="false"
            as="div"
            class="fixed inset-0 z-50 overflow-hidden"
            @close="appStore.side = false"
        >
            <div class="absolute inset-0 overflow-hidden">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="absolute inset-0 bg-black/50" />
                </TransitionChild>

                <div class="fixed inset-y-0 left-0 flex h-screen">
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-200 transform-gpu delay-75"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-200 transform-gpu "
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full"
                    >
                        <div class="h-screen w-full bg-white">
                            <NavigationDrawer />
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
    <NavigationDrawer class="hidden lg:grid" />
</template>
<script setup>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';

const appStore = useAppStore();
</script>

<style scoped></style>
