<template>
    <div class="z-0 inline-block align-middle">
        <div class="flex h-full items-center pl-1 text-xs">
            <Lozenge :class="[textStyle, bgStyle]">{{ data.name }}</Lozenge>
        </div>
    </div>
</template>

<script setup>
import Lozenge from '@components/Lozenge.vue';
import { useStatusColours } from '@util';
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const { textStyle, bgStyle } = useStatusColours(props.data.name);
</script>
