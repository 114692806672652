export default [
    {
        path: '/coupons',
        name: 'Coupons',
        meta: {
            requiresAuth: true,
            title: 'Coupons',
        },
        components: {
            default: () => import('@views/coupons/CouponsIndex.vue'),
            agent: () => import('@views/coupons/CouponsIndex.vue'),
        },
    },
    {
        path: '/coupons/create',
        name: 'CouponsCreate',
        meta: {
            requiresAuth: true,
            title: 'Create a new coupon',
        },
        components: {
            default: () => import('@views/coupons/CouponCreate.vue'),
            agent: () => import('@views/coupons/CouponCreate.vue'),
        },
    },
    {
        path: '/coupons/:id/edit',
        name: 'CouponsEdit',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
            title: 'Coupon edit',
        },
        components: {
            default: () => import('@views/coupons/CouponEdit.vue'),
            agent: () => import('@views/coupons/CouponEdit.vue'),
        },
    },
];
