/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module companyContacts
 */

async function index(companyId) {
    const {
        data: { data },
    } = await window.axios.get(`companies/${companyId}/contacts`);
    return data;
}

async function create(companyId, contact) {
    return await window.axios.post(`/companies/${companyId}/contacts`, contact);
}

async function retrieve(companyId, contactId) {
    const { data } = await window.axios.get(`/companies/${companyId}/contacts/${contactId}`);
    return data.lane;
}

async function update(companyId, contactId, contact) {
    return await window.axios.patch(`/companies/${companyId}/contacts/${contactId}`, {
        contact_id: contactId,
        ...contact,
    });
}

async function destroy(companyId, contactId) {
    const response = await window.axios.delete(`/companies/${companyId}/contacts/${contactId}`);
    return response;
}

// Restores a given contact via contactId
async function restore(companyId, contactId) {
    const { data } = await window.axios.put(`/companies/${companyId}/contacts/restore/${contactId}`);
    return data;
}

export default {
    index,
    create,
    retrieve,
    update,
    destroy,

    restore,
};
