export default [
    {
        path: '/relocations',
        name: 'relocations',
        meta: {
            requiresAuth: true,
            title: 'Relocations',
        },
        components: {
            agent: () => import('@views/relocations/RelocationsIndex.vue'),
            relocations: () => import('@views/relocations/RelocationsIndex.vue'),
        },
    },
    {
        path: '/relocations/create',
        name: 'CreateRelocation',
        meta: {
            requiresAuth: true,
            title: 'Invite your employee',
        },
        props: {
            agent: () => ({ id: null }),
            relocations: () => ({ id: null }),
        },
        components: {
            agent: () => import('@views/relocations/RelocationsForm.vue'),
            relocations: () => import('@views/relocations/RelocationsForm.vue'),
        },
    },
    {
        path: '/relocations/:id/edit',
        name: 'EditRelocation',
        meta: {
            requiresAuth: true,
            title: 'Update relocation',
        },
        props: {
            agent: (route) => ({ id: route.params.id }),
            relocations: (route) => ({ id: route.params.id }),
        },
        components: {
            agent: () => import('@views/relocations/RelocationsForm.vue'),
            relocations: () => import('@views/relocations/RelocationsForm.vue'),
        },
    },
    {
        path: '/relocations/:id/view',
        name: 'ViewRelocation',
        meta: {
            requiresAuth: true,
            title: 'Relocation',
        },
        props: {
            agent: (route) => ({ id: route.params.id }),
            relocations: (route) => ({ id: route.params.id }),
        },
        components: {
            agent: () => import('@views/relocations/RelocationsView.vue'),
            relocations: () => import('@views/relocations/RelocationsView.vue'),
        },
    },
    {
        path: '/policies',
        name: 'policies',
        meta: {
            requiresAuth: true,
            title: 'Policies',
        },
        components: {
            agent: () => import('@views/relocations/PoliciesIndex.vue'),
            relocations: () => import('@views/relocations/PoliciesIndex.vue'),
        },
    },
    {
        path: '/policies/create',
        name: 'CreatePolicy',
        meta: {
            requiresAuth: true,
            title: 'Create policy',
        },
        props: {
            agent: () => ({ id: null }),
            relocations: () => ({ id: null }),
        },
        components: {
            agent: () => import('@views/relocations/PoliciesForm.vue'),
            relocations: () => import('@views/relocations/PoliciesForm.vue'),
        },
    },
    {
        path: '/policies/:id/edit',
        name: 'EditPolicy',
        meta: {
            requiresAuth: true,
            title: 'Edit policy',
        },
        props: {
            agent: (route) => ({ id: route.params.id }),
            relocations: (route) => ({ id: route.params.id }),
        },
        components: {
            agent: () => import('@views/relocations/PoliciesForm.vue'),
            relocations: () => import('@views/relocations/PoliciesForm.vue'),
        },
    },
];
