export default [
    {
        path: '/settings',
        name: 'Settings',
        meta: {
            requiresAuth: true,
            title: 'Settings',
        },
        components: {
            default: () => import('@views/settings/SettingsContainer.vue'),
            agent: () => import('@views/settings/SettingsContainer.vue'),
            relocations: () => import('@views/settings/SettingsContainer.vue'),
            affiliates: () => import('@views/settings/SettingsContainer.vue'),
        },
    },
    {
        path: '/settings/muvexpress/depot/create',
        name: 'CreateMuvexpressDepot',
        meta: {
            requiresAuth: true,
            title: 'MuvexpressDepotCreate',
        },
        components: {
            default: () => import('@views/settings/muvexpress/MuvexpressDepotCreate.vue'),
            agent: () => import('@views/settings/muvexpress/MuvexpressDepotCreate.vue'),
        },
    },
    {
        path: '/settings/muvexpress/depot/:id/edit',
        name: 'EditMuvexpressDepot',
        meta: {
            requiresAuth: true,
            title: 'MuvexpressDepotEdit',
        },
        components: {
            default: () => import('@views/settings/muvexpress/MuvexpressDepotEdit.vue'),
            agent: () => import('@views/settings/muvexpress/MuvexpressDepotEdit.vue'),
        },
    },
];
