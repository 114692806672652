export default [
    {
        path: '/tools/historical-quotes',
        name: 'historical_quotes',
        meta: {
            requiresAuth: true,
            title: 'Historical quotes',
        },
        components: {
            agent: () => import('@views/tools/HistoricalQuoteIndex.vue'),
        },
    },
];
