<template>
    <div class="max-w-screen flex h-screen w-full">
        <aside
            v-if="showLayout"
            class="border-r"
        >
            <NavigationController />
        </aside>
        <div class="flex w-0 flex-1 flex-col gap-y-4">
            <NewTopHeader
                v-if="showLayout"
                class="mx-auto -mb-1 max-w-[1920px] px-4 lg:px-8"
            />
            <main class="h-[calc(100vh-88px)] flex-1 overflow-auto">
                <div class="mx-auto max-w-[1920px] first:mt-1 last:mb-8 lg:px-8">
                    <slot />
                </div>
            </main>
        </div>
        <NotificationSlideOver
            v-if="userStore.isAuthenticated"
            class="z-40"
        />
    </div>
</template>
<script setup>
const userStore = useUserStore();
const route = useRoute();

const showLayout = computed(() => {
    return (
        userStore.isAuthenticated &&
        route.name !== 'Login' &&
        route.name !== 'Register' &&
        route.name !== 'Update your preferences'
    );
});
</script>
