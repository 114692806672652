export default [
    {
        path: '/orders',
        name: 'Leads',
        meta: {
            requiresAuth: true,
            title: 'Your leads',
        },
        components: {
            default: () => import('@views/leads/LeadsIndex.vue'),
            agent: () => import('@views/leads/LeadsIndex.vue'),
        },
    },
    {
        path: '/orders/create',
        name: 'LeadCreate',
        meta: {
            requiresAuth: true,
            title: 'Create lead',
        },
        components: {
            default: () => import('@views/leads/LeadCreate.vue'),
            agent: () => import('@views/leads/LeadCreate.vue'),
        },
    },
];
