export default [
    {
        path: '/depots',
        name: 'Depots',
        meta: {
            requiresAuth: true,
            title: 'Depots',
        },
        components: {
            default: () => import('@views/depots/DepotsIndexRemovalist.vue'),
            agent: () => import('@views/depots/DepotsIndex.vue'),
        },
    },
    {
        path: '/depots/create',
        name: 'DepotsCreate',
        meta: {
            requiresAuth: true,
            title: 'Depots',
        },
        components: {
            default: () => import('@views/depots/DepotsCreate.vue'),
            agent: () => import('@views/depots/DepotsCreate.vue'),
        },
    },
    {
        path: '/depots/:id/edit',
        name: 'DepotsEdit',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
            title: 'Depots',
        },
        components: {
            default: () => import('@views/depots/DepotsEdit.vue'),
            agent: () => import('@views/depots/DepotsEdit.vue'),
        },
    },
];
