/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module Coupon
 */

export const couponApi = {
    index,
    create,
    retrieve,
    update,
    destroy,
};

const ROUTE = '/coupons';

function index() {
    const response = window.axios.get(ROUTE);
    return response.data.data;
}

async function create(coupon) {
    const response = await window.axios.post(ROUTE, coupon);
    return response.data.data;
}

async function retrieve(couponId) {
    const response = await window.axios.get(`${ROUTE}/${couponId}`);
    return response.data.data;
}

async function update(couponId, coupon) {
    const response = await window.axios.patch(`${ROUTE}/${couponId}`, coupon);
    return response.data.data;
}

async function destroy(couponId) {
    const response = await window.axios.delete(`${ROUTE}/${couponId}`);
    return response.data.data;
}
