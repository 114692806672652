<template>
    <form
        ref="interstatePriceForm"
        class="space-y-3"
        @submit="onSubmit"
    >
        <div class="border-b-2 pb-2 font-medium">Edit pricing option</div>

        <div class="mt-5 flex gap-5">
            <MuvalInput
                vid="item"
                label="Item"
            />
            <div class="flex w-full justify-between">
                <MuvalSelect
                    value-prop="unit"
                    display-label="unit"
                    :single-select="true"
                    :select-options="unitOptions"
                    vid="unit"
                    label="Unit"
                />
                <span class="my-auto px-3">or</span>
                <MuvalInput
                    label="New unit"
                    vid="unit"
                />
            </div>
        </div>

        <div class="flex">
            <span class="mr-12">Global</span>
            <MuvalSwitch vid="is_global" />
        </div>

        <div class="flex">
            <span class="mr-6">Published</span>
            <MuvalSwitch vid="is_published" />
        </div>

        <div class="flex w-full">
            <div class="ml-auto gap-5">
                <MuvalButton
                    link
                    @click.prevent.stop="closeModal"
                >
                    Discard
                </MuvalButton>
                <MuvalButton
                    class="ml-auto"
                    :loading="isSubmitting"
                >
                    Save changes
                </MuvalButton>
            </div>
        </div>
    </form>
</template>

<script setup>
import MuvalInput from '@components/inputs/MuvalInput.vue';
import MuvalSwitch from '@components/inputs/MuvalSwitch.vue';
import MuvalSelect from '../../inputs/MuvalSelect.vue';
import { useForm } from 'vee-validate';
import MuvalButton from '@buttons/MuvalButton.vue';

const emit = defineEmits(['close:modal']);
const props = defineProps({
    isCreate: {
        type: Boolean,
        default: false,
    },
    data: {
        type: Object,
        required: true,
    },
});

const interstatePriceForm = ref(null);

const { handleSubmit, isSubmitting, resetForm, setErrors } = useForm();

const onSubmit = handleSubmit(async (values) => {
    try {
        if (props.isCreate) {
            await window.axios.post('/interstate-price-items', {
                ...values,
            });
        }
        await window.axios.post(`/interstate-price-items/${props.data.id}`, values);
        success();
    } catch (error) {
        setErrors(error.errors);
    }
});

function success() {
    emit('close:modal');
}

function closeModal() {
    emit('close:modal');
}

// Get and set Units
onMounted(async () => {
    const response = await window.axios.get('/interstate-price-items/units');
    unitOptions.value = response.data.data;
});

// Get current data and set initial values
onMounted(async () => {
    const response = await window.axios.get(`interstate-price-items/${props.data.id}`);

    // Get unit from unit object and set unit
    let unit = response.data.price_item.unit.unit;
    response.data.price_item.unit = unit;

    resetForm({ values: response.data.price_item });
});

const unitOptions = ref([]);
</script>
