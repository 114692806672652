import { defineStore } from 'pinia';

import { UAParser } from 'ua-parser-js';
import { Loader } from '@googlemaps/js-api-loader';
import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useAppStore = defineStore('app', () => {
    const currentRelease = ref({});
    const latestRelease = ref({});
    const newRelease = ref(false);

    const showDev = computed(() => {
        if (import.meta.env.VITE_ENV === 'local') {
            return true;
        } else {
            return false;
        }
    });

    const checkRelease = computed(() => {
        if (showDev.value) {
            return false;
        } else {
            return true;
        }
    });

    // initiate release with env data if available, otherwise will use the first release.json ingested
    if (import.meta.env.VITE_RELEASE && import.meta.env.VITE_RELEASE_DATE && import.meta.env.VITE_DEPLOY_DATE) {
        const bakedRelease = {
            release: import.meta.env.VITE_RELEASE,
            release_tag: import.meta.env.VITE_RELEASE_TAG,
            release_branch: import.meta.env.VITE_RELEASE_BRANCH,
            release_commit: import.meta.env.VITE_RELEASE_COMMIT,
            release_commit_url: import.meta.env.VITE_RELEASE_COMMIT_URL,
            release_timestamp: import.meta.env.VITE_RELEASE_TIMESTAMP,
            release_date: import.meta.env.VITE_RELEASE_DATE,
            deploy_timestamp: import.meta.env.VITE_DEPLOY_TIMESTAMP,
            deploy_date: import.meta.env.VITE_DEPLOY_DATE,
            ping_timestamp: Math.round(new Date().getTime() / 1000), // to align with backend output
            ping_date: dayjs().utc().format('YYYY-MM-DDTHH:mm:ssZZ'),
            baked_release: true,
        };

        console.log(`👨🏼‍🍳 Initial release baked into env ${bakedRelease.release}`, bakedRelease);

        currentRelease.value = bakedRelease;
    }

    // check release.json for a new release, initiating if not already initiated, and setting new release when available
    function updateRelease() {
        // disable on local environments
        if (!checkRelease.value) {
            return;
        }

        const refreshTime = new Date().getTime();

        axios
            .get(`/release.json?${refreshTime}`)
            .then((response) => {
                console.log('💬 Checking release');

                const pingData = {
                    ping_timestamp: Math.round(new Date().getTime() / 1000), // to align with backend output
                    ping_date: dayjs().utc().format('YYYY-MM-DDTHH:mm:ssZZ'),
                };

                if (currentRelease.value.release) {
                    if (
                        response.data.release == currentRelease.value.release &&
                        response.data.release_timestamp == currentRelease.value.release_timestamp
                    ) {
                        console.log(
                            `🧘🏼 Existing release is the latest release ${currentRelease.value.release}`,
                            response.data,
                        );

                        // maintain original data, updating the ping data only
                        currentRelease.value = {
                            ...currentRelease.value,
                            ...pingData,
                        };
                    } else {
                        notify({
                            group: 'app_release',
                            title: 'New release available',
                            text: 'Please save any work in progress and then force-refresh for the latest features and bug fixes.',
                            data: {
                                release: response.data.release,
                                release_tag: response.data.release_tag,
                            },
                        });

                        console.log(
                            `🎉 New release ${response.data.release} since existing release ${currentRelease.value.release}`,
                            response.data,
                        );

                        latestRelease.value = {
                            ...response.data,
                            ...pingData,
                            baked_release: false,
                        };

                        newRelease.value = true;

                        return;
                    }
                } else {
                    console.log(`☺️ Initial release ${response.data.release}`, response.data);

                    currentRelease.value = {
                        ...response.data,
                        ...pingData,
                        baked_release: false,
                    };
                }
            })
            .catch((error) => {
                console.error('😭 Error getting release', error);
            });
    }

    // initiate release on load
    updateRelease();

    // check for a new release every 30 seconds
    window.setInterval(() => {
        updateRelease();
    }, 30000);

    const userAgent = new UAParser();
    const side = ref(false);
    const notificationSlider = ref(false);
    const navigation = ref([]);
    const darkMode = ref(false);
    const showKanban = useStorage('showKanban', true);
    const isMobile = ref(false);
    const header = ref(null);
    const google = ref(null);
    const autoCompleteService = ref(null);
    const axiosAbortControllerList = ref({});
    const devUser = reactive({
        username: null,
        password: null,
    });
    const adminUser = reactive({
        username: null,
        password: null,
    });
    const agentUser = reactive({
        username: null,
        password: null,
    });
    const companyFinanceUser = reactive({
        username: null,
        password: null,
    });
    const companyUser = reactive({
        username: null,
        password: null,
    });
    const companyUserList = reactive([]);

    function toggleSide() {
        console.log('toggling side');
        side.value = !side.value;
    }

    function toggleNotificationSlider() {
        notificationSlider.value = !notificationSlider.value;
    }

    function setSide(value) {
        side.value = value;
    }

    async function initGoogle() {
        google.value = await new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
            libraries: ['places'],
            language: 'en',
        }).load();
        autoCompleteService.value = new google.value.maps.places.AutocompleteService();
    }

    function setDeviceType() {
        isMobile.value = new UAParser().getDevice().type === 'mobile' ? true : false;
    }

    function setTestUsers() {
        if (import.meta.env.VITE_ENV === 'local') {
            devUser.username = import.meta.env.VITE_DEV_USER_EMAIL;
            devUser.password = import.meta.env.VITE_DEV_USER_PASSWORD;

            adminUser.username = import.meta.env.VITE_ADMIN_USER_EMAIL;
            adminUser.password = import.meta.env.VITE_ADMIN_USER_PASSWORD;

            agentUser.username = import.meta.env.VITE_AGENT_USER_EMAIL;
            agentUser.password = import.meta.env.VITE_AGENT_USER_PASSWORD;

            companyFinanceUser.username = import.meta.env.VITE_COMPANY_FINANCE_USER_EMAIL;
            companyFinanceUser.password = import.meta.env.VITE_COMPANY_FINANCE_USER_PASSWORD;

            companyUser.username = import.meta.env.VITE_COMPANY_USER_EMAIL;
            companyUser.password = import.meta.env.VITE_COMPANY_USER_PASSWORD;

            const companyUserNameList = (import.meta.env.VITE_COMPANY_USER_NAMES || '').split('|');
            const companyUserEmailList = (import.meta.env.VITE_COMPANY_USER_EMAILS || '').split('|');

            if (companyUserNameList.length > 0 && companyUserNameList.length === companyUserEmailList.length) {
                for (let companyIndex = 0; companyIndex < companyUserNameList.length; companyIndex++) {
                    if (!companyUserNameList[companyIndex]) {
                        continue;
                    }
                    companyUserList.push({
                        name: companyUserNameList[companyIndex],
                        value: companyUserEmailList[companyIndex],
                    });
                }
            }
        }
    }

    return {
        showDev,
        checkRelease,
        currentRelease,
        latestRelease,
        newRelease,
        userAgent,
        side,
        notificationSlider,
        navigation,
        darkMode,
        showKanban,
        isMobile,
        header,
        google,
        autoCompleteService,
        axiosAbortControllerList,
        toggleSide,
        toggleNotificationSlider,
        setSide,
        initGoogle,
        setDeviceType,
        setTestUsers,
        devUser,
        adminUser,
        agentUser,
        companyFinanceUser,
        companyUser,
        companyUserList,
    };
});
