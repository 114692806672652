export default [
    {
        path: '/interstate',
        name: 'Interstate',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/interstate/InterstateIndexRemovalist.vue'),
            agent: () => import('@views/interstate/InterstateIndex.vue'),
        },
    },
    {
        path: '/interstate/trip/:id/edit',
        name: 'InterstateTripEdit',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/interstate/InterstateTripEdit.vue'),
            agent: () => import('@views/interstate/InterstateTripEdit.vue'),
        },
    },
    {
        path: '/interstate/lane/:id/edit',
        name: 'InterstateLaneEdit',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/interstate/InterstateLaneEdit.vue'),
            agent: () => import('@views/interstate/InterstateLaneEdit.vue'),
        },
    },
    {
        path: '/interstate/settings',
        name: 'InterstateSettings',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/interstate/InterstateSettings.vue'),
            agent: () => import('@views/interstate/InterstateSettings.vue'),
        },
    },
    {
        path: '/interstate/lane/create',
        name: 'InterstateLaneCreate',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/interstate/InterstateLaneCreate.vue'),
            agent: () => import('@views/interstate/InterstateLaneCreate.vue'),
        },
    },
    {
        path: '/interstate/trip/create',
        name: 'InterstateTripCreate',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/interstate/InterstateTripCreate.vue'),
            agent: () => import('@views/interstate/InterstateTripCreate.vue'),
        },
    },
    {
        path: '/interstate/trip/create',
        name: 'InterstateTripCreateFromLane',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/interstate/InterstateTripCreateFromLane.vue'),
            agent: () => import('@views/interstate/InterstateTripCreateFromLane.vue'),
        },
    },
];
