<template>
    <div class="relative flex w-full flex-col">
        <span class="mx-auto font-bold">Are you sure?</span>
        <div
            class="mt-3 flex border-r-2 border-red-600 bg-red-100 p-2 text-error dark:border-red-100 dark:bg-red-600 dark:text-red-100"
        >
            <svg
                class="my-auto"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8078 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92893C15.6725 1.53041 13.8907 0.577999 11.9509 0.192146C10.0111 -0.193707 8.00042 0.00432777 6.17316 0.761204C4.3459 1.51808 2.78411 2.79981 1.6853 4.4443C0.586487 6.08879 -3.8147e-06 8.02219 -3.8147e-06 10C-3.8147e-06 11.3132 0.258654 12.6136 0.7612 13.8268C1.26375 15.0401 2.00034 16.1425 2.92893 17.0711C3.85751 17.9997 4.9599 18.7362 6.17316 19.2388C7.38642 19.7413 8.68678 20 10 20ZM9 5H11V11H9V5ZM9 13H11V15H9V13Z"
                    fill="currentColor"
                />
            </svg>
            <div class="my-auto ml-2">Warning</div>
        </div>

        <div class="text-md mt-8">
            Are you sure you want to archive
            <span class="text-brand">{{ data.fullname }}</span>
            ?
        </div>
        <div class="container mt-3 divide-y divide-gray-200 rounded bg-gray-100 dark:bg-gray-900">
            <div class="flex flex-col justify-between">
                <ul
                    role="list"
                    class="divide-y divide-gray-200"
                >
                    <li>
                        <div class="block hover:bg-gray-50 dark:hover:bg-gray-800">
                            <div class="px-4 py-4 sm:px-6">
                                <div class="mt-2">
                                    <div
                                        v-for="item in deleteItemSchema"
                                        :key="item"
                                        class="mt-2"
                                    >
                                        <p class="flex items-center text-sm text-gray-500">
                                            <span class="mr-12 h-5 w-32 flex-shrink-0 font-bold text-gray-400">
                                                {{ item.name }}
                                            </span>
                                            <!-- Thankyou Stack Overflow: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path -->
                                            {{ `${item.value}`.split('.').reduce((p, c) => (p && p[c]) || null, data) }}
                                        </p>
                                    </div>
                                    <div
                                        v-if="data.created_at"
                                        class="mt-2 flex items-center text-sm text-gray-500"
                                    >
                                        <span class="mr-12 h-5 w-32 flex-shrink-0 font-bold text-gray-400">
                                            Created
                                        </span>
                                        <p>
                                            <time>
                                                {{ $dayjs(data.created_at).format('dddd MM MMM YYYY') }} at
                                                {{ $dayjs(data.created_at).format('h[:]m A') }}
                                            </time>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="mt-4 flex justify-between">
            <a
                class="my-auto cursor-pointer text-brand underline"
                @click="closeModal"
            >
                No, keep this user
            </a>
            <div>
                <MuvalButton
                    :loading="loading"
                    class="ml-auto w-48"
                    @click="archive"
                >
                    Archive
                </MuvalButton>
            </div>
        </div>
    </div>
</template>

<script>
import { notify } from '@kyvg/vue3-notification';
import useEmitter from '@plugins/useEmitter';
import MuvalButton from '@buttons/MuvalButton.vue';

export default {
    name: 'DeleteModal',
    components: { MuvalButton },
    props: {
        itemIndex: {
            type: Number,
            required: true,
        },
        itemType: {
            type: [String, null],
            required: false,
            default: null,
        },
        data: {
            type: [Object, null],
            required: false,
            default: null,
        },
        url: {
            type: [String, null],
            required: true,
            default: null,
        },
        deleteItemSchema: {
            type: [Array, null],
            required: false,
            default: null,
        },
        emitKey: {
            type: [String, null],
            required: false,
            default: null,
        },
    },
    emits: ['close:modal', 'remove:item'],
    setup(props, { emit }) {
        console.log(props.emitKey);
        const loading = ref(false);
        const emitter = useEmitter();
        const lastDeleted = ref({
            index: null,
            id: null,
        });
        async function remove() {
            loading.value = true;
            const response = await window.axios.delete(`${props.url}/${props.data.id}`);
            loading.value = false;
            if (response) {
                lastDeleted.value.index = props.itemIndex;
                lastDeleted.value.id = props.data.id;
                console.log(lastDeleted.value);
                notify({
                    title: 'Undo delete',
                    group: 'app_restore',
                    text: 'delete',
                    data: {
                        url: props.url,
                        type: props.itemType,
                        lastDeleted: lastDeleted.value,
                        key: props.emitKey,
                    },
                });
                if (props.emitKey) {
                    emitter.emit(`remove:item-${props.emitKey}`, props.data.id);
                } else {
                    emit('remove:item', props.data.id);
                }
            }
            closeModal();
        }
        async function archive() {
            loading.value = true;
            const response = await window.axios.patch(`/users/muval/${props.data.id}`, { archived: 1 });
            console.log(response);
            loading.value = false;
            console.log(props.emitKey);
            if (props.emitKey) {
                emitter.emit(`remove:item-${props.emitKey}`, props.data.id);
            }
            closeModal();
        }
        function closeModal() {
            emit('close:modal');
        }
        return {
            loading,
            closeModal,
            remove,
            archive,
        };
    },
};
</script>
