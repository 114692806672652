/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module Company
 */

const ROUTE = '/companies';

async function index() {
    const {
        data: { data: data },
    } = await window.axios.get(ROUTE);
    console.log('Companies debug', data);
    return data;
}

function create(company) {
    return window.axios.post(ROUTE, company);
}

function retrieve(id, query) {
    return window.axios.get(`${ROUTE}/${id}`, {
        params: query,
    });
}

function retrieveWithQuery(id, query) {
    return window.axios.get(`${ROUTE}/${id}${query ? query : ''}`);
}

function retrieveSettings(id, query = {}) {
    return window.axios.get(`${ROUTE}/${id}/settings`, { params: query });
}

function update(companyId, company) {
    return window.axios.patch(`${ROUTE}/${companyId}`, {
        company_id: companyId,
        ...company,
    });
}

function updateSettings(companyId, company) {
    return window.axios.patch(`${ROUTE}/${companyId}/settings`, {
        company_id: companyId,
        ...company,
    });
}

function destroy(id) {
    return window.axios.delete(`${ROUTE}/${id}`);
}

// Helper API Services

/**
 * Register a company with associated user record.
 * @route /api/v1/register/company
 * @param {*} company
 * @returns true|false
 */
function register(company) {
    return window.axios.post('/register/company', company);
}

/**
 * Retrieve list of company users
 * @param {*} companyId
 * @returns
 */
function users(companyId) {
    return window.axios.get(`${ROUTE}/${companyId}/users`);
}

export default {
    index,
    create,
    retrieve,
    update,
    destroy,
    retrieveWithQuery,
    register,
    users,
    retrieveSettings,
    updateSettings,
};
