<template>
    <button
        class="relative items-center gap-x-1 lg:flex"
        @click="app.toggleNotificationSlider"
    >
        <SvgIcon
            name="notifications"
            class="size-5 text-muval-gray-1"
        />
        <div
            v-show="notificationCount > 0"
            class="absolute -right-2.5 -top-2.5"
        >
            <span
                class="z-1 flex size-[18px] items-center justify-center rounded-full bg-brand text-xs text-white"
                v-text="notificationCount"
            />
            <span
                class="absolute top-0 -z-1 flex size-[18px] animate-ping items-center justify-center rounded-full bg-brand text-xs text-white"
                v-text="' '"
            />
        </div>
    </button>
</template>

<script setup>
import { useAppStore } from '@store/app';
import { useNotificationsStore } from '@store/notifications';

const app = useAppStore();
const notifications = useNotificationsStore();

const notificationCount = computed(() => {
    return notifications.getUnreadCount;
});

onMounted(() => {
    notifications.startListener();
});
</script>
