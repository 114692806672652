<template>
    <div class="mr-auto flex">
        <ServiceIcon
            class="grayscale"
            :service="data.name"
        />
        <div class="my-auto ml-2 rounded-full bg-gray-200 bg-opacity-75 px-3 py-0 text-gray-900">
            {{ data.name }}
        </div>
    </div>
</template>

<script>
import ServiceIcon from '@components/order/ServiceIcon.vue';
export default {
    name: 'MSService',
    components: {
        ServiceIcon,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return {};
    },
};
</script>
