<template>
    <div class="flex flex-col">
        <div class="border-b-2 pb-2 font-medium">Send an SMS</div>

        <MuvalSelect
            label="Select a template"
            class="mt-8"
        />

        <div class="my-8 h-0 w-full border"></div>

        <MuvalTextArea
            label="Custom message"
            class="mb-5"
        />

        <MuvalSelect
            label="Update status"
            class="mt-8"
        />
    </div>
</template>

<script>
import MuvalSelect from '@components/inputs/MuvalSelect.vue';
import MuvalTextArea from '@components/inputs/MuvalTextArea.vue';
export default {
    components: {
        MuvalSelect,
        MuvalTextArea,
    },
};
</script>
