<template>
    <SvgIcon
        :class="[textStyle, size, color]"
        :name="`${service}`"
    />
</template>

<script setup>
import { useStatusColours } from '@util/useStatusColours';

const props = defineProps({
    service: {
        type: String,
        required: true,
    },
    status: {
        type: String,
        required: false,
        default: null,
    },
    size: {
        type: String,
        required: false,
        default: 'h-5 w-5',
    },
    color: {
        type: String,
        required: false,
        default: null,
    },
});

const status = toRef(props, 'status');

const { textStyle } = useStatusColours(status, { type: 'ICON' });
</script>
