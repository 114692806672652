<template>
    <MuvalTippy v-if="inMaintenance">
        <div class="relative flex h-10 w-10 items-center justify-center">
            <SvgIcon
                name="system-warning"
                class="h-6 w-6 text-error"
            />
            <div class="absolute h-6 w-6 shrink-0 animate-ping rounded-full bg-error/25" />
        </div>
        <template #content>
            The platform is currently under maintenance. Changes made during this process won't necessarily be saved.
        </template>
    </MuvalTippy>
</template>
<script setup>
import { useQuery } from '@tanstack/vue-query';
const { data: inMaintenance } = useQuery({
    queryKey: ['booking-status'],
    queryFn: async () => {
        const response = await window.axios.get('booking-status');
        return response.data.maintenance;
    },
    placeholderData: false,
    refetchInterval: 60000,
});
</script>
