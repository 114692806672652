<template>
    <Tippy
        placement="top"
        animation="scale"
        :trigger="triggers"
        :interactive="interactive"
        :delay="[100, 100]"
        :duration="[100, 100]"
        :offset="[0, 5]"
        :popperOptions="{
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        altAxis: true,
                        tether: false,
                    },
                },
            ],
        }"
        :on-show="() => !$props.disabled"
    >
        <template #default>
            <slot v-bind="{ open: open }" />
        </template>
        <template #content>
            <div class="whitespace-pre-wrap rounded-muval-2 border bg-white px-2 py-1 text-muval-gray-1 shadow-muval-1">
                <slot name="content" />
            </div>
        </template>
    </Tippy>
</template>

<script setup>
import 'tippy.js/animations/scale.css';

const open = ref(false);

defineProps({
    triggers: {
        type: String,
        default: 'click mouseenter',
        validator: (value) => {
            return value.includes('click') || value.includes('mouseenter');
        },
    },
    interactive: {
        type: Boolean,
        default: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});
</script>
