import { defineStore } from 'pinia';

export const useTenderStore = defineStore('tender', () => {
    const tender = ref(null);

    const userStore = useUserStore();

    async function retrieve(tenderId) {
        try {
            const response = await window.axios.get(`/companies/${userStore.companyId}/tenders/${tenderId}`);
            tender.value = response.data;
        } catch (error) {
            console.error('tenderStore@retrieve', error);
            throw error;
        }
    }

    async function update(tenderId, data) {
        try {
            const response = await window.axios.patch(`/companies/${userStore.companyId}/tenders/${tenderId}`, data);
            tender.value = response.data;
        } catch (error) {
            console.error('tenderStore@update', error);
            throw error;
        }
    }

    async function calculateQuote(tenderId, values) {
        try {
            const response = await window.axios.post(
                `/companies/${userStore.companyId}/tenders/${tenderId}/matches/calculate`,
                values,
            );
            return response.data;
        } catch (error) {
            console.error('tenderStore@calculateQuote', error);
            throw error;
        }
    }

    async function createQuote(tenderId, values) {
        try {
            const response = await window.axios.post(
                `/companies/${userStore.companyId}/tenders/${tenderId}/matches`,
                values,
            );
            await retrieve(tenderId);
            return response.data;
        } catch (error) {
            console.error('tenderStore@createQuote', error);
            throw error;
        }
    }

    async function updateQuote(tenderId, match_id, values) {
        try {
            const response = await window.axios.patch(
                `/companies/${userStore.companyId}/tenders/${tenderId}/matches/${match_id}`,
                values,
            );
            await retrieve(tenderId);
            return response.data;
        } catch (error) {
            console.error('tenderStore@updateQuote', error);
            throw error;
        }
    }

    const canQuote = computed(() => {
        return tender.value?.tender_status === 'REQUESTED' || tender.value?.tender_status === 'QUOTED';
    });

    return {
        tender,
        retrieve,
        update,
        calculateQuote,
        createQuote,
        updateQuote,
        canQuote,
    };
});
