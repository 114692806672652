/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module User
 */

const ROUTE = '/providers';

async function create(providerData) {
    return await window.axios.post(ROUTE, providerData);
}

async function retrieve(providerId) {
    if (!providerId) throw new Error('No provider ID provided');
    const {
        data: { provider: data },
    } = await window.axios.get(`${ROUTE}/${providerId}`);
    return data;
}

async function update(providerId, providerData) {
    return await window.axios.patch(`${ROUTE}/${providerId}`, {
        ...providerData,
    });
}

async function destroy(providerId) {
    return await window.axios.delete(`${ROUTE}/${providerId}`);
}

export default {
    create,
    retrieve,
    update,
    destroy,
};
