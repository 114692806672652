import { defineStore } from 'pinia';
import { isEqual } from 'lodash';

export const useOrderAddonStore = defineStore('addons', {
    state: () => {
        return {
            loading: false,
            data: [],
        };
    },
    actions: {
        /*
         * Retrieve a addons
         */
        async retrieve(orderId, serviceId) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.get(`/orders/${orderId}/addons/${serviceId}`);
                let copy = this.mapData(data.order_addon);
                if (this.data.some((item) => item?.id == serviceId)) {
                    let index = this.data.findIndex((item) => item?.id == serviceId);
                    this.data[index] = copy;
                } else {
                    this.data.push(copy);
                }
                this.loading = false;
                return copy;
            } catch (error) {
                console.log('Error retrieving addons', error);
                throw error;
            }
        },

        /*
         * Create a addons
         */
        async create(orderId, addonsData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.post(`/orders/${orderId}/addons`, {
                    ...addonsData,
                });
                this.loading = false;
                this.data.push(data.order_addon);
                return data.order_addon;
            } catch (error) {
                console.log('Error creating addons', error);
                throw error;
            }
        },

        /*
         * Update a addons
         */
        async update(orderId, serviceId, addonsData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/addons/${serviceId}`, {
                    ...addonsData,
                });
                let copy = this.mapData(data.order_addon);
                let index = this.data.findIndex((addon) => addon.id == copy.id);
                this.data[index] = copy;
                this.loading = false;
                return copy;
            } catch (error) {
                console.log('Error updating addons', error);
                throw error;
            }
        },

        async inlineUpdate(orderId, serviceId, addonsData) {
            try {
                const { data: data } = await window.axios.patch(`/orders/${orderId}/addons/${serviceId}`, {
                    ...addonsData,
                });
                let copy = this.mapData(data.order_addon);
                let index = this.data.findIndex((addon) => addon.id == copy.id);
                this.data[index] = copy;
                return copy;
            } catch (error) {
                console.log('Error updating addons', error);
                throw error;
            }
        },

        checkDifference(currentFormData, originalFormData) {
            return Object.keys(currentFormData).reduce((formData, field) => {
                if (!isEqual(currentFormData[field], originalFormData[field])) {
                    formData[field] = currentFormData[field];
                }
                return formData;
            }, {});
        },

        mapData(addon) {
            let copy = addon;
            copy = addon;
            // copy.price = addon.price.amount;
            // copy.muval_fee = addon.muval_fee.amount;
            if (!copy.provider_id) delete copy.provider_id;
            if (!copy.bundle_move_id) delete copy.bundle_move_id;
            if (!copy?.bundle_move) copy.bundle_move = false;
            return copy;
        },

        setAddon(addon) {
            let index = this.data.findIndex((item) => item.id == addon.id);
            this.data[index] = addon;
        },
    },
    getters: {
        getAddons: (state) => {
            return state.data ?? null;
        },
    },
});
