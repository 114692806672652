<template>
    <nav
        class="flex max-h-full flex-col gap-y-6 overflow-y-auto bg-white pt-7 text-base"
        style="width: 250px"
    >
        <SvgIcon
            class="ml-4 h-7 w-32 shrink-0"
            name="logo"
        />
        <div class="grid">
            <template
                v-for="(item, idx) in userStore.navigation"
                :key="idx"
            >
                <component :is="item?.subitems ? Disclosure : 'div'">
                    <router-link
                        v-if="!item.subitems"
                        v-slot="{ isActive }"
                        :to="{ path: item.route }"
                        @click.prevent="canRefresh(item.route) && $router.go()"
                        @click.ctrl="modClick(item.route)"
                        @click.meta="modClick(item.route)"
                    >
                        <!-- TODO: Remove item.name != 'Scheduler' below -->
                        <div
                            v-if="item && item.name != 'Scheduler'"
                            :key="item"
                            class="flex h-10 items-center gap-4 overflow-x-clip px-4 text-base font-normal no-underline hover:bg-muval-gray-6"
                        >
                            <SvgIcon
                                :class="[
                                    { '!text-brand': subIsActive(item.route) },
                                    { 'text-muval-gray-1 dark:text-white': !isActive },
                                ]"
                                class="h-6 w-6 flex-shrink-0 transition"
                                :name="item.icon"
                            />
                            <span
                                class="opacity-100 transition duration-200 ease-in-out"
                                :class="[subIsActive(item.route) ? 'text-brand' : 'text-muval-gray-1']"
                            >
                                {{ item.name }}
                            </span>
                            <div
                                v-if="item.meta?.count"
                                class="ml-2 rounded-md bg-yellow-100 px-2 py-0.5 text-xs font-bold text-yellow-500"
                            >
                                {{ item.meta?.count }}
                            </div>
                        </div>
                    </router-link>
                    <template v-else>
                        <DisclosureButton
                            v-slot="{ open }"
                            class="w-full"
                        >
                            <div
                                v-if="item && item.name != 'Scheduler'"
                                :key="item"
                                class="flex h-10 items-center gap-4 px-4 text-base font-normal no-underline hover:bg-muval-gray-6"
                            >
                                <SvgIcon
                                    :class="[
                                        {
                                            'text-brand':
                                                open && item.subitems.some((subitem) => subIsActive(subitem.route)),
                                        },
                                        { 'text-muval-gray-1 dark:text-white': !isActive },
                                    ]"
                                    class="h-6 w-6 flex-shrink-0 transition"
                                    :name="item.icon"
                                />
                                <span
                                    class="text-muval-gray-1 opacity-100 transition duration-200 ease-in-out dark:text-gray-300"
                                    :class="[
                                        {
                                            'text-brand dark:text-brand':
                                                open && item.subitems.some((subitem) => subIsActive(subitem.route)),
                                        },
                                        { 'text-gray-800:': isActive },
                                    ]"
                                >
                                    {{ item.name }}
                                </span>
                                <div
                                    v-if="item.meta?.count"
                                    class="ml-2 rounded-md bg-yellow-100 px-2 py-0.5 text-xs font-bold text-yellow-500"
                                >
                                    {{ item.meta?.count }}
                                </div>
                            </div>
                        </DisclosureButton>

                        <DisclosurePanel
                            class="flex flex-col gap-1"
                            as="div"
                        >
                            <router-link
                                v-for="(subitem, subItemIndex) in item.subitems"
                                :key="subItemIndex"
                                v-slot="{ isActive }"
                                :to="{ path: subitem.route }"
                                @click.prevent="canRefresh(subitem.route) && $router.go()"
                                @click.ctrl="modClick(subitem.route)"
                                @click.meta="modClick(subitem.route)"
                            >
                                <div
                                    v-if="subitem && subitem.name != 'Scheduler'"
                                    :key="subitem"
                                    class="flex h-9 items-center gap-4 px-4 text-base font-normal no-underline hover:bg-muval-gray-6"
                                >
                                    <div class="flex h-6 w-6 flex-shrink-0 items-center justify-center">
                                        <div
                                            class="h-1.5 w-1.5 rounded-full bg-muval-gray-4"
                                            :class="[subitem.route === $route.path && '!bg-muval-brand']"
                                        ></div>
                                    </div>
                                    <span
                                        class="text-sm text-muval-gray-1 opacity-100 transition duration-200 ease-in-out dark:text-gray-300"
                                        :class="[
                                            {
                                                '!text-brand': subitem.route === $route.path,
                                            },
                                            { 'text-gray-800:': isActive },
                                        ]"
                                    >
                                        {{ subitem.name }}
                                    </span>
                                    <div
                                        v-if="subitem.meta?.count"
                                        class="ml-2 rounded-md bg-yellow-100 px-2 py-0.5 text-xs font-bold text-yellow-500"
                                    >
                                        {{ subitem.meta?.count }}
                                    </div>
                                </div>
                            </router-link>
                        </DisclosurePanel>
                    </template>
                </component>
            </template>
        </div>
    </nav>
</template>
<script setup>
// const appStore = useAppStore();
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const userStore = useUserStore();

const route = useRoute();
const router = useRouter();

function subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    if (route.name == 'Order' && router?.options?.history?.state?.back == '/jobs' && input == '/jobs') {
        return true;
    } else if (route.name == 'Order' && router?.options?.history?.state?.back == '/jobs' && input == '/orders') {
        return false;
    } else {
        return paths.some((path) => {
            return route.path.indexOf(path) === 0; // current path starts with this path string
        });
    }
}

function modClick(route) {
    window.open(route, '_blank');
}

function canRefresh(input) {
    return route.path === input;
}
</script>
