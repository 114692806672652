<template>
    <div class="mx-auto flex items-center text-sm text-muval-gray-1 dark:text-gray-100">
        {{ $filters.capitalize(data) }}
        <!-- <div v-if="data === 'b-double'">
      <Tippy
        :follow-cursor="true"
        :arrow="true"
        :delay="[900, 0]"
        :duration="[200, 0]"
      >
        <svg
          class="mx-auto"
          width="36"
          height="16"
          viewBox="0 0 36 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33 4H30V0L2.5 0C1.4 0 0.5 0.9 0.5 2V13H2.5C2.5 14.66 3.84 16 5.5 16C7.16 16 8.5 14.66 8.5 13H28C28 14.66 29.34 16 31 16C32.66 16 34 14.66 34 13H36V8L33 4ZM5.5 14.5C4.67 14.5 4 13.83 4 13C4 12.17 4.67 11.5 5.5 11.5C6.33 11.5 7 12.17 7 13C7 13.83 6.33 14.5 5.5 14.5ZM32.5 5.5L34.46 8H30V5.5H32.5ZM31 14.5C30.17 14.5 29.5 13.83 29.5 13C29.5 12.17 30.17 11.5 31 11.5C31.83 11.5 32.5 12.17 32.5 13C32.5 13.83 31.83 14.5 31 14.5Z"
            fill="currentColor"
            fill-opacity="0.4"
          />
        </svg>
        <template #content>
          <div
            class="px-4 uppercase py-1 bg-opacity-80 text-white rounded bg-black shadow"
          >
            {{ data }}
          </div>
        </template>
      </Tippy>
    </div>

    <div v-else-if="data === 'van'">
      <Tippy
        :follow-cursor="true"
        :arrow="true"
        :delay="[900, 0]"
        :duration="[200, 0]"
      >
        <svg
          width="22"
          height="14"
          viewBox="0 0 22 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 0H20C21.1 0 22 0.89 22 2V11H20C20 12.65 18.66 14 17 14C15.34 14 14 12.65 14 11H8.5C8.5 12.65 7.16 14 5.5 14C3.84 14 2.5 12.65 2.5 11H0V6L6 0ZM17 12.5C17.83 12.5 18.5 11.83 18.5 11C18.5 10.17 17.83 9.5 17 9.5C16.17 9.5 15.5 10.17 15.5 11C15.5 11.83 16.17 12.5 17 12.5ZM5.5 12.5C6.33 12.5 7 11.83 7 11C7 10.17 6.33 9.5 5.5 9.5C4.67 9.5 4 10.17 4 11C4 11.83 4.67 12.5 5.5 12.5ZM8 6V2H7L3 6H8Z"
            fill="currentColor"
            fill-opacity="0.4"
          />
        </svg>

        <template #content>
          <div
            class="px-4 uppercase py-1 bg-opacity-80 text-white rounded bg-black shadow"
          >
            {{ data }}
          </div>
        </template>
      </Tippy>
    </div>
    <div v-else-if="data === 'truck'">
      <Tippy
        :follow-cursor="true"
        :arrow="true"
        :delay="[900, 0]"
        :duration="[200, 0]"
      >
        <svg
          width="22"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 4H16V0H2C0.9 0 0 0.9 0 2V13H2C2 14.66 3.34 16 5 16C6.66 16 8 14.66 8 13H14C14 14.66 15.34 16 17 16C18.66 16 20 14.66 20 13H22V8L19 4ZM5 14.5C4.17 14.5 3.5 13.83 3.5 13C3.5 12.17 4.17 11.5 5 11.5C5.83 11.5 6.5 12.17 6.5 13C6.5 13.83 5.83 14.5 5 14.5ZM18.5 5.5L20.46 8H16V5.5H18.5ZM17 14.5C16.17 14.5 15.5 13.83 15.5 13C15.5 12.17 16.17 11.5 17 11.5C17.83 11.5 18.5 12.17 18.5 13C18.5 13.83 17.83 14.5 17 14.5Z"
            fill="currentColor"
            fill-opacity="0.4"
          />
        </svg>

        <template #content>
          <div
            class="px-4 uppercase py-1 bg-opacity-80 text-white rounded bg-black shadow"
          >
            {{ data }}
          </div>
        </template>
      </Tippy>
    </div>
    <div v-else-if="data === 'trailer'">
      <Tippy
        :follow-cursor="true"
        :arrow="true"
        :delay="[900, 0]"
        :duration="[200, 0]"
      >
        <svg
          width="22"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 4H16V0H2C0.9 0 0 0.9 0 2V13H2C2 14.66 3.34 16 5 16C6.66 16 8 14.66 8 13H14C14 14.66 15.34 16 17 16C18.66 16 20 14.66 20 13H22V8L19 4ZM5 14.5C4.17 14.5 3.5 13.83 3.5 13C3.5 12.17 4.17 11.5 5 11.5C5.83 11.5 6.5 12.17 6.5 13C6.5 13.83 5.83 14.5 5 14.5ZM18.5 5.5L20.46 8H16V5.5H18.5ZM17 14.5C16.17 14.5 15.5 13.83 15.5 13C15.5 12.17 16.17 11.5 17 11.5C17.83 11.5 18.5 12.17 18.5 13C18.5 13.83 17.83 14.5 17 14.5Z"
            fill="currentColor"
            fill-opacity="0.4"
          />
        </svg>

        <template #content>
          <div
            class="px-4 uppercase py-1 bg-opacity-80 text-white rounded bg-black shadow"
          >
            {{ data }}
          </div>
        </template>
      </Tippy>
    </div>
    <div v-else-if="data === 'ute'">
      <Tippy
        :follow-cursor="true"
        :arrow="true"
        :delay="[900, 0]"
        :duration="[200, 0]"
      >
        <template #content>
          <div
            class="px-4 uppercase py-1 bg-opacity-80 text-white rounded bg-black shadow"
          >
            {{ data }}
          </div>
        </template>
      </Tippy>
    </div>
    <div v-else-if="data === 'train'">
      <Tippy
        :follow-cursor="true"
        :arrow="true"
        :delay="[900, 0]"
        :duration="[200, 0]"
      >
        <svg
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0C4 0 0 0.5 0 4V13.5C0 15.43 1.57 17 3.5 17L2 18.5V19H4.23L6.23 17H10L12 19H14V18.5L12.5 17C14.43 17 16 15.43 16 13.5V4C16 0.5 12.42 0 8 0ZM3.5 15C2.67 15 2 14.33 2 13.5C2 12.67 2.67 12 3.5 12C4.33 12 5 12.67 5 13.5C5 14.33 4.33 15 3.5 15ZM7 8H2V4H7V8ZM9 8V4H14V8H9ZM12.5 15C11.67 15 11 14.33 11 13.5C11 12.67 11.67 12 12.5 12C13.33 12 14 12.67 14 13.5C14 14.33 13.33 15 12.5 15Z"
            fill="currentColor"
            fill-opacity="0.4"
          />
        </svg>

        <template #content>
          <div
            class="px-4 uppercase py-1 bg-opacity-80 text-white rounded bg-black shadow"
          >
            {{ data }}
          </div>
        </template>
      </Tippy>
    </div> -->
    </div>
</template>

<script>
export default {
    name: 'VehicleType',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>
