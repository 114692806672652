<template>
    <div class="relative h-input w-full p-0">
        <div class="relative flex h-input w-full cursor-pointer select-none">
            <input
                :id="vid"
                ref="input"
                :data-cy="vid"
                :disabled="disabled"
                :name="vid"
                :type="inputType"
                class="bloc loading peer h-input w-full resize-y overflow-y-auto rounded-input border text-sm text-muval-gray-1 placeholder-transparent hover:border-muval-gray-3 focus:border-focus focus:outline-none focus:ring-0"
                :class="{
                    'border-error': errorMessage,
                    'border-muval-gray-4': !errorMessage,
                    'bg-white': !errorMessage,
                    'placeholder-gray-400': !label && placeholder,
                    'bg-muval-gray-5': disabled,
                }"
                :placeholder="placeholder ? placeholder : label"
                :value="inputValue"
                :autocomplete="autocomplete"
                :autofocus="autofocus"
                @change="onInput($event)"
                @blur="handleInternalBlur"
            />
            <div
                v-if="label"
                class="peer pointer-events-none absolute -top-2 left-2 z-1 line-clamp-1 text-xs text-gray-400 transition-all duration-75 peer-placeholder-shown:top-[0.6125rem] peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-focus skeleton:hidden"
                :class="errorMessage ? 'text-error' : ''"
            >
                <span class="z-1 px-1">{{ label }}</span>
                <div
                    class="absolute top-2 -z-1 h-0.5 w-full px-2 dark:bg-gray-900"
                    :class="disabled ? 'bg-muval-gray-5' : 'bg-white'"
                ></div>
            </div>
            <div
                v-if="cubic"
                :class="errorMessage ? 'border-error' : 'border-[#D0D0D0]'"
                class="peer pointer-events-none absolute right-0 top-0 z-0 h-input w-1/5"
            >
                <div class="absolute right-0 p-2 align-middle text-sm text-gray-900">
                    <span>m&sup3;</span>
                </div>
            </div>

            <div
                v-if="infoType"
                :class="errorMessage ? 'border-error' : 'border-[#D0D0D0]'"
                class="peer pointer-events-none absolute right-0 top-0 z-0 flex h-input w-auto px-4 text-muval-gray-1"
            >
                <div class="relative my-auto mr-auto">
                    <span class="text-sm">{{ infoType }}</span>
                    <div
                        v-if="errorMessage"
                        class="absolute -left-5 top-1.5"
                    >
                        <svg
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-error"
                        >
                            <path
                                d="M7.24598 9.71591L5.92852 9.71591L5.92852 8.42046L7.24598 8.42046L7.24598 9.71591ZM7.24598 7.125L5.92852 7.125L5.92852 3.23864L7.24598 3.23864L7.24598 7.125ZM6.58725 12.9546C10.2234 12.9546 13.1746 10.0527 13.1746 6.47728C13.1746 2.90182 10.2234 -7.30522e-07 6.58725 -1.0431e-06C2.95106 -1.35567e-06 -5.34657e-05 2.90182 -5.37836e-05 6.47727C-5.41014e-05 10.0527 2.95106 12.9546 6.58725 12.9546ZM6.58725 1.29545C9.49226 1.29545 11.8571 3.6208 11.8571 6.47728C11.8571 9.33375 9.49226 11.6591 6.58725 11.6591C3.68225 11.6591 1.31741 9.33375 1.31741 6.47727C1.31741 3.6208 3.68225 1.29545 6.58725 1.29545Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
            </div>

            <div
                v-if="errorMessage && !infoType && !cubic"
                class="absolute right-2 top-3"
            >
                <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-error"
                >
                    <path
                        d="M7.24598 9.71591L5.92852 9.71591L5.92852 8.42046L7.24598 8.42046L7.24598 9.71591ZM7.24598 7.125L5.92852 7.125L5.92852 3.23864L7.24598 3.23864L7.24598 7.125ZM6.58725 12.9546C10.2234 12.9546 13.1746 10.0527 13.1746 6.47728C13.1746 2.90182 10.2234 -7.30522e-07 6.58725 -1.0431e-06C2.95106 -1.35567e-06 -5.34657e-05 2.90182 -5.37836e-05 6.47727C-5.41014e-05 10.0527 2.95106 12.9546 6.58725 12.9546ZM6.58725 1.29545C9.49226 1.29545 11.8571 3.6208 11.8571 6.47728C11.8571 9.33375 9.49226 11.6591 6.58725 11.6591C3.68225 11.6591 1.31741 9.33375 1.31741 6.47727C1.31741 3.6208 3.68225 1.29545 6.58725 1.29545Z"
                        fill="currentColor"
                    />
                </svg>
            </div>
        </div>
        <div
            class="pointer-events-none absolute -bottom-5 w-full truncate whitespace-nowrap text-xxs"
            :class="errorMessage ? 'text-error' : ''"
        >
            <span class="text-xxs">{{ errorMessage }}</span>
        </div>

        <!-- Skelton -->
        <div class="skeleton:loader"></div>
    </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { idGen } from '@utilities/idGen';

const props = defineProps({
    label: {
        type: [String, null],
        required: false,
        default: null,
    },
    placeholder: {
        type: [String, null],
        required: false,
        default: null,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    vid: {
        type: String,
        required: false,
        default: () => idGen(6),
    },
    inputType: {
        type: String,
        required: false,
        default: 'text',
    },
    cubic: {
        type: Boolean,
        required: false,
        default: false,
    },
    infoType: {
        type: [String, null],
        required: false,
        default: null,
    },
    modelValue: {
        type: [String, Number, null],
        required: false,
        default: null,
    },
    autocomplete: {
        type: String,
        required: false,
        default: 'off',
    },
    autofocus: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue', 'blur']);

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
} = useField(props.vid, undefined, {
    initialValue: props.modelValue,
    valueProp: props.modelValue,
    validateOnValueUpdate: false,
});

const onInput = (event) => {
    handleChange(event, true);
    emit('update:modelValue', event.target.value);
};

function handleInternalBlur() {
    handleBlur();
    emit('blur', inputValue.value);
}

function focusInput() {
    this.$refs.input.focus();
}

defineExpose({
    focusInput: focusInput,
});
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
</style>
