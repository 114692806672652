import { defineStore } from 'pinia';
import localApi from '@api/local';

export const useLocalStore = defineStore('local', {
    state: () => {
        return {
            loading: false,
            data: {},
            createValues: {
                depot_radius: 30, // has to be a multiple of 10
            },
        };
    },
    getters: {},
    actions: {
        async create(local) {
            try {
                this.loading = true;
                await localApi.create(local);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async retrieve(localId) {
            try {
                this.loading = true;
                const local = await localApi.retrieve(localId);
                this.mapResponseValues(local.data);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async update(localId, local) {
            try {
                this.loading = true;
                const data = await localApi.update(localId, local);
                this.mapResponseValues(data.data.local_price);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        mapResponseValues(local) {
            this.data.company_id = local.company_id;
            this.data.depot_id = local.depot.id;
            this.data.depot_radius = local.depot_radius;
            this.data.depot_address = local.depot.address;
            this.data.auto_hide = local.auto_hide;
            this.data.rates = { small: {}, large: {} };

            // Small
            let smallIndex = local.rates.findIndex((item) => item.truck_size == 'SMALL');
            let largeIndex = local.rates.findIndex((item) => item.truck_size == 'LARGE');
            this.data.rates.small.two_mon_fri = local.rates[smallIndex].two_mon_fri?.amount;
            this.data.rates.small.three_mon_fri = local.rates[smallIndex].three_mon_fri?.amount;
            this.data.rates.small.four_mon_fri = local.rates[smallIndex].four_mon_fri?.amount;

            this.data.rates.small.two_sat = local.rates[smallIndex].two_sat?.amount;
            this.data.rates.small.two_sun = local.rates[smallIndex].two_sun?.amount;
            this.data.rates.small.three_sat = local.rates[smallIndex].three_sat?.amount;
            this.data.rates.small.three_sun = local.rates[smallIndex].three_sun?.amount;
            this.data.rates.small.four_sat = local.rates[smallIndex].four_sat?.amount;
            this.data.rates.small.four_sun = local.rates[smallIndex].four_sun?.amount;

            // Large
            this.data.rates.large.two_mon_fri = local.rates[largeIndex].two_mon_fri?.amount;
            this.data.rates.large.three_mon_fri = local.rates[largeIndex].three_mon_fri?.amount;
            this.data.rates.large.four_mon_fri = local.rates[largeIndex].four_mon_fri?.amount;

            this.data.rates.large.two_sat = local.rates[largeIndex].two_sat?.amount;
            this.data.rates.large.two_sun = local.rates[largeIndex].two_sun?.amount;
            this.data.rates.large.three_sat = local.rates[largeIndex].three_sat?.amount;
            this.data.rates.large.three_sun = local.rates[largeIndex].three_sun?.amount;
            this.data.rates.large.four_sat = local.rates[largeIndex].four_sat?.amount;
            this.data.rates.large.four_sun = local.rates[largeIndex].four_sun?.amount;

            this.data.active = local.active;
        },
    },
});
