import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { useInventoryStore } from '@store';
import { useOrderStore } from '@store';

export const useMoveStore = defineStore('move', {
    state: () => {
        return {
            loading: false,
            data: {},
            jobs: [],
            jobsStatuses: [],
        };
    },
    actions: {
        /*
         * Retrieve a move
         */
        async retrieve(orderId) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.get(`/orders/${orderId}/move`);
                this.data = data.move;
                this.loading = false;
                return data;
            } catch (error) {
                console.log('Error retrieving move', error);
                throw error;
            }
        },

        /*
         * Create a move
         */
        async create(orderId, moveData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.post(`/orders/${orderId}/move`, {
                    ...moveData,
                });
                this.data = data.move;
                this.loading = false;
                return data;
            } catch (error) {
                console.log('Error creating move', error);
                this.loading = false;
                throw error;
            }
        },

        /*
         * Update a move
         */
        async update(orderId, moveData) {
            try {
                this.loading = true;
                const { data: data } = await window.axios.patch(`/orders/${orderId}/move`, {
                    ...moveData,
                });
                let temp = cloneDeep(this.data.inventory);
                this.data = data.move;
                this.data.inventory = temp;
                this.loading = false;
                return data;
            } catch (error) {
                console.log('Error updating move', error);
                this.loading = false;
                throw error;
            }
        },

        async inlineUpdate(orderId, moveData) {
            try {
                const { data: data } = await window.axios.patch(`/orders/${orderId}/move`, {
                    ...moveData,
                });
                console.log('data', data);
                this.data = data.move;
                return data;
            } catch (error) {
                console.log('Error updating move', error);
                throw error;
            }
        },

        async retrieveJobs(moveId = this.getMoveId) {
            try {
                const resp = await window.axios.get(`/moves/${moveId}/jobs?sort=-created_at`);
                this.jobs = resp.data.data;
                if (this.jobs.length > 0) {
                    await this.retrieveJobsStatuses();
                }
                console.log(resp);
            } catch (e) {
                console.log('Store@move retrieveJobs() error', e);
                throw e;
            }
        },

        async retrieveJob(job_id) {
            try {
                const resp = await window.axios.get(`/moves/${this.getMoveId}/jobs/${job_id}`);
                return resp.data.job;
            } catch (e) {
                console.log('Store@move retrieveJobs() error', e);
                throw e;
            }
        },

        async retrieveJobsStatuses(moveId = this.getMoveId) {
            this.jobsStatuses = [];
            this.jobs.forEach(async (job) => {
                const response = await window.axios.get(`/moves/${moveId}/jobs/${job.id}/statuses`);

                let formatted = [];

                for (const [key, val] of Object.entries(response.data.data)) {
                    console.log(key, val);
                    formatted.push({
                        name: val,
                        value: key,
                    });
                }
                this.jobsStatuses.push({
                    job_id: job.id,
                    statuses: [...formatted],
                });
            });
            return this.jobsStatuses;
        },

        async retrieveInventory() {
            try {
                const inventoryStore = useInventoryStore();
                await inventoryStore.retrieve(this.getMoveId);
            } catch (e) {
                console.log('Store@move retrieveInventory() error', e);
                throw e;
            }
        },

        setInventory(inventory) {
            this.data.inventory = inventory;
        },

        setQuoteType(quoteType) {
            this.data.quote_type = quoteType;
        },

        updateBundledAddon(value) {
            let index = this.data.bundledAddons.findIndex((el) => el.id == value.id);
            this.data.bundledAddons[index] = value;
        },

        // Match related

        async selectMatch(matchId, orderId) {
            try {
                const response = await window.axios.patch(`/orders/${orderId}/move`, {
                    service_match_id: matchId,
                });
                this.data = response.data.move;
                return response.data.move;
            } catch (error) {
                console.log('Error selecting match', error);
                throw error;
            }
        },

        async applyCoupon(coupon) {
            try {
                await window.axios.post(`/coupons/${this.getOrderItemId}/apply`, {
                    coupon,
                });
                await this.retrieve(this.getOrderId);
            } catch (error) {
                console.log('Error revoking coupon', error);
                throw error;
            }
        },

        async revokeCoupon() {
            try {
                await window.axios.delete(`/coupons/${this.getOrderItemId}/remove`);
                await this.retrieve(this.getOrderId);
                const orderStore = useOrderStore();
                await orderStore.retrieve();
                await orderStore.fetchItems();
            } catch (error) {
                console.log('Error revoking coupon', error);
                throw error;
            }
        },
    },
    getters: {
        getMoveId(state) {
            return state.data.id ? state.data.id : undefined;
        },

        getOrderId(state) {
            return state.data.order_id ? state.data.order_id : undefined;
        },

        getOrderItemId(state) {
            return state.data.order_item_id ? state.data.order_item_id : undefined;
        },

        getMove: (state) => {
            return state?.data ?? null;
        },

        getQuoteType: (state) => {
            return state?.data?.quote_type ?? null;
        },

        isLocal: (state) => {
            return state.data?.type == 'LOCAL';
        },

        getType: (state) => {
            return state.data?.type ?? null;
        },

        isInventoryHeavy: () => {
            const inventory = useInventoryStore();
            return inventory.hasHeavyItem;
        },

        getSpace(state) {
            return state.data.space;
        },

        getSelectedMatch(state) {
            return state.data?.selectedMatch ?? null;
        },

        getJob(state) {
            return state.data?.job;
        },

        getStatus(state) {
            return state.data?.status;
        },

        getTimeEstimate(state) {
            if (state.data?.time_est?.min && state.data?.time_est?.max) {
                return state.data.time_est.min + ' - ' + state.data.time_est.max + ' hrs';
            } else {
                return state.data.time_est?.time + ' hrs';
            }
        },

        isMuvexpress() {
            return this.getSelectedMatch?.match_type == 'MUVEXPRESS';
        },

        isSelfPack() {
            return this.getSelectedMatch?.match_type == 'SELF_PACK';
        },

        isSelfy() {
            return this.data?.is_selfy;
        },

        isTaxibox() {
            return this.data?.is_taxibox;
        },

        hasBundledAddons(state) {
            return state.data?.selectedMatch?.bundles?.length > 0 ?? false;
        },

        getBundledAddons(state) {
            return state.data?.selectedMatch?.bundles;
        },

        hasJobs(state) {
            return state.jobs.length > 0;
        },

        hasActionableJobs(state) {
            return state.jobs.filter((job) => !['CANCELLED', 'DECLINED', 'WITHDRAWN'].includes(job.status)).length > 0;
        },

        hasPendingJob(state) {
            return state.jobs.find((job) => job.status == 'PENDING');
        },

        hasAcceptedJob(state) {
            return state.jobs.find((job) => job.status == 'ACCEPTED');
        },

        hasReadyJob(state) {
            if (state.jobs.find((job) => job.status == 'READY')) {
                return true;
            } else {
                return false;
            }
        },

        hasCompletedJob(state) {
            if (state.jobs.find((job) => job.status == 'COMPLETED')) {
                return true;
            } else {
                return false;
            }
        },

        getJobsStatuses(state) {
            return (jobId) => {
                return state.jobsStatuses.find((el) => el.job_id == jobId)?.statuses;
            };
        },

        hasPendingInventory(state) {
            return !!state.data?.pending_inventory?.length ?? false;
        },

        getDatesFormatted(state) {
            return {
                pickup_date_type: state.data.pickup_date_type,
                ...(state.data.pickup_date_from && {
                    pickup_date_from: utcToUserTimezone(state.data.pickup_date_from),
                }),
                ...(state.data.pickup_date_by && {
                    pickup_date_by: utcToUserTimezone(state.data.pickup_date_by),
                }),
                ...(state.data.pickup_date_interval && {
                    pickup_date_interval: state.data.pickup_date_interval,
                }),
                ...(state.data.pickup_date_flexibility && {
                    pickup_date_flexibility: state.data.pickup_date_flexibility,
                }),
            };
        },

        getPickupAccessFormatted(state) {
            if (state.data?.pickup_access?.length > 0) {
                return state.data.pickup_access.map((item) => {
                    return {
                        name: item.accessTag.item,
                        unit: item.accessTag.unit,
                        access_tag_id: item.accessTag.id,
                        ...item?.accessTag,
                        value: item.value ?? null,
                    };
                });
            } else {
                return [];
            }
        },

        getDeliveryAccessFormatted(state) {
            if (state.data?.delivery_access?.length > 0) {
                return state.data.delivery_access.map((item) => {
                    console.log(item);
                    return {
                        name: item.accessTag.item,
                        unit: item.accessTag.unit,
                        access_tag_id: item.accessTag.id,
                        ...item?.accessTag,
                        value: item.value ?? null,
                    };
                });
            } else {
                return [];
            }
        },

        getHideOrganiceMatches(state) {
            return state.data?.hide_organic_matches ?? 0;
        },

        getActiveJobsCount(state) {
            return state.jobs.filter(
                (job) => job.status == 'ACCEPTED' || job.status == 'READY' || job.status == 'COMPLETED',
            ).length;
        },
    },
});
