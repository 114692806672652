/*
 * Returns the pinia store for the given service type
 */

import { useQuery } from '@tanstack/vue-query';

export function useAgents({ limit = 999, nullable = false } = {}) {
    const {
        data: agents,
        isLoading,
        isFetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ['agents'],
        queryFn: getAgents,
        retry: false,
        keepPreviousData: true,
        placeholderData: [],
        staleTime: 300000,
    });

    async function getAgents() {
        const {
            data: { data: response },
        } = await window.axios.get('/users/muval', { params: { limit } });
        return response;
    }

    const agentsFormatted = computed(() => {
        if (!agents.value || !agents.value?.length) return [];

        let formatted = [
            { label: 'Active agent', options: [] },
            { label: 'Other staff', options: [] },
            { label: 'Inactive staff', options: [] },
        ];

        if (nullable) {
            formatted = [{ label: '', options: [{ fullname: 'Unassigned', agent_id: null }] }, ...formatted];
        }

        agents.value.forEach((item) => {
            if (item.is_active) {
                if (item.roles.includes('MUVAL_AGENT')) {
                    formatted.find((opt) => opt.label == 'Active agent').options.push(item);
                } else {
                    formatted.find((opt) => opt.label == 'Other staff').options.push(item);
                }
            } else {
                formatted.find((opt) => opt.label == 'Inactive staff').options.push(item);
            }
        });

        return formatted;
    });

    return {
        agents,
        agentsFormatted,
        isLoading,
        isFetching,
        refetch,
        error,
    };
}
