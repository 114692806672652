<template>
    <div class="z-0 flex w-full justify-between">
        <div class="">
            {{ data?.item }}
        </div>
        <span class="ml-4 rounded-full bg-brand bg-opacity-75 px-3 py-0 capitalize text-white">
            {{ data?.unit?.unit }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'MSPriceItem',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>
