<template>
    <div class="w-full max-w-md">
        <div class="flex h-full w-full flex-col divide-y">
            <NotificationItem
                v-for="(notification, index) in notificationsStore.all"
                :key="index"
                :notification="notification"
                @notification-clicked="handleClick"
                @mark-read="markRead"
            />
        </div>
    </div>
</template>

<script setup>
import { useNotificationsStore } from '@store/notifications';

const notificationsStore = useNotificationsStore();
const router = useRouter();
const emit = defineEmits(['close']);

const handleClick = async (notification) => {
    if (!notification.read_at) {
        try {
            await notificationsStore.read(notification.id);
        } catch (error) {
            console.error('Error marking notification as read', notification.id, error);
        }
    }
    emit('close');

    if (notification.data.url) {
        router.push({ path: notification.data.url });
    }
};

async function markRead(notification) {
    if (!notification.read_at) {
        await notificationsStore.read(notification.id);
    }
}
</script>
