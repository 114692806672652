<template>
    <Menu
        as="div"
        class="relative"
    >
        <MenuButton class="flex items-center gap-x-3">
            <span class="sr-only">Open user menu</span>
            <div class="relative">
                <img
                    class="size-10 rounded-full"
                    :src="user.getAvatar"
                />
                <div
                    v-if="getStatusColourByValue(user.activity_status)"
                    class="absolute bottom-0.5 right-0.5 size-2 rounded-full ring-2 ring-white transition-colors"
                    :class="getStatusColourByValue(user.activity_status)"
                />
            </div>
            <div class="hidden flex-col items-start lg:flex">
                <span
                    class="leading-3"
                    v-text="user.getFullName"
                />
                <span
                    class="text-xs text-muval-gray-2"
                    v-text="getStatusNameByValue(user.activity_status)"
                />
            </div>
            <SvgIcon
                name="system-chevron-down"
                class="-ml-1 hidden size-5 text-muval-gray-2 lg:block"
            />
        </MenuButton>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems class="absolute right-0 z-50 mt-2 min-w-52 origin-top-right rounded-lg border bg-white shadow">
                <MenuItem
                    v-if="user.hasAnyRole(['MUVAL_AGENT'])"
                    as="div"
                    class="flex cursor-pointer items-center gap-x-3 border-b px-4 py-3 text-muval-gray-1"
                >
                    <MuvalSelect
                        v-model="userActiveStatus"
                        value-prop="value"
                        :select-options="statusesWithDisabled"
                        rounded
                        :searchable="false"
                        option-component="MSUserActiveStatusOption"
                        display-component="MSUserActiveStatusDisplay"
                        class="w-full"
                        @click.stop
                    />
                </MenuItem>
                <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    as="div"
                    class="flex cursor-pointer items-center gap-x-3 px-4 py-3 text-muval-gray-1"
                    @click="$router.push(item.to)"
                >
                    <SvgIcon
                        :name="item.icon"
                        class="size-6"
                    />
                    <span v-text="item.name" />
                </MenuItem>

                <MenuItem
                    as="div"
                    class="flex cursor-pointer items-center gap-x-3 border-t px-4 py-3 text-muval-gray-1"
                    @click="logout"
                >
                    <SvgIcon
                        name="system-logout"
                        class="size-6"
                    />
                    <span>Log out</span>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import { uniqBy } from 'lodash';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useUserStore } from '@store/user';

const allUsersNavigation = [{ name: 'Settings', to: '/settings', icon: 'system-settings' }];

const removalistUsersNavigation = [
    { name: 'Company profile', to: '/company/profile', icon: 'system-user-settings' },
    { name: 'Company settings', to: '/company/settings', icon: 'system-toolbox' },
];

const helpNav = [{ name: 'Help', to: '/help', icon: 'system-support' }];

const { statusesWithDisabled, getStatusColourByValue, getStatusNameByValue } = useActivityStatuses();

const userActiveStatus = computed({
    get: () => user.activity_status,
    set: async (activity_status) => {
        const resp = await window.axios.patch(`/users/muval/${user.data?.id}/activity-status`, {
            activity_status,
        });
        user.activity_status = resp.data.user.activity_status;
        if (activity_status === 'AVAILABLE') {
            notificationsStore.inactive_status_code = null;
            notificationsStore.inactive_status = null;
        }
    },
});

const user = useUserStore();
const notificationsStore = useNotificationsStore();
const router = useRouter();

const userNavigation = computed(() => {
    let navigation = [];
    user.getUser.roles.forEach((role) => {
        switch (role) {
            case 'MUVAL_AGENT':
                break;
            case 'MUVAL_ADMIN':
                break;
            case 'COMPANY_MANAGER':
                navigation = [...navigation, ...removalistUsersNavigation];
                break;
        }
    });

    navigation = [...navigation, ...allUsersNavigation];

    if (!user.isMuval) {
        navigation = [...navigation, ...helpNav];
    }

    return uniqBy(navigation, 'name');
});

async function logout() {
    await user.logout();
    router.replace({ path: '/login' });
}
</script>
