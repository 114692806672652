// store/notifications.js
import { defineStore } from 'pinia';
import { useUserStore } from '@store/user';
import { notify } from '@kyvg/vue3-notification';

export const useNotificationsStore = defineStore('notifications', {
    state: () => {
        return {
            loading: false,
            all: [],
            listener: null,
            inactive_status_code: useStorage('inactive_status_code', null),
            inactive_status: useStorage('inactive_status', null),
            inactive_dismissible: useStorage('inactive_dismissible', false),
        };
    },
    actions: {
        add(notification) {
            notify({
                title: 'New Notification',
                group: 'app_notification',
                text: notification.data.message,
                data: notification.data,
            });
            this.all = [notification, ...this.all];
        },

        async retrieve() {
            this.loading = true;
            const response = await window.axios.get('me/notifications');
            this.all = response.data.data;
            this.loading = false;
        },

        /*
         * NotificationIds are comma separated string of uuids for the notifications to be modified
         */
        async read(notificationIds) {
            this.loading = true;

            await window.axios.post('me/notifications/read', {
                ids: notificationIds,
            });

            console.log('read', notificationIds);

            await this.retrieve();

            this.loading = false;
        },

        async markAllRead() {
            this.loading = true;

            const unreadNotifications = this.all.filter((notification) => !notification.read_at);
            let promises = [];
            unreadNotifications.forEach((notification) => {
                promises.push(this.read(notification.id));
            });
            await Promise.allSettled(promises);

            await this.retrieve();
            this.loading = false;
        },

        startListener() {
            const user = useUserStore();
            this.listener = window.echo.private('App.Models.User.' + user.getUser.id).notification((notification) => {
                if (notification.type === 'App\\Notifications\\UserActivityNotification') {
                    if (!user.isMuval) {
                        return;
                    }

                    this.inactive_status_code = notification.status;
                    this.inactive_status = notification.message;
                    this.inactive_dismissible = notification.dismissible;

                    if (!notification.dismissible) {
                        user.activity_status = notification.status;
                    }

                    return;
                }

                this.add({
                    data: {
                        message: notification.message,
                        url: notification.url,
                    },
                    id: notification.id,
                    type: notification.type,
                    read_at: null,
                });
            });
        },
    },
    getters: {
        getUnreadCount: (state) => {
            return state.all.reduce((accumulator, currentValue) => {
                if (!currentValue.read_at) {
                    accumulator = accumulator + 1;
                }
                return accumulator;
            }, 0);
        },

        getAllCount: (state) => {
            if (state.all && state.all.length) {
                return state.all.length;
            }
        },

        getUnread: (state) => {
            return state.unread;
        },

        getAll: (state) => {
            return state.all;
        },
    },
});
