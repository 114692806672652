import { defineStore } from 'pinia';

export const useRelocationStore = defineStore('relocation', () => {
    const relocation = ref({});

    async function retrieve(id) {
        const response = await window.axios.get(`/relocations/relocations/${id}`);
        relocation.value = response.data.data;
        return response.data.data;
    }

    async function update(data) {
        const response = await window.axios.patch(`/relocations/relocations/${relocation.value.id}`, data);
        relocation.value = response.data.data;
        return response.data.data;
    }

    async function resend() {
        await window.axios.post(`/relocations/relocations/${relocation.value.id}/resend`);
    }

    async function select(matchId) {
        const response = await window.axios.post(`/relocations/relocations/${relocation.value.id}/select`, {
            service_match_id: matchId,
        });
        relocation.value = response.data.data;
        return response.data.data;
    }

    async function approve(itemIdList) {
        const response = await window.axios.post(`/relocations/relocations/${relocation.value.id}/approve`, {
            items: itemIdList.map((id) => ({ id: id })),
        });
        relocation.value = response.data.data;
        return response.data.data;
    }

    async function requestAttention() {
        await window.axios.post(`/relocations/relocations/${relocation.value.id}/notify`);
    }

    function reset() {
        relocation.value = {};
    }

    return {
        relocation,
        retrieve,
        update,
        resend,
        select,
        approve,
        requestAttention,
        reset,
    };
});
