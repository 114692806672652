<template>
    <SvgIcon
        name="system-search"
        class="size-5 cursor-pointer text-muval-gray-1"
        @click="cmdCtx.shown = true"
    />
    <Teleport to="body">
        <div
            class="absolute inset-0 grid place-items-center"
            :class="{ 'pointer-events-none': !cmdCtx.shown }"
            @click="cmdCtx.handleClose"
        >
            <transition
                name="fade"
                mode="out-in"
            >
                <Command.Root
                    v-if="cmdCtx.shown"
                    :context="cmdCtx"
                    @click.stop
                >
                    <Command.Search />
                    <Command.Results v-show="cmdCtx.search" />
                </Command.Root>
            </transition>
        </div>
    </Teleport>
</template>
<script setup>
import { watchDebounced } from '@vueuse/core';
import { Command } from '@components/command';

const cmdCtx = reactive({
    shown: false,
    search: null,
    searching: false,
    results: [],
    _limit_order: 4,
    _limit_company: 2,
    handleClose() {
        cmdCtx.shown = false;
        cmdCtx.search = null;
        cmdCtx.results = [];
    },
});
provide(
    'context',
    reactive({
        context: cmdCtx,
    }),
);

const { Ctrl_K, Cmd_K, Escape } = useMagicKeys({
    passive: false,
    onEventFired(e) {
        if (e.key === 'k' && (e.ctrlKey || e.metaKey)) e.preventDefault();
        if (e.key === 'Escape') e.preventDefault();
    },
});

whenever(
    () => Ctrl_K.value || Cmd_K.value,
    () => {
        cmdCtx.shown = !cmdCtx.shown;

        if (!cmdCtx.shown) {
            // already closed but still need to reset search
            cmdCtx.handleClose();
        }
    },
);

whenever(
    () => cmdCtx.search,
    () => {
        cmdCtx.searching = true;
    },
);

whenever(Escape, () => {
    if (cmdCtx.shown) {
        cmdCtx.handleClose();
    }
});

watchDebounced(
    () => cmdCtx.search,
    async (value) => {
        console.log(value);
        if (!value) {
            cmdCtx.results = [];
            return;
        }

        cmdCtx.results = await handleSearch(value);
    },
    {
        debounce: 500,
    },
);

async function handleSearch(searchValue) {
    try {
        cmdCtx.searching = true;
        const rawResponse = await Promise.allSettled([
            // order search
            window.axios.get('/global-search/orders', {
                params: {
                    search: searchValue,
                    limit: cmdCtx._limit_order,
                    simple_paginate: true,
                },
            }),
            // company search
            window.axios.get('/global-search/companies', {
                params: {
                    search: searchValue,
                    limit: cmdCtx._limit_company,
                    simple_paginate: true,
                },
            }),
        ]);

        let formattedResponse = [];

        rawResponse.forEach((response) => {
            if (response.status === 'fulfilled') {
                if (response.value.data?.data?.length) {
                    formattedResponse = [...formattedResponse, ...response.value.data.data];
                }
            }
        });

        return formattedResponse;
    } finally {
        cmdCtx.searching = false;
    }
}
</script>
