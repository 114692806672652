import { defineStore } from 'pinia';

export const useLeadStore = defineStore('lead', {
    state: () => {
        return {
            loading: true,
            predefined: null,
            initial: {
                order: {
                    additional_assistance: [],
                },
                customer: {},
                move: { inventory: [] },
                car_transport: {
                    vehicles: [
                        {
                            start_address: null,
                            end_address: null,
                            make: null,
                            model: null,
                            registration_number: null,
                            registration_state: null,
                            start_at_type: null,
                            start_at_from: null,
                            start_at_by: null,
                            end_at_type: null,
                            end_at_from: null,
                            end_at_by: null,
                        },
                    ],
                },
                pet_transport: {
                    pets: [
                        {
                            start_address: null,
                            end_address: null,
                            name: null,
                            animal: null,
                            breed: null,
                            age: null,
                            weight: null,
                            start_at_type: null,
                            start_at_from: null,
                            start_at_by: null,
                            end_at_type: null,
                            end_at_from: null,
                            end_at_by: null,
                        },
                    ],
                },
            },
            data: {
                order: {
                    additional_assistance: [],
                },
                customer: {},
                move: { inventory: [] },
                car_transport: {
                    vehicles: [
                        {
                            start_address: null,
                            end_address: null,
                            make: null,
                            model: null,
                            registration_number: null,
                            registration_state: null,
                            start_at_type: null,
                            start_at_from: null,
                            start_at_by: null,
                            end_at_type: null,
                            end_at_from: null,
                            end_at_by: null,
                        },
                    ],
                },
                pet_transport: {
                    pets: [
                        {
                            start_address: null,
                            end_address: null,
                            name: null,
                            animal: null,
                            breed: null,
                            age: null,
                            weight: null,
                            start_at_type: null,
                            start_at_from: null,
                            start_at_by: null,
                            end_at_type: null,
                            end_at_from: null,
                            end_at_by: null,
                        },
                    ],
                },
            },
        };
    },

    actions: {},
});
