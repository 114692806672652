<template>
    <form @submit="onSubmit">
        <div class="flex flex-col justify-center space-y-5 font-body">
            <!-- <div class="bg-app shadow-lg flex flex-col divide-y rounded-lg">
      <div
        v-for="(agent, agentIdx) in agents"
        :key="agentIdx"
        class="px-4 py-2 text-center bg-app hover:bg-muval-gray-6 cursor-pointer"
        @click.prevent="selectAgent(agent.id)"
      >
        {{ agent.fullname }}
      </div>
    </div> -->
            <MuvalSelect
                vid="id"
                valueProp="id"
                class="mb-auto w-1/2"
                displayLabel="fullname"
                :selectOptions="agentsFormatted"
                grouped
            />

            <ErrorDetails :errorData="errorObject" />
            <div class="flex">
                <div class="ml-auto flex gap-5">
                    <MuvalButton
                        quaternary
                        type="button"
                        @click.prevent="$emit('cancel:modal')"
                    >
                        Cancel
                    </MuvalButton>
                    <MuvalButton :loading="loading">Assign</MuvalButton>
                </div>
            </div>
        </div>
    </form>
</template>
<script setup>
import MuvalSelect from '@inputs/MuvalSelect.vue';
import MuvalButton from '@buttons/MuvalButton.vue';
import ErrorDetails from '@components/errors/ErrorDetails.vue';
import { useForm } from 'vee-validate';
const props = defineProps({
    itemId: {
        type: Number,
        required: true,
    },
});

const errorObject = ref({
    message: null,
    errors: null,
});

const form = reactive(useForm());
const loading = ref(false);
const onSubmit = form.handleSubmit(async (values) => {
    try {
        loading.value = true;
        await window.axios.patch(`/inbox/${props.itemId}`, {
            assignee_id: values.id,
        });
        emit('close:modal');
    } catch (error) {
        errorObject.value.message = error.message;
        errorObject.value.errors = error.errors;
        form.setErrors(error.errors);
    } finally {
        loading.value = false;
    }
});

const emit = defineEmits(['close:modal', 'cancel:modal']);

const { agentsFormatted } = useAgents();
</script>
