export default [
    {
        path: '/ancillary',
        name: 'Ancillary',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/ancillary/AncillaryIndex.vue'),
            agent: () => import('@views/ancillary/AncillaryIndex.vue'),
        },
    },
];
