<template>
    <div class="relative flex w-full flex-col">
        <div class="text-md">Restore this {{ itemType }}?</div>
        <div class="container mt-3 divide-y divide-gray-200 rounded-muval-2 bg-muval-gray-5 dark:bg-gray-900">
            <div class="grid grid-cols-5 gap-y-2 p-4 text-sm">
                <span class="font-medium">{{ itemType }}</span>
                <span class="col-span-4">{{ data.id }}</span>
                <template
                    v-for="item in deleteItemSchema"
                    :key="item"
                >
                    <span class="font-medium">{{ item.name }}</span>
                    <!-- Thankyou Stack Overflow: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path -->
                    <span class="col-span-4">
                        {{ `${item.value}`.split('.').reduce((p, c) => (p && p[c]) || null, data) }}
                    </span>
                </template>
                <template v-if="data.created_at">
                    <span class="font-medium">Created</span>
                    <time class="col-span-4">
                        {{ $dayjs(data.created_at).format('dddd MM MMM YYYY') }} at
                        {{ $dayjs(data.created_at).format('h:mm A') }}
                    </time>
                </template>
            </div>
        </div>
        <div class="form-save">
            <MuvalButton
                quaternary
                @click="closeModal"
            >
                Cancel
            </MuvalButton>
            <MuvalButton
                :loading="loading"
                @click.once="restore"
            >
                Yes, restore this {{ itemType }}
            </MuvalButton>
        </div>
    </div>
</template>

<script setup>
import { notify } from '@kyvg/vue3-notification';
import useEmitter from '@plugins/useEmitter';
import MuvalButton from '@buttons/MuvalButton.vue';

const props = defineProps({
    itemIndex: {
        type: Number,
        required: true,
    },
    itemType: {
        type: [String, null],
        required: false,
        default: null,
    },
    data: {
        type: [Object, null],
        required: false,
        default: null,
    },
    url: {
        type: [String, null],
        required: true,
        default: null,
    },
    deleteItemSchema: {
        type: [Array, null],
        required: false,
        default: null,
    },
    emitKey: {
        type: [String, null],
        required: false,
        default: null,
    },
});
const emit = defineEmits(['close:modal', 'remove:item']);
const loading = ref(false);
const emitter = useEmitter();

function closeModal() {
    emit('close:modal');
}

async function restore() {
    try {
        loading.value = true;

        const response = await window.axios.put(`${props.url}/restore/${props.data.id}`);
        if (response) {
            emitter.emit(props.emitKey ? `restore-item-${props.emitKey}` : 'restore-item', {
                index: null,
                object: response.data.restored,
            });
            notify({
                title: `${props.itemType} restored`,
                group: 'app_success',
                text: 'successfully',
            });
        }

        closeModal();
    } catch (error) {
        loading.value = false;
        console.log('RestoreModal@error', error);
    }
}
</script>
