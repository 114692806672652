<template>
    <div class="flex w-full flex-col gap-y-4">
        <div class="flex w-full justify-between">
            <div class="flex flex-col">
                <span
                    class="text-lg font-medium"
                    v-text="data.name"
                />
                <span
                    class="-mt-1 text-base font-medium text-muval-gray-2"
                    v-text="$filters.capitalize(data.type)"
                />
            </div>
            <Lozenge
                :class="[textStyle, bgStyle]"
                class="mt-1"
                >{{ $filters.capitalize(data.status) }}</Lozenge
            >
        </div>
        <img
            v-if="data.logo_url"
            :src="data.logo_url"
            class="h-16 w-fit"
        />
        <div class="flex flex-col">
            <span class="font-medium">Contact info</span>
            <span v-text="data.email" />
            <span v-text="data.phone" />
        </div>
        <div
            v-if="data.review_rating && data.review_count"
            class="flex w-fit grow-0 items-center gap-x-1 rounded-muval-1 border bg-muval-gray-6 px-1 py-0.5 text-xs"
        >
            <SvgIcon
                name="system-star"
                class="mr-0.5 h-4 w-4 text-muval-amber"
            />
            <span
                class="font-medium leading-4"
                v-text="data.review_rating"
            />
            <span
                class="text-xs leading-4 text-muval-gray-2"
                v-text="data.review_count"
            />
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const { textStyle, bgStyle } = useStatusColours(props.data.status, { type: 'company' });
</script>
