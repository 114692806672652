import { defineStore } from 'pinia';
import { couponApi } from '@api';

export const useCouponStore = defineStore('coupon', {
    state: () => {
        return {
            loading: false,
            data: {},
            collection: [],
        };
    },
    getters: {},
    actions: {
        async index() {
            try {
                const coupons = await couponApi.index();
                this.collection = coupons;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async create(data) {
            try {
                this.loading = true;
                const coupon = await couponApi.create(data);
                this.loading = false;
                return coupon;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },

        async retrieve(couponId) {
            try {
                this.loading = true;
                const coupon = await couponApi.retrieve(couponId);
                this.loading = false;
                return coupon;
            } catch (error) {
                this.loading = false;
                // let the form component display the error
                throw error;
            }
        },

        async update(couponId, data) {
            try {
                this.loading = true;
                const coupon = await couponApi.update(couponId, data);
                this.loading = false;
                return coupon;
            } catch (error) {
                this.loading = false;
                throw error;
            }
        },
    },
});
