/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module Local
 */

const ROUTE = '/settings/muvexpress-depots';

function index() {
    return window.axios.get(ROUTE);
}

async function create(data) {
    const response = await window.axios.post(ROUTE, data);
    return response;
}

async function retrieve(id) {
    const {
        data: { muvexpress_depot: data },
    } = await window.axios.get(`${ROUTE}/${id}`);
    return {
        data,
    };
}

function update(id, data) {
    return window.axios.patch(`${ROUTE}/${id}`, data);
}

function destroy(id) {
    return window.axios.delete(`${ROUTE}/${id}`);
}

export default {
    index,
    create,
    retrieve,
    update,
    destroy,
};
