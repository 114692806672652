<template>
    <!-- Version Toast -->
    <notifications
        v-if="newRelease && !showReleaseModal"
        :pause-on-hover="true"
        :max="1"
        width="340"
        group="app_release"
        position="bottom center w-full"
        class="!bottom-[30px]"
        :duration="-1"
        :speed="1000"
    >
        <template #body="{ item }">
            <div
                class="relative m-4 flex h-fit cursor-pointer rounded-muval-2 border-gray-300 bg-white px-3 py-3 pb-4 shadow-muval-3 shadow-muval-brand"
                @click="releaseModal.toggle()"
            >
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        class="h-6 w-6 text-muval-brand"
                        fill="currentColor"
                    >
                        <path
                            d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"
                        />
                    </svg>
                </div>
                <div class="my-auto ml-2 flex w-full justify-between">
                    <div class="flex flex-col text-sm text-muval-gray-1">
                        <span class="font-medium">{{ item.title }}</span>
                        <span class="text-xs">
                            {{ item.text }}
                            <span
                                class="cursor-help font-mono text-xxs opacity-50"
                                :title="item.data?.release"
                                >{{ item.data?.release_tag }}</span
                            >
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <MuvalModal
        v-model="showReleaseModal"
        modalSize="md:w-2/3"
    >
        <template #header>
            <div class="flex text-muval-gray-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    class="mr-2 h-6 w-6 text-muval-brand"
                    fill="currentColor"
                >
                    <path
                        d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"
                    />
                </svg>
                <template v-if="!checkRelease">Work in progress</template>
                <template v-else-if="newRelease">New release available</template>
                <template v-else>Current release</template>
            </div>
        </template>
        <div>
            <div class="-mt-2 mb-4 font-body text-sm text-muval-gray-1">
                <template v-if="!checkRelease">
                    Keep up the great work! We're excited to see what you're building.
                </template>
                <template v-else-if="newRelease">
                    Please save any work in progress and then refresh for the latest features and bug fixes. We
                    recommend you perform a force-refresh to be sure you're on the latest version.
                </template>
                <template v-else>
                    You appear to be on the current release, however if you want to be sure you're on the latest
                    version, we recommend you perform a force-refresh.
                </template>
            </div>
            <div class="-mt-2 mb-4 font-body text-sm text-muval-gray-1">
                Use Shift (⇧)-F5 on Windows or Cmd (⌘)-Shift (⇧)-R on macOS to force-refresh.
            </div>

            <div class="flex gap-4">
                <MuvalButton @click="refresh()">Refresh</MuvalButton>

                <MuvalButton
                    v-if="checkRelease"
                    quaternary
                    @click="toggleDetails()"
                >
                    {{ showReleaseDetails ? 'Hide' : 'View' }} details
                </MuvalButton>
            </div>

            <template v-if="showReleaseDetails">
                <ReleaseDetails />
            </template>
        </div>
    </MuvalModal>
</template>

<script setup>
import MuvalModal from '@components/modal/MuvalModal.vue';
import { useAppStore } from '@store/app';

const app = useAppStore();
const showReleaseModal = ref(false);
const showReleaseDetails = ref(false);

const newRelease = computed(() => {
    return app.newRelease;
});

const checkRelease = computed(() => {
    return app.checkRelease;
});

function refresh() {
    window.location.reload(true);
}

function toggleDetails() {
    showReleaseDetails.value = !showReleaseDetails.value;
}

const keys = useMagicKeys({
    passive: false,
    onEventFired(e) {
        if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
        }
    },
});

const keyCtrlU = keys['Ctrl+U'];
const keyCmdU = keys['Cmd+U'];
const keyEscape = keys['escape'];

const releaseModal = {
    open: () => {
        showReleaseModal.value = true;
        showReleaseDetails.value = false;
    },
    close: () => {
        showReleaseModal.value = false;
        showReleaseDetails.value = false;
    },
    toggle: () => {
        showReleaseModal.value = !showReleaseModal.value;
        showReleaseDetails.value = false;
    },
};

whenever(keyCtrlU, releaseModal.toggle);
whenever(keyCmdU, releaseModal.toggle);
whenever(keyEscape, releaseModal.close);
</script>
