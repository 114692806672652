import { defineStore } from 'pinia';
import { useUserStore } from '@store/user';

export const useJobStore = defineStore('job', {
    state: () => {
        return {
            loading: false,
            data: {},
            invoice: null,
        };
    },
    getters: {
        getJobId: (state) => {
            return state?.data.id ?? null;
        },
        getCompanyId: () => {
            const userStore = useUserStore();
            return userStore.getCompanyId;
        },
        getInvoice: (state) => {
            return state.invoice?.invoice_url;
        },
        getJobData(state) {
            return state.data;
        },
        getRouteData(state) {
            return state.data?.route;
        },
        hasInvoice: (state) => {
            return state.invoice?.invoice_url != null;
        },
    },
    actions: {
        async retrieve(jobId) {
            try {
                this.loading = true;
                const resp = await window.axios.get(`/companies/${this.getCompanyId}/jobs/${jobId}`);
                this.data = resp.data.job;
                this.invoice = resp.data.invoice;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log('jobStore@retrieve', error);
                throw error;
            }
        },
    },
});
