<template>
    <TransitionRoot
        as="template"
        :show="open"
    >
        <Dialog
            :unmount="false"
            as="div"
            class="fixed inset-0 overflow-hidden"
            @close="open = false"
        >
            <div class="absolute inset-0 overflow-hidden">
                <DialogOverlay class="absolute inset-0" />

                <div class="fixed inset-y-0 right-0 z-20 flex max-w-full pl-10 sm:pl-16">
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-500"
                        enter-from="translate-x-full"
                        enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-500"
                        leave-from="translate-x-0"
                        leave-to="translate-x-full"
                    >
                        <div class="w-screen max-w-md">
                            <div class="relative flex h-full flex-col bg-white shadow-muval-3 dark:bg-gray-700">
                                <div class="p-6">
                                    <div class="flex items-center justify-between">
                                        <DialogTitle
                                            as="h1"
                                            class="truncate text-2xl font-medium"
                                        >
                                            Notifications
                                        </DialogTitle>
                                        <MuvalButton
                                            v-show="notifications.getUnreadCount"
                                            small
                                            quaternary
                                            :loading="notifications.loading"
                                            icon="system-check"
                                            @click.stop="markAllAsRead"
                                        >
                                            Mark all as read
                                        </MuvalButton>
                                        <div class="ml-3 flex h-7 items-center">
                                            <button
                                                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                                @click="open = false"
                                            >
                                                <span class="sr-only">Close panel</span>
                                                <XIcon
                                                    class="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="overflow-y-auto px-0 sm:px-6">
                                    <NotificationTabs @close="open = false" />
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

import { useAppStore } from '@store/app';
import { useNotificationsStore } from '@store/notifications';
import NotificationTabs from './NotificationTabs.vue';
import { onUnmounted } from 'vue';

const app = useAppStore();

const open = computed({
    get() {
        return app.notificationSlider ?? false;
    },
    set(value) {
        app.notificationSlider = value;
    },
});

const notifications = useNotificationsStore();
// onMounted(async () => {
//   await notifications.retrieve();
// });
onMounted(() => {
    notifications.retrieve();
    let stop = setInterval(notifications.retrieve, 60000);
    onUnmounted(() => {
        clearInterval(stop);
    });
});

const markAllAsRead = async () => {
    console.log('marking all as read');
    await notifications.markAllRead();
};
</script>
