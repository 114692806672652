import { defineStore } from 'pinia';
import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query';
import { notify } from '@kyvg/vue3-notification';

export const useOrderInboxStore = defineStore('orderInbox', () => {
    const orderStore = useOrderStore();

    const orderId = computed(() => orderStore.getOrderId);

    const queryClient = useQueryClient();
    const queryKey = computed(() => ['order-inbox', orderId.value]);

    const listener = ref();

    const { isLoading, data, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } = useInfiniteQuery(
        queryKey,
        ({ pageParam = 1 }) => getInboxData(orderId.value, pageParam),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage?.meta?.last_page > lastPage?.meta?.current_page) {
                    return lastPage.meta.current_page + 1;
                } else {
                    return undefined;
                }
            },
        },
        {
            refetchOnWindowFocus: false,
            retry: false,
        },
    );

    watch(orderId, () => {
        if (orderId.value) {
            listener.value = window.echo.private(`orders.${orderId.value}`).listen('InboxCreatedEvent', (e) => {
                notify({
                    title: e.type + ' received',
                    group: 'order_inbox',
                    ...e,
                });
                refetch();
            });
        }
    });

    const allInboxData = computed(() => {
        return data.value?.pages?.reduce((acc, page) => {
            return [...acc, ...page.data];
        }, []);
    });

    const allInboxDataCount = computed(() => {
        return data.value?.pages?.[0]?.meta?.total ?? 0;
    });

    function stopListening() {
        listener.value = null;
    }

    function resetQuery() {
        queryClient.removeQueries({
            queryKey,
            exact: true,
        });
        listener.value = null;
        data.value = null;
    }

    return {
        isLoading,
        allInboxData,
        allInboxDataCount,
        fetchNextPage,
        hasNextPage,
        refetch,
        isFetchingNextPage,
        stopListening,
        resetQuery,
    };
});

async function getInboxData(order_id, page = 1) {
    if (order_id) {
        const { data: response } = await window.axios.get(`/orders/${order_id}/inbox`, {
            params: {
                sort: '-created_at',
                limit: 10,
                page,
            },
        });

        let formatted = [];
        response.data.forEach((item, itemIndex) => {
            let copy = item;

            const currentItemDate =
                copy?.inboxItem?.sent_at_date_formatted ?? copy?.inboxItem?.answered_at_date_formatted;

            const previousItemDate =
                formatted[itemIndex - 1]?.inboxItem?.sent_at_date_formatted ??
                formatted[itemIndex - 1]?.inboxItem?.answered_at_date_formatted;

            if (itemIndex) {
                if (currentItemDate != previousItemDate) {
                    copy.date_title = currentItemDate;
                }
            } else {
                copy.date_title = currentItemDate;
            }

            formatted.push(copy);
        });

        response.data = formatted;

        return response;
    } else {
        return {};
    }
}
