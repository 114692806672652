<template>
    <div
        v-if="errorCount > 0"
        class="flex flex-col overflow-hidden rounded-muval-1"
    >
        <div
            class="flex justify-between gap-2 rounded-muval-1 border-l-4 border-t-0 border-muval-red-1 bg-muval-red-2 px-2 py-2 text-sm transition-all"
            :class="errorCount > 0 ? 'hover:cursor-pointer' : ''"
            @click="toggleShowAll"
        >
            <div class="flex w-full items-center justify-between text-muval-red-1">
                <span>
                    {{ errorData.message }}
                    <ErrorLink
                        :errorKey="firstErrorKey"
                        :links="errorData.links"
                    />
                </span>
                <div v-if="errorCount > 0">
                    <SvgIcon
                        name="system-chevron-right"
                        class="h-4 w-4 transition"
                        :class="showAll && 'rotate-90'"
                    />
                </div>
            </div>
        </div>

        <!-- Display Messages -->
        <div class="flex flex-grow flex-col text-sm text-error transition-all">
            <!-- Display More Messages -->
            <transition
                enter-active-class="transition ease-out"
                enter-from-class="transform opacity-0 "
                enter-to-class="transform opacity-100 "
                leave-active-class="transition ease-in"
                leave-from-class="transform opacity-100 "
                leave-to-class="transform opacity-0 "
            >
                <div v-if="showAll">
                    <div
                        v-for="(errorMessages, key, idx) in errorData.errors"
                        :key="idx"
                    >
                        <div class="flex items-center gap-2">
                            <SvgIcon
                                class="h-5 w-5"
                                name="system-arrow-turn"
                            />
                            <div class="mt-2">
                                <span>
                                    {{ errorMessages[0] }}
                                    <ErrorLink
                                        :errorKey="key"
                                        :links="errorData.links"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    errorData: {
        required: true,
        type: [Object, null],
        default: null,
    },
    maxErrors: {
        required: false,
        type: Number,
        default: 3,
    },
});

const firstErrorKey = computed(() => {
    if (props.errorData?.errors) {
        return Object.keys(props.errorData.errors)[0];
    }

    return null;
});

const errorCount = computed(() => {
    if (props.errorData?.errors) {
        return Object.keys(props.errorData.errors).length;
    } else {
        return 0;
    }
});

const showAll = ref(false);

const toggleShowAll = () => {
    if (errorCount.value > 0) {
        showAll.value = !showAll.value;
    }
};
</script>
