export default [
    {
        path: '/fleet',
        name: 'Fleet',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/fleet/FleetIndex.vue'),
            agent: () => import('@views/fleet/FleetIndex.vue'),
        },
    },
    {
        path: '/fleet/create',
        name: 'FleetCreate',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/fleet/FleetCreate.vue'),
            agent: () => import('@views/fleet/FleetCreate.vue'),
        },
    },
    {
        path: '/fleet/:id/edit',
        name: 'FleetEdit',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
            title: 'Fleet Edit',
        },
        components: {
            default: () => import('@views/fleet/FleetEdit.vue'),
            agent: () => import('@views/fleet/FleetEdit.vue'),
        },
    },
];
