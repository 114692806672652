<template>
    <!-- Notification Toast -->
    <notifications
        :max="1"
        width="500"
        group="app_notification"
        position="top center w-full"
        :pause-on-hover="true"
    >
        <template #body="{ item }">
            <component
                :is="item.data.url ? 'router-link' : 'div'"
                :to="item.data.url"
                target="_blank"
                class="card relative m-2 flex h-fit rounded-muval-2 border-gray-300 bg-white px-4 py-2"
            >
                <div class="flex h-full w-6 rounded-full pt-1 text-brand">
                    <SvgIcon
                        class="h-6 w-6"
                        name="system-notification-filled"
                    />
                </div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col text-sm">
                        <span class="font-bold dark:text-gray-300">
                            {{ item.title }}
                        </span>
                        <span>{{ item.text }}</span>
                    </div>
                </div>
            </component>
        </template>
    </notifications>

    <!-- Auth Toast -->
    <notifications
        group="auth"
        position="top"
    >
        <template #body="{ item, close }">
            <div class="m-2 flex h-16 w-64 flex-col rounded-md border-l-4 border-blue-600 bg-gray-200 p-2 shadow">
                <div class="flex justify-between">
                    <span>{{ item.title }}</span>

                    <button
                        class="flex rounded border border-gray-100 bg-gray-100 px-1 py-0.5 hover:bg-gray-200"
                        @click="close"
                    >
                        <svg
                            class="h-4 w-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Success Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="app_success"
        position="bottom center w-full"
        class="!bottom-[30px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-fit rounded-muval-2 border-gray-300 bg-white px-4 py-2 shadow-muval-3">
                <div class="flex h-full w-6 rounded-full text-success">
                    <SvgIcon
                        class="h-6 w-6"
                        name="system-check-circle"
                    />
                </div>
                <div class="my-auto ml-2 flex w-full justify-between">
                    <div class="flex flex-col text-sm">
                        <span class="font-medium">{{ item.title }}</span>
                        <span class="font-light">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <NewRelease />

    <!-- Info Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="app_info"
        position="bottom center w-full"
        class="!bottom-[30px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-fit rounded-muval-2 border-gray-300 bg-white px-4 py-2 shadow-muval-3">
                <div class="my-auto">
                    <svg
                        class="h-6 w-6 text-muval-sky-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </div>
                <div class="my-auto ml-2 flex w-full justify-between">
                    <div class="flex flex-col text-sm">
                        <span class="font-bold">{{ item.title }}</span>
                        <span class="text-xs">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Order Inbox Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="order_inbox"
        position="bottom right"
        class="!bottom-[30px] !right-[30px]"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-fit rounded-muval-2 border-gray-300 bg-white px-4 py-2 shadow-muval-3">
                <div class="my-auto">
                    <SvgIcon
                        v-if="item.type === 'SMS'"
                        name="system-mobile"
                        class="h-6 w-6"
                    />
                    <SvgIcon
                        v-else-if="item.type === 'EMAIL'"
                        name="system-email-filled"
                        class="h-6 w-6"
                    />
                    <SvgIcon
                        v-else-if="item.type === 'CALL'"
                        name="system-phone"
                        class="h-6 w-6"
                    />
                    <SvgIcon
                        v-else-if="item.type === 'CALLBACK'"
                        name="system-phone"
                        class="h-6 w-6"
                    />
                </div>
                <div class="my-auto ml-2 flex w-full justify-between">
                    <div class="flex flex-col text-sm">
                        <span class="font-bold">{{ $filters.capitalize(item.title) }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Error Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        :duration="5000"
        group="app_error"
        class="m-0 p-0"
        position="bottom center w-full"
    >
        <template #body="{ item, close }">
            <div
                class="relative m-2 flex h-16 w-fit justify-between gap-3 rounded-full bg-white px-4 shadow dark:bg-gray-800"
            >
                <div class="flex h-full rounded-full text-muval-error">
                    <SvgIcon
                        class="my-auto h-6 w-6"
                        name="system-alert"
                    />
                </div>
                <div class="flex w-full whitespace-nowrap">
                    <div class="my-auto flex w-full flex-col text-sm">
                        <span class="font-bold text-gray-700 dark:text-white">{{ item.title }}</span>
                        <button
                            class="mr-auto text-focus underline"
                            @click="handleMoreInfo(item.data)"
                        >
                            Show more info
                        </button>
                    </div>
                </div>
                <div class="relative h-full">
                    <div class="flex h-full">
                        <button
                            class="my-auto flex rounded-full p-2 text-error hover:bg-gray-200 focus:outline-none"
                            @click="close"
                        >
                            <svg
                                class="h-4 w-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Validation Error Toast -->
    <notifications
        :pause-on-hover="true"
        :max="1"
        width="250"
        group="app_validation_error"
        position="bottom center w-full"
    >
        <template #body="{ item }">
            <div class="relative m-2 flex h-fit rounded-muval-2 border-gray-300 bg-white px-4 py-2 shadow-muval-3">
                <div class="text-muval-alert_error flex h-full w-6 rounded-full">
                    <svg
                        class="my-auto h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col text-xs">
                        <span class="font-bold text-gray-900 dark:text-gray-300">{{ item.title }}</span>
                        <span class="font-light">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <!-- Restore Toast -->
    <notifications
        :pause-on-hover="true"
        :max="4"
        group="app_restore"
        class="m-0 p-0"
        position="bottom left"
    >
        <template #body="{ item, close }">
            <div
                class="relative m-2 flex h-20 w-60 rounded-lg bg-white p-2 shadow dark:bg-gray-900"
                @click.once="restore(item.data.url, item.data.lastDeleted, item.data.type, item.data.key, close)"
            >
                <div class="flex h-full rounded-full text-muval-amber">
                    <SvgIcon
                        class="my-auto h-6 w-6"
                        name="system-alert"
                    />
                </div>
                <div class="ml-4 flex w-full justify-between">
                    <div class="flex flex-col">
                        <span class="font-bold text-gray-700 dark:text-white">
                            {{
                                item.data.type.toUpperCase().substring(0, 1) + item.data.type.toLowerCase().substring(1)
                            }}
                            deleted
                        </span>
                        <span class="cursor-pointer text-xs text-blue-400 underline">Click to restore</span>
                    </div>
                    <div class="relative my-auto ml-auto flex">
                        <button class="flex rounded p-4 hover:bg-gray-200 focus:outline-none">
                            <svg
                                class="h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <MuvalModal
        v-model="moreInfoModal"
        title="More error info"
    >
        <div class="flex flex-col gap-4">
            <h4>If you think this is an error, please report it to a dev with the following info:</h4>
            <div class="rounded-muval-1 bg-muval-gray-1 p-4 text-white">
                <div class="flex justify-between">
                    <span class="font-bold">Url</span>
                    <span class="text-gray-300 dark:text-white">{{ moreInfo.url }}</span>
                </div>
                <div class="flex justify-between">
                    <span class="font-bold">Method</span>
                    <span class="text-gray-300 dark:text-white">{{ moreInfo.method }}</span>
                </div>
                <div class="flex justify-between">
                    <span class="font-bold">Users Roles</span>
                    <span class="text-gray-300 dark:text-white">{{ user.getRoles }}</span>
                </div>
            </div>
        </div>
    </MuvalModal>
</template>

<script setup>
import useEmitter from '@plugins/useEmitter';
import { notify } from '@kyvg/vue3-notification';
import MuvalModal from './modal/MuvalModal.vue';

import { useUserStore } from '@store/user';

const user = useUserStore();
const moreInfoModal = ref(false);
const moreInfo = ref({
    url: null,
    method: null,
});

function handleMoreInfo(item) {
    moreInfoModal.value = true;
    moreInfo.value = {
        url: item.url,
        method: item.method,
    };
}

const emitter = useEmitter();
async function restore(url, item, type = 'Item', key, closeToast) {
    const response = await window.axios.put(`${url}/restore/${item.id}`);
    if (response) {
        emitter.emit(key ? `restore-item-${key}` : 'restore-item', {
            index: item.index,
            object: response.data.restored,
        });
        notify({
            title: `${type.toUpperCase().substring(0, 1) + type.toLowerCase().substring(1)} restored`,
            group: 'app_success',
            text: 'successfully',
        });
        closeToast();
    }
}
</script>
