<script setup>
import { useDebounceFn, useMousePressed, whenever, onKeyStroke } from '@vueuse/core';

const userStore = useUserStore();

const DEBOUNCE_IN_MS = 1 * 60 * 1000;
const MAX_WAIT_IN_MS = 5 * 60 * 1000;

const debounceFn = useDebounceFn(() => userStore.fetchUser(false), DEBOUNCE_IN_MS, {
    maxWait: MAX_WAIT_IN_MS,
});

// reset the timeout whenever the mouse is pressed
const { pressed } = useMousePressed();
whenever(pressed, debounceFn);

// reset the timeout whenever a key is pressed
onKeyStroke(true, debounceFn);
</script>
