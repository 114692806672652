<template>
    <svg aria-hidden="true">
        <use
            :href="symbolId"
            :fill="color ? color : 'currentColor'"
        />
    </svg>
</template>

<script setup>
const props = defineProps({
    noPrefix: {
        type: Boolean,
        default: false,
    },

    prefix: {
        type: String,
        default: 'icon',
    },
    name: {
        type: String,
        required: true,
    },
    color: {
        type: [String, null],
        required: false,
        default: null,
    },
});
const symbolId = computed(() => {
    if (props.noPrefix) {
        return `#${props.name}`;
    }
    return `#${props.prefix}-${props.name}`;
});
</script>
