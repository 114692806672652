export default [
    {
        path: '/orders',
        name: 'Leads',
        meta: {
            requiresAuth: true,
            title: 'Leads',
        },
        components: {
            default: () => import('@views/leads/LeadsIndex.vue'),
            agent: () => import('@views/leads/LeadsIndex.vue'),
        },
    },
    {
        path: '/orders/:id/view',
        name: 'Order',
        meta: {
            requiresAuth: true,
        },
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        components: {
            default: () => import('@views/admin/orders/Order.vue'),
            agent: () => import('@views/admin/orders/Order.vue'),
        },
    },
];
