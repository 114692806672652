/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module Local
 */

const ROUTE = '/local-prices';

function index() {
    return window.axios.get(ROUTE);
}

async function create(local) {
    const response = await window.axios.post(ROUTE, local);
    return response;
}

async function retrieve(localId) {
    const {
        data: { local_price: data },
    } = await window.axios.get(`${ROUTE}/${localId}`);
    return {
        data,
    };
}

function update(localId, local) {
    return window.axios.patch(`${ROUTE}/${localId}`, {
        local_id: localId,
        ...local,
    });
}

function destroy(localId) {
    return window.axios.delete(`${ROUTE}/${localId}`);
}

export default {
    index,
    create,
    retrieve,
    update,
    destroy,
};
