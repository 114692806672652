<template>
    <template v-if="hasAuth">
        <slot />
    </template>
    <slot
        v-else
        name="else"
    />
</template>

<script>
import { useUserStore } from '@store/user';

export default {
    name: 'RoleGuard',
    props: {
        guarded: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const user = useUserStore();

        const roles = computed(() => {
            return user?.data?.roles;
        });

        const hasAuth = computed(() => {
            if (props.guarded && roles.value) {
                if (props.guarded.some((v) => roles.value.includes(v))) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        });

        return {
            roles,
            hasAuth,
        };
    },
};
</script>
