import { defineStore } from 'pinia';
import { useOrderStore } from '@store/order';

export const useSubscriptionStore = defineStore('subscription', () => {
    const order = useOrderStore();

    // state

    const data = ref({
        channels: null,
        lists: null,
        subscriptions: null,
        email: null,
        phone: null,
        total: null,
        loading: false,
    });

    // getters

    const isLoading = computed(() => {
        return data.value.loading;
    });

    const getChannels = computed(() => {
        return data.value.channels ?? null;
    });

    const getLists = computed(() => {
        return data.value.lists ?? null;
    });

    const getSubscriptions = computed(() => {
        return data.value.subscriptions ?? null;
    });

    const getStatus = computed(() => {
        return (channel, list) =>
            data.value.subscriptions?.find((subscription) => {
                return subscription.channel == channel && subscription.list == list;
            })?.status;
    });

    const allowMoveFollowUpByEmail = computed(() => {
        return (
            data.value.subscriptions?.filter((subscription) => {
                return (
                    subscription.channel == 'EMAIL' &&
                    subscription.list == 'MOVE_FOLLOW_UP' &&
                    subscription.status === false
                );
            })?.length == 0
        );
    });

    const allowMoveFollowUpBySms = computed(() => {
        return (
            data.value.subscriptions?.filter((subscription) => {
                return (
                    subscription.channel == 'SMS' &&
                    subscription.list == 'MOVE_FOLLOW_UP' &&
                    subscription.status === false
                );
            })?.length == 0
        );
    });

    const allowMoveFollowUp = computed(() => {
        return (
            data.value.subscriptions?.filter((subscription) => {
                return subscription.list == 'MOVE_FOLLOW_UP' && subscription.status === false;
            })?.length == 0
        );
    });

    const preventMoveFollowUp = computed(() => {
        return (
            data.value.subscriptions?.filter((subscription) => {
                return subscription.list == 'MOVE_FOLLOW_UP' && subscription.status === false;
            })?.length > 0
        );
    });

    // actions

    async function init() {
        data.value.loading = true;

        try {
            const response = await window.axios.get(`orders/${order.getOrderId}/subscriptions`);

            data.value.channels = response.data.data.channels;
            data.value.lists = response.data.data.lists;
            data.value.subscriptions = response.data.data.subscriptions;

            return response;
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            data.value.loading = false;
        }
    }

    async function updateStatus(channel, list, status) {
        try {
            const response = await window.axios.patch(`orders/${order.getOrderId}/subscriptions`, {
                channel,
                list,
                status,
            });

            data.value.channels = response.data.data.channels;
            data.value.lists = response.data.data.lists;
            data.value.subscriptions = response.data.data.subscriptions;

            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    return {
        // state
        data,

        // getters
        isLoading,
        getChannels,
        getLists,
        getSubscriptions,
        getStatus,
        allowMoveFollowUp,
        allowMoveFollowUpByEmail,
        allowMoveFollowUpBySms,
        preventMoveFollowUp,

        // actions
        init,
        updateStatus,
    };
});
