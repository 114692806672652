<template>
    <div class="z-0 inline-block align-middle">
        <div class="flex">
            <input
                id="offers"
                :checked="!!selected"
                name="offers"
                type="checkbox"
                :disabled="true"
                class="my-auto h-4 w-4 rounded-sm border-muval-gray-3 text-green-500 focus:ring-green-500"
            />
            <span class="my-auto ml-2 truncate text-sm font-normal capitalize text-gray-700">
                {{ data.first_name }} {{ data.last_name }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MSAgent',
    props: {
        data: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {};
    },
};
</script>
