export default [
    {
        path: '/companies',
        name: 'Companies',
        meta: {
            requiresAuth: true,
            title: 'Companies',
        },
        components: {
            default: () => import('@views/companies/CompaniesIndex.vue'),
            agent: () => import('@views/companies/CompaniesIndex.vue'),
        },
        children: [],
    },
    {
        path: '/companies/:companyId/preferences',
        name: 'CompaniesPreferences',
        props: {
            default: (route) => ({ companyId: route.params.companyId }),
            agent: (route) => ({ companyId: route.params.companyId }),
        },
        meta: {
            requiresAuth: true,
            title: 'Companies',
        },
        components: {
            default: () => import('@views/company/CompanyPreferences.vue'),
            agent: () => import('@views/company/CompanyPreferences.vue'),
        },
    },
    {
        path: '/companies/interstate-price-items',
        name: 'CompanyInterstatePriceItemsIndex',
        props: {
            default: (route) => ({ id: route.params.id }),
            agent: (route) => ({ id: route.params.id }),
        },
        meta: {
            requiresAuth: true,
            title: 'Companies',
        },
        components: {
            default: () => import('@views/company/CompanyInterstatePriceItemsIndex.vue'),
            agent: () => import('@views/company/CompanyInterstatePriceItemsIndex.vue'),
        },
    },
    {
        path: '/companies/:id/view',
        name: 'CompaniesView',
        props: {
            default: (route) => ({ companyId: route.params.id }),
            agent: (route) => ({ companyId: route.params.id }),
        },
        meta: {
            requiresAuth: true,
            title: 'CompaniesView',
        },
        components: {
            default: () => import('@views/companies/CompaniesView.vue'),
            agent: () => import('@views/companies/CompaniesView.vue'),
        },
    },
    {
        path: '/companies/:id/edit',
        name: 'CompaniesEdit',
        props: {
            default: (route) => ({ companyId: route.params.id }),
            agent: (route) => ({ companyId: route.params.id }),
        },
        meta: {
            requiresAuth: true,
            title: 'Companies',
        },
        components: {
            default: () => import('@views/companies/CompaniesEdit.vue'),
            agent: () => import('@views/companies/CompaniesEdit.vue'),
        },
    },
];
